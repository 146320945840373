import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  where,
} from "firebase/firestore";
import { auth, db, updateOfferteStatus } from "../firebaseConfig";
import {
  Card,
  CardBody,
  Button,
  Row,
  Col,
  Label,
  Input,
  ModalBody,
  ModalHeader,
  Modal,
  ModalFooter,
  Spinner,
} from "reactstrap";

import PdfViewerModal from "../components/PdfViewerModal";

import "jspdf-autotable";

import {
  addLog,
  createFollowUpTask,
  getIcon,
  getStatusName,
  sendQuoteEmail,
  toastHelper,
} from "../utils/helpers";
import moment from "moment";
import { FaEye, FaEdit, FaPaperPlane, FaPlus } from "react-icons/fa";
import TaskModal from "../components/AddTaskModal";
import { useUser } from "../context/UserContext";
import TaskTabs from "../components/TaskTabs";

const SingleOffertePage = () => {
  const { id } = useParams();
  const { user } = useUser();
  const [previousStatus, setPreviousStatus] = useState(null);
  const [agent, setAgent] = useState([]);
  const [agents, setAgents] = useState([]);
  const [offerte, setOfferte] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);
  const [pdfBlob, setPdfBlob] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState(null);
  const [isSending, setIsSending] = useState(false);
  const [logs, setLogs] = useState([]);
  const [isNoteModalOpen, setNoteModalOpen] = useState(false);
  const [noteTitle, setNoteTitle] = useState("");
  const [noteDescription, setNoteDescription] = useState("");
  const [isTaskModalOpen, setTaskModalOpen] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelDescription, setCancelDescription] = useState("");
  const [newTask, setNewTask] = useState({
    type: "bel afspraak", // Default task type
    title: "",
    agent: auth.currentUser?.uid || "", // Assigned to the current user
    date: null,
    startTime: null,
    endTime: null,
    lead: id, // Automatically linked to the offerte ID
    description: "",
    comments: "",
  });

  const toggleTaskModal = () => setTaskModalOpen(!isTaskModalOpen);
  const toggleCancelModal = () => setCancelModalOpen(!isCancelModalOpen);

  const toggleNoteModal = () => setNoteModalOpen(!isNoteModalOpen);
  const toggleModal = () => setModalOpen(!isModalOpen);

  // useEffect(() => {
  //   // Firestore listener for offerte changes
  //   const offerteRef = doc(db, "offertes", id);

  //   const unsubscribe = onSnapshot(
  //     offerteRef,
  //     (docSnapshot) => {
  //       if (docSnapshot.exists()) {
  //         const newOfferte = { id: docSnapshot.id, ...docSnapshot.data() };

  //         // Detect status change
  //         if (previousStatus && newOfferte.status !== previousStatus) {
  //           handleStatusChange(newOfferte.status);
  //         }
  //         console.log(newOfferte.status);
  //         setPreviousStatus(newOfferte.status); // Update the previous status
  //         setOfferte(newOfferte);
  //       } else {
  //         console.error("No such document!");
  //       }
  //     },
  //     (error) => {
  //       console.error("Error listening to offerte:", error);
  //     }
  //   );

  //   // Cleanup listener on component unmount
  //   return () => unsubscribe();
  // }, [id, previousStatus]);

  // const handleStatusChange = (newStatus) => {
  //   switch (newStatus) {
  //     case "Verzonden":
  //       toastHelper.success("De offerte is verzonden naar de klant.");
  //       break;
  //     case "Afgeleverd":
  //       toastHelper.success("De offerte is afgeleverd in de inbox.");
  //       break;
  //     case "Mislukt":
  //       toastHelper.error("Het verzenden van de offerte is mislukt.");
  //       break;
  //     default:
  //       console.log(`Nieuwe status: ${newStatus}`);
  //   }
  // };

  const userId = auth.currentUser ? auth.currentUser.uid : "client";
  const lastLogin = auth.currentUser
    ? new Date(parseInt(auth.currentUser.metadata.lastLoginAt))
    : null;

  const fetchLogs = async (offerteId) => {
    try {
      const logsRef = collection(db, "logs");
      const q = query(
        logsRef,
        where("offerteId", "==", offerteId),
        orderBy("createdAt", "desc")
      );
      const querySnapshot = await getDocs(q);

      const logsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // console.log(logsData);
      setLogs(logsData);
    } catch (error) {
      console.error("Error fetching logs:", error);
    }
  };

  useEffect(() => {
    const fetchOfferte = async () => {
      try {
        const docRef = doc(db, "offertes", id);
        const docSnap = await getDoc(docRef);
        if (docSnap.exists()) {
          let data = docSnap.data();
          data.id = docRef.id;
          await fetchAgentData(data.agent);
          setOfferte(data);
          fetchLogs(data.offerteNr);
        } else {
          console.error("No such document!");
        }

        if (user.role === "admin") {
          // Fetch agents
          const agentsSnap = await getDocs(collection(db, "users"));
          const fetchedAgents = agentsSnap.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setAgents(fetchedAgents);
        }
      } catch (error) {
        console.log(error);
      }
    };

    fetchOfferte();
  }, [id]);

  useEffect(() => {
    // Fetch tasks related to the offerte
    const getTasks = async () => {
      await fetchTasks();
    };

    getTasks();
  }, [id]);

  const fetchTasks = async () => {
    const tasksRef = collection(db, "tasks");
    const q = query(tasksRef, where("lead", "==", id)); // `id` is the offerte ID
    const tasksSnapshot = await getDocs(q);

    const fetchedTasks = tasksSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setTasks(fetchedTasks);

    setFilteredTasks(fetchedTasks.sort((a, b) => a.startTime - b.startTime));
  };

  const fetchOfferte = async () => {
    const docRef = doc(db, "offertes", id);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      let data = docSnap.data();
      data.id = docRef.id;
      setOfferte(data);
    } else {
      console.error("No such document!");
    }
  };

  const sendOfferte = async () => {
    offerte.id = id;
    try {
      let res = await sendQuoteEmail(offerte);
      if (res) {
        await updateOfferteStatus(offerte.id, "Verzonden", {
          sentAt: new Date(),
        });
        await createFollowUpTask(offerte);
        console.log("Email sent successfully!");
        toastHelper.success("Offerte met succes naar de klant gemaild!");
      } else {
        toastHelper.error("Er ging iets fout. Probeer het later nog eens.");
      }
    } catch (error) {
      console.log(`Error: ${error.message}`);
    }
  };

  const sendOfferteToClient = async (data) => {
    if (isSending) return;
    setIsSending(true);
    // console.log(data);
    // return;
    try {
      const res = await sendQuoteEmail(data);
      if (res) {
        console.log(res);
        await updateOfferteStatus(data.id, "Verzonden");
        // navigate(`/offertes/${docRef.id}`);
        // await createFollowUpTask(data);
        await addLog({
          offerteId: data.offerteNr,
          userId,
          message: "Offerte is naar de klant gestuurd.",
        });
        toastHelper.success("Offerte is succesvol verstuurd!");
        fetchOfferte();
        fetchLogs(data.offerteNr);
        setIsSending(false);
        // navigate(`/offertes/${id}`);
      } else {
        await updateOfferteStatus(id, "Verzenden mislukt");
        toastHelper.error(
          "Er is iets misgegaan. Probeer het later nog eens of neem contact op met je contactpersoon."
        );
      }

      // Optional: You can trigger an email API here
      // Example: Call an external API or email service
      // await sendEmail(data.customerDetails.email, docRef.id, data);
    } catch (error) {
      console.error("Error sending offerte to client: ", error);
      if (id) {
        await updateOfferteStatus(id, "Verzenden mislukt", {
          errorMessage: error.message,
        });
        toastHelper.error(
          "Er is iets misgegaan. Probeer het later nog eens of neem contact op met je contactpersoon."
        );
      }
      setIsSending(false);
    }
  };

  const handleAddNote = async () => {
    if (!noteTitle || !noteDescription) {
      alert("Titel en beschrijving zijn verplicht.");
      return;
    }

    try {
      const logData = {
        offerteId: offerte.offerteNr,
        userId,
        message: noteTitle,
        extraDetails: noteDescription,
        createdAt: serverTimestamp(),
      };

      await addDoc(collection(db, "logs"), logData);
      fetchLogs(offerte.offerteNr);
      toggleNoteModal();
      setNoteTitle("");
      setNoteDescription("");
    } catch (error) {
      console.error("Error adding note:", error);
    }
  };

  const handleSaveTask = async () => {
    if (!newTask.title || !newTask.startTime || !newTask.endTime) {
      toastHelper.error("Titel, starttijd en eindtijd zijn verplicht.");
      return;
    }

    try {
      const taskData = {
        ...newTask,
        createdAt: serverTimestamp(),
      };

      await addDoc(collection(db, "tasks"), taskData);
      setTasks([...tasks, taskData]); // Add to local state
      toastHelper.success("Taak succesvol aangemaakt!");
      setTaskModalOpen(false);
      setNewTask({
        type: "bel afspraak",
        title: "",
        agent: auth.currentUser?.uid || "",
        date: null,
        startTime: null,
        endTime: null,
        lead: id,
        description: "",
        comments: "",
      });
    } catch (error) {
      console.error("Error adding task:", error);
      toastHelper.error("Er is een fout opgetreden bij het maken van de taak.");
    }
  };

  const handleAddTask = () => {
    // Logic to open modal for adding a new task
    setTaskModalOpen(true);
  };

  const fetchAgentData = async (agentId) => {
    if (!agentId) return;
    try {
      const agentRef = doc(db, "users", agentId); // Replace "agents" with your Firestore collection for agents
      const agentSnap = await getDoc(agentRef);

      if (agentSnap.exists()) {
        // console.log(agentSnap.data());
        setAgent(agentSnap.data());
      } else {
        console.error("Agent not found!");
      }
    } catch (error) {
      console.error("Error fetching agent data:", error);
    }
  };

  const handleCancelOfferte = async () => {
    if (!cancelReason.trim()) {
      toastHelper.error("Selecteer een reden voor de annulering.");
      return;
    }

    try {
      const logMessage =
        cancelReason === "Overig" && cancelDescription
          ? `${cancelReason}: ${cancelDescription}`
          : cancelReason;

      // Update offerte status to "Geannuleerd"
      await updateOfferteStatus(offerte.id, "cancelled", {
        canceledAt: new Date(),
        cancelReason: logMessage,
      });

      // Log the cancellation
      await addLog({
        offerteId: offerte.offerteNr,
        userId,
        message: "Offerte geannuleerd.",
        extraDetails: logMessage,
        createdAt: serverTimestamp(),
      });

      toastHelper.success("De offerte is geannuleerd.");
      setCancelReason("");
      setCancelDescription("");
      setCancelModalOpen(false);
      fetchOfferte(); // Refresh the offerte data
    } catch (error) {
      console.error("Error canceling offerte:", error);
      toastHelper.error("Er is een fout opgetreden bij het annuleren.");
    }
  };

  if (!offerte) {
    return (
      <div>
        Offerte niet gevonden. Zoek hem hier:{" "}
        <Link to="/offertes"> Naar offertes</Link>
      </div>
    );
  }
  console.log(offerte.status);
  return (
    <div className="container">
      {isSending && (
        <div className="overlay">
          <Spinner color="primary" />
          <p>De offerte wordt verzonden... Even geduld.</p>
        </div>
      )}
      <Row>
        <Col>
          <div className="title-flex">
            <h2>Offerte Details</h2>
            <div className="agent-block">
              Adviseur: {agent.personalInfo.firstName}{" "}
              {agent.personalInfo.lastName}
            </div>
          </div>
        </Col>
        <Col sm={{ size: "auto" }}>
          <div className="action-buttons">
            {/* <Button
              onClick={() => createFollowUpTask(offerte)}
              color="primary"
              className="icon-button"
              title="Bekijk Offerte"
            >
              create follow up
            </Button> */}
            {/* <Button
              onClick={() => toggleTaskModal()}
              color="primary"
              className="icon-button"
              title="Nieuwe Taak"
            >
              Taak toevoegen
            </Button> */}
            <Button
              onClick={() =>
                window.open(
                  `/public/offerte/${id}/${offerte.passcode}`,
                  "_blank"
                )
              }
              color="primary"
              className="icon-button"
              title="Bekijk Offerte"
            >
              <FaEye /> Bekijken {/* Eye icon */}
            </Button>

            <Button
              onClick={() => (window.location.href = `/offertes/edit/${id}`)}
              color="warning"
              className="icon-button"
              title="Bewerk Offerte"
            >
              <FaEdit /> Aanpassen {/* Edit icon */}
            </Button>
            {!["cancelled", "Geannuleerd"].includes(offerte.status) && (
              <>
                <Button
                  color="success"
                  onClick={() => sendOfferteToClient(offerte)}
                  disabled={isSending}
                  className="icon-button"
                  title="Verzend naar Klant"
                >
                  <FaPaperPlane /> Verzenden {/* Paper Plane icon */}
                </Button>

                <Button
                  color="danger"
                  onClick={() => setCancelModalOpen(true)}
                  className="icon-button"
                  title="Annuleer Offerte"
                >
                  Annuleren
                </Button>
              </>
            )}

            {/* 
            <Button
              color="danger"
              className="icon-button"
              title="Annuleer Offerte"
            >
              <FaTrashAlt /> Verwijder 
            </Button> */}
          </div>
        </Col>
      </Row>

      <Row>
        <Col md={8}>
          <Card className="mb-4 info-block">
            <div className={`status ${offerte.status}`}>
              <strong>Status: </strong> &nbsp; {getStatusName(offerte.status)}{" "}
            </div>
            <CardBody>
              <div className="title-flex">
                <h5>Offerte #{offerte.offerteNr}</h5> |{" "}
                <span className="product-name">
                  {offerte.selectedProduct?.naam}
                </span>
              </div>
              <Row>
                <Col>
                  <p>
                    <strong>Contactgegevens:</strong> <br />
                    <strong>Naam:</strong> {offerte.customerDetails?.firstName}{" "}
                    {offerte.customerDetails?.lastName}
                    <br />
                    <strong>Email:</strong> {offerte.customerDetails?.email}
                    <br />
                    <strong>Telefoon:</strong> {offerte.customerDetails?.phone}
                    <br />
                  </p>
                </Col>
                <Col>
                  <p>
                    <strong>Adres:</strong> <br />
                    {offerte.customerDetails?.straat}
                    {offerte.customerDetails?.huisnummer} <br />
                    {offerte.customerDetails?.postcode}{" "}
                    {offerte.customerDetails?.plaats}
                    <br />
                    {offerte.customerDetails?.provincie}
                  </p>
                </Col>
              </Row>
            </CardBody>
          </Card>

          <TaskTabs
            tasks={tasks}
            onAddTask={handleAddTask}
            agent={agent}
            onFinishTask={fetchTasks}
            // onTaskClick={handleTaskClick}
          />

          <Card className="p-4 shadow-sm">
            <div>
              <Row style={{ marginBottom: 40, marginTop: 20 }}>
                <Col>
                  <h3>
                    <strong>Het product</strong>
                  </h3>
                  <img
                    src={offerte.selectedProduct.images[0]}
                    alt={offerte.selectedProduct.naam}
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                  <div className="datasheets">
                    <h5>Datasheets</h5>
                    {offerte.selectedProduct.datasheets?.map((extra, index) => (
                      <div key={index}>
                        <a
                          href={extra}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Thuisbatterij Datasheet
                        </a>
                      </div>
                    ))}
                  </div>
                </Col>
                <Col xs={6}>
                  <h5 style={{ margin: "20px 0" }}>
                    {offerte.selectedProduct.naam}
                  </h5>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: offerte.selectedProduct.beschrijving,
                    }}
                  ></p>
                  <div className="separator price"></div>

                  {offerte.selectedExtras.length > 0 && (
                    <>
                      <h5>Meerwerk</h5>

                      <ul>
                        {offerte.selectedExtras.map((a, i) => {
                          // console.log(a);
                          return (
                            <li key={i}>
                              {a.name}:{" "}
                              {a.type === "quantity" && <>X {a.quantity}</>}
                            </li>
                          );
                        })}
                      </ul>
                    </>
                  )}
                </Col>
              </Row>
            </div>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-4">
            <CardBody>
              <h5>Betalingscondities</h5>
              {offerte.selectedFinancingOption && (
                <>
                  <div className="pay-condition">
                    <strong>{offerte.selectedFinancingOption.name}</strong>
                  </div>

                  <span>
                    {offerte.selectedFinancingOption.paymentCondition}
                  </span>
                  {offerte.selectedFinancingOption.selectedPartner && (
                    <div className="pay-condition">
                      <p className="mb-1 mt-2" style={{ fontWeight: "bold" }}>
                        Partner
                      </p>
                      <span className={`provider`}>
                        {offerte.selectedFinancingOption.selectedPartner}{" "}
                        <a
                          target="_blank"
                          href={
                            offerte.selectedFinancingOption.selectedPartner ===
                            "Greenloans"
                              ? "https://www.krediet.nl/greenloans/"
                              : "https://aanvraag.warmtefonds.nl/1"
                          }
                        >
                          aanvragen
                        </a>
                        {/* <a target="_blank" href="#">
                          aanvragen
                        </a> */}
                      </span>
                    </div>
                  )}
                </>
              )}
            </CardBody>
          </Card>

          <Card className="mb-4">
            <CardBody>
              <h5>Prijs</h5>
              <p className="pricing">
                Prijs (excl. BTW):{" "}
                <span>€{offerte.priceDetails?.basePrice}</span>
              </p>
              {offerte.priceDetails.basePrice -
                offerte.priceDetails.discountedPrice >
                0 && (
                <>
                  <p className="pricing discount">
                    - Korting:{" "}
                    <span>
                      - €
                      {(
                        offerte.priceDetails.basePrice -
                        offerte.priceDetails.discountedPrice
                      ).toLocaleString()}
                    </span>
                  </p>
                  <p className="pricing">
                    Prijs na korting (excl. BTW):{" "}
                    <span>
                      €{offerte.priceDetails.discountedPrice.toLocaleString()}
                    </span>
                  </p>
                </>
              )}

              <div className="separator price"></div>

              <p className="pricing">
                BTW (21%): <span>€{offerte.priceDetails.VAT}</span>
              </p>
              <div className="separator price"></div>

              <p className="pricing total-price">
                <strong>Totaal (incl. BTW):</strong>{" "}
                <span>€{offerte.priceDetails.totalIncludingVAT}</span>
              </p>
            </CardBody>
          </Card>

          {/* Logs Section */}

          <Card className="mb-4 logs">
            <h5 style={{ padding: "20px 20px 0 20px" }}>
              Logs{" "}
              <span onClick={toggleNoteModal} className="icon-button">
                <FaPlus />
              </span>
            </h5>

            <CardBody>
              {logs.length > 0 ? (
                <div className="timeline">
                  {logs.map((log, index) => (
                    <div className="timeline-item" key={log.id}>
                      <div className="timeline-icon">
                        <span>{getIcon(log.message)}</span>
                      </div>
                      <div className="timeline-content">
                        <div className="timeline-header">
                          <span className="timeline-time">
                            {moment(log.createdAt.toDate()).fromNow()}
                          </span>
                          {lastLogin &&
                            log.createdAt.toDate() > lastLogin && ( // Check if the log is new
                              <span className="timeline-dot"></span>
                            )}
                        </div>
                        <div className="timeline-body">
                          <strong>{log.message}</strong>
                          <p className="timeline-details">
                            {log.extraDetails
                              ? log.extraDetails
                              : "Details not provided"}
                          </p>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p>Geen logs gevonden voor deze offerte.</p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
      <PdfViewerModal
        isOpen={isModalOpen}
        toggle={toggleModal}
        pdfBlob={pdfBlob}
      />
      {/* Note Modal */}
      <Modal isOpen={isNoteModalOpen} toggle={toggleNoteModal}>
        <ModalHeader toggle={toggleNoteModal}>Voeg Notitie Toe</ModalHeader>
        <ModalBody>
          <Label for="noteTitle">Titel</Label>
          <Input
            id="noteTitle"
            value={noteTitle}
            onChange={(e) => setNoteTitle(e.target.value)}
            placeholder="Voer een titel in..."
          />

          <Label for="noteDescription" className="mt-3">
            Beschrijving
          </Label>
          <Input
            id="noteDescription"
            type="textarea"
            value={noteDescription}
            onChange={(e) => setNoteDescription(e.target.value)}
            placeholder="Voer een beschrijving in..."
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAddNote}>
            Opslaan
          </Button>
          <Button color="secondary" onClick={toggleNoteModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>

      <TaskModal
        isOpen={isTaskModalOpen}
        toggle={toggleTaskModal}
        agents={agents}
        lead={offerte} // Pass the offerte as lead
        initialData={{
          type: "bel afspraak", // Default task type
          agent: auth.currentUser?.uid || "",
          lead: id, // Link task to the current offerte
        }}
        refreshTasks={() => {
          // Optional: Add logic to refresh tasks if needed
          toastHelper.success("Taak bijgewerkt.");
        }}
      />
      <Modal isOpen={isCancelModalOpen} toggle={toggleCancelModal}>
        <ModalHeader toggle={toggleCancelModal}>Offerte Annuleren</ModalHeader>
        <ModalBody>
          <p>
            Weet u zeker dat u deze offerte wilt annuleren? Selecteer een reden
            en voeg eventueel een toelichting toe.
          </p>
          <Label for="reasonSelect">Reden voor annulering:</Label>
          <Input
            type="select"
            id="reasonSelect"
            value={cancelReason}
            onChange={(e) => setCancelReason(e.target.value)}
          >
            <option value="">Selecteer een reden</option>
            <option value="Te duur">Te duur</option>
            <option value="Niet meer geïnteresseerd">
              Niet meer geïnteresseerd
            </option>
            <option value="Klant heeft een andere oplossing gekozen">
              Klant heeft een andere oplossing gekozen
            </option>
            <option value="Technische problemen">Technische problemen</option>
            <option value="Overig">Overig</option>
          </Input>

          <Label for="additionalDescription" className="mt-3">
            Toelichting:
          </Label>
          <Input
            type="textarea"
            id="additionalDescription"
            value={cancelDescription}
            onChange={(e) => setCancelDescription(e.target.value)}
            placeholder="Vul hier een toelichting in..."
          />
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            onClick={handleCancelOfferte}
            disabled={!cancelReason || cancelDescription.length < 10} // Disable button if no reason is selected
          >
            Bevestig Annulering
          </Button>
          <Button color="secondary" onClick={toggleCancelModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SingleOffertePage;
