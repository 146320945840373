import React, { useState } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const energyProviders = [
  { name: "Budget Energie", feedInCost: 0.12, purchaseCost: 0.27 },
  { name: "Delta", feedInCost: 0.143, purchaseCost: 0.27 },
  { name: "Eneco", feedInCost: 0.143, purchaseCost: 0.27 },
];

const batteryData = [
  { brand: "Dyness", capacity: 14.1, price: 7615, usableEnergy: 13.499 },
  { brand: "Blauhof", capacity: 20, price: 10190, usableEnergy: 20 },
];

const consumptionProfiles = {
  low_day_high_night: { day: 0.3, night: 0.7 },
  high_day_high_night: { day: 0.5, night: 0.5 },
  low_day_low_night: { day: 0.4, night: 0.6 },
  high_day_low_night: { day: 0.6, night: 0.4 },
};

const SavingsCalculator = () => {
  const [userInput, setUserInput] = useState({
    annualProduction: "", // kWh produced yearly
    annualConsumption: "", // kWh consumed yearly
    selectedProvider: energyProviders[0], // Default provider
    selectedBattery: null, // Selected battery
    dynamicMarket: false, // Dynamic market enabled/disabled
    consumptionProfile: "low_day_high_night", // Default consumption profile
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [results, setResults] = useState(null);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserInput({
      ...userInput,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleProviderSelect = (e) => {
    const selectedProvider = energyProviders[parseInt(e.target.value)];
    setUserInput({ ...userInput, selectedProvider });
  };

  const handleBatterySelect = (e) => {
    const selectedBattery = batteryData[parseInt(e.target.value)];
    setUserInput({ ...userInput, selectedBattery });
  };

  const calculateSavings = () => {
    const {
      annualProduction,
      annualConsumption,
      selectedProvider,
      selectedBattery,
      dynamicMarket,
      consumptionProfile,
    } = userInput;

    if (!selectedBattery) return;

    const production = parseFloat(annualProduction);
    const consumption = parseFloat(annualConsumption);
    const { feedInCost, purchaseCost } = selectedProvider;
    const { day, night } = consumptionProfiles[consumptionProfile];

    // **Dag- en nachtverbruik verdelen**
    const dayProduction = production * day;
    const nightProduction = production * night;

    const dayConsumption = consumption * day;
    const nightConsumption = consumption * night;

    // Scenario 1: Zonder batterij
    const selfConsumptionDayNoBattery = Math.min(dayProduction, dayConsumption);
    const selfConsumptionNightNoBattery = Math.min(
      nightProduction,
      nightConsumption
    );
    const selfConsumptionNoBattery =
      selfConsumptionDayNoBattery + selfConsumptionNightNoBattery;

    const gridExportNoBattery = production - selfConsumptionNoBattery;
    const gridImportNoBattery = consumption - selfConsumptionNoBattery;

    const revenueFromExportNoBattery = gridExportNoBattery * feedInCost;
    const costFromImportNoBattery = gridImportNoBattery * purchaseCost;
    const totalCostNoBattery =
      costFromImportNoBattery - revenueFromExportNoBattery;

    // Scenario 2: Met batterij (zonder dynamische markt)
    const selfConsumptionDayWithBattery = Math.min(
      dayProduction,
      dayConsumption + selectedBattery.usableEnergy
    );
    const selfConsumptionNightWithBattery = Math.min(
      nightProduction,
      nightConsumption + selectedBattery.usableEnergy
    );
    const selfConsumptionWithBattery =
      selfConsumptionDayWithBattery + selfConsumptionNightWithBattery;

    const gridExportWithBattery = production - selfConsumptionWithBattery;
    const gridImportWithBattery = consumption - selfConsumptionWithBattery;

    const revenueFromExportWithBattery = gridExportWithBattery * feedInCost;
    const costFromImportWithBattery = gridImportWithBattery * purchaseCost;
    const totalCostWithBattery =
      costFromImportWithBattery - revenueFromExportWithBattery;

    // Scenario 3: Met batterij en dynamische markt
    let costWithDynamicMarket = totalCostWithBattery;
    if (dynamicMarket) {
      const cheapGridImport = gridImportWithBattery * 0.1; // Opladen goedkoop
      const revenueFromPeakSelling = gridExportWithBattery * 0.6; // Verkopen duur
      costWithDynamicMarket =
        cheapGridImport - revenueFromPeakSelling + totalCostWithBattery;
    }

    // Resultaten opslaan
    setResults({
      provider: selectedProvider.name,
      battery: selectedBattery.brand,
      totalCostNoBattery: totalCostNoBattery.toFixed(2),
      totalCostWithBattery: totalCostWithBattery.toFixed(2),
      costWithDynamicMarket: costWithDynamicMarket.toFixed(2),
    });

    setCurrentStep(4);
  };

  const chartData = results
    ? {
        labels: ["Zonder batterij", "Met batterij", "Met dynamische markt"],
        datasets: [
          {
            label: "Kosten (€)",
            data: [
              results.totalCostNoBattery,
              results.totalCostWithBattery,
              results.costWithDynamicMarket,
            ],
            backgroundColor: [
              "rgba(75, 192, 192, 0.6)",
              "rgba(153, 102, 255, 0.6)",
              "rgba(255, 159, 64, 0.6)",
            ],
          },
        ],
      }
    : null;

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: { display: false },
      title: { display: true, text: "Kosten Vergelijking" },
    },
  };

  const renderResults = () => (
    <div>
      <h3>Resultaten</h3>
      <p>
        <strong>Leverancier:</strong> {results.provider}
      </p>
      <p>
        <strong>Batterij:</strong> {results.battery}
      </p>

      <h4>Zonder batterij:</h4>
      <p>Totale kosten: €{results.totalCostNoBattery}</p>

      <h4>Met batterij (zonder dynamische markt):</h4>
      <p>Totale kosten: €{results.totalCostWithBattery}</p>

      <h4>Met batterij (met dynamische markt):</h4>
      <p>Totale kosten: €{results.costWithDynamicMarket}</p>

      {/* Grafiek */}
      {chartData && <Bar data={chartData} options={chartOptions} />}
    </div>
  );

  return (
    <div
      style={{
        maxWidth: "600px",
        margin: "auto",
        fontFamily: "Arial, sans-serif",
      }}
    >
      <h2>Thuisbatterij Besparingscalculator</h2>
      {currentStep === 1 && (
        <div>
          <h3>Stap 1: Energiegegevens</h3>
          <label>
            Jaarlijkse opwekking (kWh):
            <input
              type="number"
              name="annualProduction"
              value={userInput.annualProduction}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Jaarlijks verbruik (kWh):
            <input
              type="number"
              name="annualConsumption"
              value={userInput.annualConsumption}
              onChange={handleChange}
            />
          </label>
          <br />
          <label>
            Dag-/nachtverbruik profiel:
            <select
              name="consumptionProfile"
              value={userInput.consumptionProfile}
              onChange={handleChange}
            >
              {Object.keys(consumptionProfiles).map((key) => (
                <option key={key} value={key}>
                  {key.replace(/_/g, " ")}
                </option>
              ))}
            </select>
          </label>
          <br />
          <button onClick={() => setCurrentStep(2)}>Volgende</button>
        </div>
      )}

      {currentStep === 2 && (
        <div>
          <h3>Stap 2: Kies een batterij</h3>
          <select onChange={handleBatterySelect}>
            <option value="">-- Kies een batterij --</option>
            {batteryData.map((battery, index) => (
              <option key={index} value={index}>
                {battery.brand} - {battery.capacity} kWh - €{battery.price}
              </option>
            ))}
          </select>
          <br />
          <button onClick={() => setCurrentStep(1)}>Terug</button>
          <button onClick={() => setCurrentStep(3)}>Verder</button>
        </div>
      )}

      {currentStep === 3 && (
        <div>
          <h3>Stap 3: Energieprijzen en Dynamische Markt</h3>
          <label>
            Gebruik dynamische markt:
            <input
              type="checkbox"
              name="dynamicMarket"
              checked={userInput.dynamicMarket}
              onChange={handleChange}
            />
          </label>
          <br />
          <button onClick={() => setCurrentStep(2)}>Terug</button>
          <button onClick={calculateSavings}>Bereken</button>
        </div>
      )}

      {currentStep === 4 && renderResults()}
    </div>
  );
};

export default SavingsCalculator;
