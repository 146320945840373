import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Card, CardBody, Button, Row, Col } from "reactstrap";
import { FaFilePdf, FaFileAlt } from "react-icons/fa";
import "./MediaPage.css"; // Voeg een CSS-bestand toe voor styling
import { useUser } from "../context/UserContext";

const MediaPage = () => {
  const { user } = useUser();
  const [mediaFiles, setMediaFiles] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("klant");
  const [loading, setLoading] = useState(true);
  const [userGroups, setUserGroups] = useState([]);

  const fetchUserGroups = async () => {
    try {
      if (!user) return;
      const userRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userRef);
      const userData = userDoc.exists() ? userDoc.data() : null;

      if (userData && userData.groupId) {
        const groupsQuery = query(
          collection(db, "groups"),
          where("id", "==", userData.groupId)
        );
        const groupsSnap = await getDocs(groupsQuery);
        const groups = groupsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserGroups(groups);
      }
    } catch (error) {
      console.error("Error fetching user groups:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchMediaFiles = async () => {
    try {
      setLoading(true);
      const mediaRef = collection(db, "media");
      const q = query(mediaRef, where("category", "==", selectedCategory));
      const querySnapshot = await getDocs(q);

      const files = querySnapshot.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .filter((file) =>
          file.userGroups?.some((groupId) =>
            userGroups.some((group) => group.id === groupId)
          )
        );

      setMediaFiles(files);
    } catch (error) {
      console.error("Error fetching media files:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserGroups();
  }, [user]);

  useEffect(() => {
    if (userGroups.length > 0) {
      fetchMediaFiles();
    }
  }, [selectedCategory, userGroups]);

  return (
    <div className="container media-page">
      <h2>Media</h2>
      <div className="category-selector">
        <Button
          color={selectedCategory === "klant" ? "primary" : "secondary"}
          onClick={() => setSelectedCategory("klant")}
        >
          Klant
        </Button>
        <Button
          color={selectedCategory === "verkoper" ? "primary" : "secondary"}
          onClick={() => setSelectedCategory("verkoper")}
        >
          Verkoper
        </Button>
      </div>

      {loading ? (
        <p>Bestanden laden...</p>
      ) : mediaFiles.length > 0 ? (
        <Row>
          {mediaFiles.map((file) => (
            <Col md={4} key={file.id}>
              <Card className="media-card">
                <CardBody>
                  <div className="icon">
                    {file.fileUrl.endsWith(".pdf") ? (
                      <FaFilePdf size={40} />
                    ) : (
                      <FaFileAlt size={40} />
                    )}
                  </div>
                  <h5>{file.title}</h5>
                  <p>{file.description}</p>
                  <a
                    href={file.fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Button color="success">Download</Button>
                  </a>
                </CardBody>
              </Card>
            </Col>
          ))}
        </Row>
      ) : (
        <p>Geen bestanden gevonden in de geselecteerde categorie.</p>
      )}
    </div>
  );
};

export default MediaPage;
