import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
} from "reactstrap";

const DiscountModal = ({
  isOpen,
  toggle,
  discounts,
  customDiscount,
  onSave,
}) => {
  const [localDiscounts, setLocalDiscounts] = useState(discounts || []);
  const [localCustomDiscount, setLocalCustomDiscount] =
    useState(customDiscount);

  // Synchronize local state when props change
  useEffect(() => {
    setLocalDiscounts(discounts);
    setLocalCustomDiscount(customDiscount);
  }, [discounts, customDiscount]);

  const toggleDiscount = (index) => {
    setLocalDiscounts((prev) =>
      prev.map((discount, i) =>
        i === index ? { ...discount, selected: !discount.selected } : discount
      )
    );
  };

  const handleCustomDiscountChange = (e) => {
    const { name, value } = e.target;
    setLocalCustomDiscount((prev) => ({
      ...prev,
      [name]: name === "value" ? parseFloat(value) || 0 : value,
    }));
  };

  const toggleCustomDiscount = () => {
    setLocalCustomDiscount((prev) => ({
      ...prev,
      selected: !prev.selected,
    }));
  };

  return (
    <Modal
      size="lg"
      style={{ maxWidth: "700px", width: "100%" }}
      isOpen={isOpen}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}>Kortingen beheren</ModalHeader>
      <ModalBody>
        <h5>Selecteer kortingen</h5>
        {localDiscounts.map((discount, index) => (
          <Row key={discount.id} className="align-items-center mb-3">
            <Col md={6}>
              <h6>{discount.name}</h6>
            </Col>
            <Col md={3}>
              <p>
                {discount.type === "percentage"
                  ? `-${discount.value}%`
                  : `-€${discount.value}`}
              </p>
            </Col>
            <Col md={3}>
              <FormGroup check>
                <Label check>
                  <Input
                    type="checkbox"
                    checked={discount.selected}
                    onChange={() => toggleDiscount(index)}
                  />
                </Label>
              </FormGroup>
            </Col>
          </Row>
        ))}

        {/* Custom Discount Section */}
        <h5 className="mt-4">Aangepaste korting toevoegen</h5>
        <Row className="align-items-center mb-3">
          <Col md={4}>
            <Input
              type="text"
              name="name"
              value={localCustomDiscount?.name}
              onChange={handleCustomDiscountChange}
              placeholder="Naam van korting"
            />
          </Col>
          <Col md={3}>
            <Input
              type="number"
              name="value"
              value={
                localCustomDiscount?.value === 0
                  ? ""
                  : localCustomDiscount?.value
              } // Show empty when value is 0
              onChange={(e) => {
                const { name, value } = e.target;
                handleCustomDiscountChange({
                  target: {
                    name,
                    value: value === "" ? "" : parseFloat(value) || 0,
                  },
                });
              }}
              placeholder="Waarde"
            />
          </Col>
          <Col md={3}>
            <Input
              type="select"
              name="type"
              value={localCustomDiscount?.type}
              onChange={handleCustomDiscountChange}
            >
              <option value="fixed">Vast bedrag (€)</option>
              <option value="percentage">Percentage (%)</option>
            </Input>
          </Col>
          <Col md={2}>
            <FormGroup check>
              <Label check>
                <Input
                  type="checkbox"
                  checked={localCustomDiscount?.selected}
                  onChange={toggleCustomDiscount}
                />
              </Label>
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={() => {
            onSave(localDiscounts, localCustomDiscount); // Pass updated discounts and custom discount to parent
            toggle(); // Close modal
          }}
        >
          Opslaan
        </Button>
        <Button color="secondary" onClick={toggle}>
          Annuleren
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DiscountModal;
