import React, { createContext, useState, useEffect, useContext } from "react";
import { auth, db, getAgents, getGroupById } from "../firebaseConfig"; // Import Firebase Auth and Firestore
import { onAuthStateChanged, signOut } from "firebase/auth";
import { doc, getDoc, collection, getDocs } from "firebase/firestore";

// Create User Context
const UserContext = createContext();

// Provider Component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Store user data
  const [agents, setagents] = useState([]); // Store user data
  const [loading, setLoading] = useState(true); // Loading state
  const [usersCache, setUsersCache] = useState({}); // Cache for user data

  useEffect(() => {
    // Listen for auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        const uid = currentUser.uid;
        try {
          const userDocRef = doc(db, "users", uid);
          const userDocSnap = await getDoc(userDocRef);

          if (userDocSnap.exists()) {
            let userData = userDocSnap.data();
            if (userData.groupId) {
              userData.userGroup = await getGroupById(userData.groupId);
            }
            setUser({ uid, ...userData });
            if (userData.role === "admin") {
              const agentsData = await getAgents();
              setagents(agentsData);
            } else {
              console.log(user);
            }
          } else {
            console.error("No such user in Firestore");
          }
        } catch (error) {
          console.log(user);
          console.error("Error fetching user data:", error);
        }
      } else {
        setUser(null);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const getAgentName = (id) => {
    let agent = null;
    if (agents.length > 0) {
      agent = agents.find((agent) => agent.id === id);
    }

    if (agent) {
      const { firstName } = agent.personalInfo || {};
      // Construct full name including optional infix
      return `${firstName}`.trim();
    }
    return "Agent not found";
  };

  // Function to fetch a user by ID
  const getUserById = async (userId) => {
    if (usersCache[userId]) {
      return usersCache[userId];
    }

    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = { id: userId, ...userDocSnap.data() };

        if (userData.groupId) {
          userData.userGroup = await getGroupById(userData.groupId);
        }

        setUsersCache((prevCache) => ({ ...prevCache, [userId]: userData }));
        return userData;
      } else {
        console.error("No such user in Firestore");
        return null;
      }
    } catch (error) {
      console.error("Error fetching user data:", error);
      return null;
    }
  };

  // Logout function
  const logout = async () => {
    try {
      await signOut(auth);
      setUser(null);
    } catch (error) {
      console.error("Error during logout:", error);
    }
  };

  return (
    <UserContext.Provider
      value={{ user, agents, loading, logout, getUserById, getAgentName }}
    >
      {children}
    </UserContext.Provider>
  );
};

// Custom hook for accessing UserContext
export const useUser = () => {
  return useContext(UserContext);
};
