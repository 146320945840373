import { useLocation } from "react-router-dom";

const usePageIdentifier = () => {
  const location = useLocation();
  const path = location.pathname;

  if (path.startsWith("/admin/dashboard")) return "Dashboard";
  if (path.startsWith("/admin/offertes")) return "Offertes";
  if (path.startsWith("/admin/leads")) return "Leads";
  if (path.startsWith("/admin/producten")) return "Producten";
  if (path.startsWith("/admin/producten-category")) return "Categorieen";
  if (path.startsWith("/admin/pakketten")) return "Pakketten";
  if (path.startsWith("/admin/discounts")) return "Discount";
  if (path.startsWith("/admin/gebruikers")) return "Users";
  if (path.startsWith("/admin/taken")) return "Taken";
  if (path.startsWith("/admin/faq")) return "Faq";
  if (path.startsWith("/taken")) return "Taken";
  if (path.startsWith("/leads")) return "Leads";
  if (path.startsWith("/agenda")) return "Agenda";
  if (path.startsWith("/offertes")) return "Offertes";
  if (path.startsWith("/sales-tools")) return "Sales-tools";
  if (path.startsWith("/media")) return "Media";
  if (path.startsWith("/faq")) return "Faq";

  return "Admin";
};

export default usePageIdentifier;
