import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import {
  FaTachometerAlt,
  FaUsers,
  FaTasks,
  FaFileAlt,
  FaFileDownload,
  FaChevronDown,
  FaChevronUp,
  FaQuestionCircle,
  FaBox,
  FaBoxes,
} from "react-icons/fa";
import logo from "../../Assets/logo-white.webp";
import "./SideBar.css";
import {
  FaBoxesPacking,
  FaBoxesStacked,
  FaMoneyBill1Wave,
  FaMoneyBillTransfer,
  FaPeopleGroup,
} from "react-icons/fa6";

import { BsBoxSeam, BsBoxSeamFill, BsMegaphoneFill } from "react-icons/bs";
import usePageIdentifier from "../../utils/usePageIdentifier";

const Sidebar = () => {
  const currentPage = usePageIdentifier();
  const [userSubmenuOpen, setUserSubmenuOpen] = useState(false);
  const [productSubmenuOpen, setProductSubmenuOpen] = useState(false);

  const toggleUserSubmenu = () => setUserSubmenuOpen(!userSubmenuOpen);
  const toggleProductSubmenu = () => setProductSubmenuOpen(!productSubmenuOpen);

  return (
    <div className="sidebar">
      <div className="logo">
        <img
          src={logo}
          alt="Thuisbatterij Experts"
          style={{ height: "100%" }}
        />
      </div>
      <nav className="menu">
        <ul>
          <li>
            <NavLink
              to="/admin/dashboard"
              className={currentPage === "Dashboard" ? "active" : ""}
              end
            >
              <FaTachometerAlt /> Dashboard
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/offertes"
              className={currentPage === "Offertes" ? "active" : ""}
              end
            >
              <FaFileAlt /> Offertes
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/leads"
              className={currentPage === "Leads" ? "active" : ""}
              end
            >
              <BsMegaphoneFill /> Leads
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/taken"
              className={currentPage === "Taken" ? "active" : ""}
              end
            >
              <FaTasks /> Taken
            </NavLink>
          </li>
          {/* <li>
            <NavLink
              to="/admin/producten"
              className={
                currentPage === "Producten" || currentPage === "Categorieen"
                  ? "active"
                  : ""
              }
              end
            >
              <FaBox /> Producten
            </NavLink>
          </li> */}

          <li className="has-submenu">
            <div
              className={`submenu-toggle ${
                currentPage === "Producten" ? "active" : ""
              }`}
              onClick={toggleProductSubmenu}
            >
              <FaBox /> Producten
              {productSubmenuOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <ul className={`submenu ${productSubmenuOpen ? "open" : ""}`}>
              <li>
                <NavLink
                  to="/admin/producten"
                  className={currentPage === "Producten" ? "active" : ""}
                >
                  Alle Producten
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/producten-categorieen"
                  className={currentPage === "Categorieen" ? "active" : ""}
                >
                  Categorieën
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink
              to="/admin/pakketten"
              className={currentPage === "Pakketten" ? "active" : ""}
              end
            >
              <FaBoxesPacking /> Pakketten
            </NavLink>
          </li>

          {/* Users Menu with Submenu */}
          <li className="has-submenu">
            <div
              className={`submenu-toggle ${
                currentPage === "Users" || currentPage === "Groups"
                  ? "active"
                  : ""
              }`}
              onClick={toggleUserSubmenu}
            >
              <FaPeopleGroup /> Gebruikers
              {userSubmenuOpen ? <FaChevronUp /> : <FaChevronDown />}
            </div>
            <ul className={`submenu ${userSubmenuOpen ? "open" : ""}`}>
              <li>
                <NavLink
                  to="/admin/gebruikers"
                  className={currentPage === "Users" ? "active" : ""}
                >
                  Alle Gebruikers
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/admin/groepen"
                  className={currentPage === "Groups" ? "active" : ""}
                >
                  Groepen
                </NavLink>
              </li>
            </ul>
          </li>

          <li>
            <NavLink
              to="/admin/media"
              className={currentPage === "Media" ? "active" : ""}
              end
            >
              <FaFileDownload /> Media
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/kortingen"
              className={currentPage === "Discount" ? "active" : ""}
              end
            >
              <FaMoneyBill1Wave /> Kortingen
            </NavLink>
          </li>
          <li>
            <NavLink
              to="/admin/faq"
              className={currentPage === "Faq" ? "active" : ""}
              end
            >
              <FaQuestionCircle /> Kennisbank
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Sidebar;
