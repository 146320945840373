import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Progress,
  Container,
  Row,
  Col,
} from "reactstrap";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebaseConfig";

const SchouwModal = ({ isOpen, onClose, onSave, lead }) => {
  const [step, setStep] = useState(1);
  const [showImageInput, setShowImageInput] = useState(false);
  const [newImageName, setNewImageName] = useState("");
  const [formData, setFormData] = useState({
    schouwDate: "",
    schouwTime: "",
    batteryLocation: "",
    inverterLocation: "",
    spaceDetails: "",
    ventilation: "",
    accessNotes: "",
    obstacles: "",
    plannedExtensions: "",
    products: lead?.products || [],
    photos: [
      { name: "Meterkast", url: "" },
      { name: "Locatie van de batterij", url: "" },
      { name: "Omvormer locatie", url: "" },
      { name: "Toegangspad", url: "" },
      { name: "Ventilatie/openingen", url: "" },
    ],
  });
  const [uploading, setUploading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileUpload = (e, photoName) => {
    const file = e.target.files[0];
    if (!file) return;

    setUploading(true);
    const storageRef = ref(storage, `schouw-photos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        console.error("Upload failed:", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setFormData((prev) => ({
          ...prev,
          photos: prev.photos.map((photo) =>
            photo.name === photoName ? { ...photo, url: downloadURL } : photo
          ),
        }));
        setUploading(false);
      }
    );
  };

  const handleAdditionalImageUpload = (e) => {
    const file = e.target.files[0];
    const trimmedName = newImageName.trim();
    if (!file || !trimmedName) {
      alert("Geef een geldige naam voor de afbeelding.");
      return;
    }

    setUploading(true);
    const storageRef = ref(storage, `schouw-photos/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    uploadTask.on(
      "state_changed",
      null,
      (error) => {
        console.error("Upload failed:", error);
        setUploading(false);
      },
      async () => {
        const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
        setFormData((prev) => ({
          ...prev,
          photos: [...prev.photos, { name: trimmedName, url: downloadURL }],
        }));
        setUploading(false);
        setNewImageName("");
        setShowImageInput(false);
      }
    );
  };

  const removeImage = (index) => {
    setFormData((prev) => ({
      ...prev,
      photos: prev.photos.map((photo, i) =>
        i === index ? { ...photo, url: "" } : photo
      ),
    }));
  };

  const handleNext = () => {
    if (step < 4) setStep(step + 1);
  };

  const handlePrevious = () => {
    if (step > 1) setStep(step - 1);
  };

  const handleSubmit = () => {
    // Ensure mandatory images except Ventilatie/openingen are uploaded
    const mandatoryImages = [
      "Meterkast",
      "Locatie van de batterij",
      "Omvormer locatie",
    ];
    const allMandatoryUploaded = mandatoryImages.every((name) =>
      formData.photos.some((photo) => photo.name === name && photo.url)
    );

    if (!allMandatoryUploaded) {
      alert("Alle verplichte afbeeldingen moeten worden geüpload.");
      return;
    }

    const updatedLead = {
      ...lead,
      schouwDetails: {
        ...formData,
      },
      status: "installatie_inplannen",
    };
    console.log(updatedLead);
    // onSave(updatedLead);
    // onClose();
  };
  // console.log(lead);
  return (
    <Modal isOpen={isOpen} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>Schouw Uitvoeren</ModalHeader>
      <ModalBody>
        <Container>
          {/* Progress Bar */}
          <Progress value={(step / 5) * 100} className="mb-4" />

          {step === 1 && (
            <Row>
              <Col>
                <h4>Klantinformatie</h4>
                <p>
                  <strong>Naam:</strong> {lead?.customerDetails.firstName}{" "}
                  {lead?.customerDetails.lastName}
                  <br />
                  <strong>Adres:</strong> {lead?.customerDetails.straat}{" "}
                  {lead?.customerDetails.huisnummer},{" "}
                  {lead?.customerDetails.plaats}
                </p>
                <p>
                  <strong>Telefoonnummer:</strong> {lead?.customerDetails.phone}
                  <br />
                  <strong>E-mailadres:</strong> {lead?.customerDetails.email}
                </p>
                <h5>Productinformatie</h5>

                <p style={{ fontStyle: "italic", fontWeight: 500 }}>
                  Gekozen thuisbatterij: <br />
                  {lead?.selectedProduct.naam}
                  <br />
                  <a target="_blank" href={lead?.selectedProduct.datasheets[0]}>
                    Download datasheet
                  </a>
                </p>
                <p>Extra producten:</p>

                <Row>
                  {lead?.selectedExtras.length > 0 &&
                    lead.selectedExtras.map((product, index) => {
                      // console.log(product);
                      return (
                        <Col xs={4} key={index}>
                          <div className="product-box">
                            <h3>{product.name}</h3>
                            <br />
                            {product.pdfs.length > 0 && (
                              <a target="_blank" href={product.pdfs[0]}>
                                Download
                              </a>
                            )}
                            <p
                              dangerouslySetInnerHTML={{
                                __html: product.description,
                              }}
                            ></p>
                            {product.images.length > 0 && (
                              <img src={product.images[0]} />
                            )}
                          </div>
                        </Col>
                      );
                    })}
                </Row>
              </Col>
            </Row>
          )}

          {/* {step === 2 && (
            <Form>
              <FormGroup>
                <Label for="schouwDate">Schouwdatum</Label>
                <Input
                  type="date"
                  name="schouwDate"
                  id="schouwDate"
                  value={formData.schouwDate}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="schouwTime">Schouwtijd</Label>
                <Input
                  type="time"
                  name="schouwTime"
                  id="schouwTime"
                  value={formData.schouwTime}
                  onChange={handleChange}
                />
              </FormGroup>
            </Form>
          )} */}

          {step === 2 && (
            <Form>
              <FormGroup>
                <Label for="batteryLocation">Locatie van de batterij</Label>
                <Input
                  type="text"
                  name="batteryLocation"
                  id="batteryLocation"
                  value={formData.batteryLocation}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="inverterLocation">Locatie van de omvormer</Label>
                <Input
                  type="text"
                  name="inverterLocation"
                  id="inverterLocation"
                  value={formData.inverterLocation}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="spaceDetails">Ruimte en ventilatie</Label>
                <Input
                  type="textarea"
                  name="spaceDetails"
                  id="spaceDetails"
                  value={formData.spaceDetails}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="ventilation">Ventilatieopties</Label>
                <Input
                  type="text"
                  name="ventilation"
                  id="ventilation"
                  value={formData.ventilation}
                  onChange={handleChange}
                />
              </FormGroup>
            </Form>
          )}

          {step === 3 && (
            <Form>
              <FormGroup>
                <Label for="obstacles">Mogelijke obstakels</Label>
                <Input
                  type="textarea"
                  name="obstacles"
                  id="obstacles"
                  value={formData.obstacles}
                  onChange={handleChange}
                />
              </FormGroup>
              <FormGroup>
                <Label for="plannedExtensions">Geplande uitbreidingen</Label>
                <Input
                  type="textarea"
                  name="plannedExtensions"
                  id="plannedExtensions"
                  value={formData.plannedExtensions}
                  onChange={handleChange}
                />
              </FormGroup>
            </Form>
          )}

          {step === 4 && (
            <Form>
              <Row>
                {formData.photos.map((photo, index) => (
                  <Col xs={4} key={index}>
                    <FormGroup>
                      <h3 className="image-upload-title">{photo.name}</h3>
                      {!photo.url ? (
                        <Label for={`photo-${index}`} className="image-actions">
                          <span>Upload afbeelding</span>
                          <Input
                            type="file"
                            id={`photo-${index}`}
                            accept="image/*"
                            onChange={(e) => handleFileUpload(e, photo.name)}
                            className="mt-2"
                            hidden
                          />
                        </Label>
                      ) : (
                        <div className="image-actions">
                          <a
                            href={photo.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Bekijk afbeelding
                          </a>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => removeImage(index)}
                            className="ms-2"
                          >
                            Verwijderen
                          </Button>
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                ))}
                {showImageInput && (
                  <Col xs={4}>
                    <FormGroup>
                      <Label for="newImageName">
                        Naam voor nieuwe afbeelding
                      </Label>
                      <Input
                        type="text"
                        id="newImageName"
                        value={newImageName}
                        onChange={(e) => setNewImageName(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label for="additionalPhoto">
                        Upload nieuwe afbeelding
                      </Label>
                      <Input
                        type="file"
                        id="additionalPhoto"
                        accept="image/*"
                        onChange={handleAdditionalImageUpload}
                      />
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <Button
                color="link"
                onClick={() => setShowImageInput(!showImageInput)}
              >
                {showImageInput ? "Annuleren" : "Voeg nieuwe afbeelding toe"}
              </Button>
              <div className="uploaded-photos mt-3">
                <h5>Geüploade afbeeldingen</h5>
                <ul>
                  {formData.photos
                    .filter((photo) => photo.url)
                    .map((photo, index) => (
                      <li key={index}>
                        <a
                          href={photo.url}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {photo.name}
                        </a>
                      </li>
                    ))}
                </ul>
              </div>
              <FormGroup>
                <Label for="accessNotes">Opmerkingen</Label>
                <Input
                  type="textarea"
                  name="accessNotes"
                  id="accessNotes"
                  value={formData.accessNotes}
                  onChange={handleChange}
                />
              </FormGroup>
            </Form>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        {step > 1 && (
          <Button color="secondary" onClick={handlePrevious}>
            Vorige
          </Button>
        )}
        {step < 5 && (
          <Button
            color="primary"
            onClick={handleNext}
            disabled={
              // (step === 2 && !formData.schouwDate) ||
              step === 2 && !formData.batteryLocation
            }
          >
            Volgende
          </Button>
        )}
        {step === 5 && (
          <Button color="primary" onClick={handleSubmit} disabled={uploading}>
            Opslaan
          </Button>
        )}
        <Button color="secondary" onClick={onClose}>
          Annuleren
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SchouwModal;
