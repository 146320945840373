import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Input,
  Card,
  CardBody,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Badge,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebaseConfig";
import "./Faq.css";

const FaqPage = () => {
  const [faqs, setFaqs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [selectedFaq, setSelectedFaq] = useState(null);

  // Fetch FAQs from Firestore
  useEffect(() => {
    const fetchFaqs = async () => {
      try {
        const faqSnap = await getDocs(collection(db, "faqs"));
        const fetchedFaqs = faqSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Extract categories
        const uniqueCategories = [
          ...new Set(fetchedFaqs.map((faq) => faq.category)),
        ].map((category) => ({
          name: category,
          count: fetchedFaqs.filter((faq) => faq.category === category).length,
        }));

        setFaqs(fetchedFaqs);
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqs();
  }, []);

  const filteredFaqs = faqs
    .filter(
      (faq) =>
        (selectedCategory === "All" || faq.category === selectedCategory) &&
        (faq.question.toLowerCase().includes(searchQuery.toLowerCase()) ||
          faq.summary.toLowerCase().includes(searchQuery.toLowerCase()) ||
          faq.answer.toLowerCase().includes(searchQuery.toLowerCase()))
    )
    .sort((a, b) => b.pinned - a.pinned);

  return (
    <Container className="faq-page">
      <Row className="mt-4">
        {/* Sidebar for categories */}
        <Col md={3}>
          <div className="categories-faq">
            <h4>Categorieën</h4>
            <ListGroup>
              <ListGroupItem
                active={selectedCategory === "All"}
                onClick={() => setSelectedCategory("All")}
                action
              >
                Alle ({faqs.length})
              </ListGroupItem>
              {categories.map((category) => (
                <ListGroupItem
                  key={category.name}
                  active={selectedCategory === category.name}
                  onClick={() => setSelectedCategory(category.name)}
                  action
                >
                  {category.name} ({category.count})
                </ListGroupItem>
              ))}
            </ListGroup>
          </div>
        </Col>

        {/* Main content */}
        <Col md={9}>
          <h2>Veelgestelde Vragen</h2>
          <Input
            type="text"
            placeholder="Zoek naar een vraag..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="mt-3 mb-4"
          />

          <Row>
            {filteredFaqs.length > 0 ? (
              filteredFaqs.map((faq) => (
                <Col md={6} className="mb-4" key={faq.id}>
                  <Card
                    className="faq-card"
                    onClick={() => setSelectedFaq(faq)}
                  >
                    <CardBody>
                      <CardTitle tag="h5">
                        {faq.question}{" "}
                        {faq.pinned && <Badge color="info">Gepind</Badge>}
                      </CardTitle>
                      {faq?.answer?.length > 30 ? (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: `${faq?.answer.substring(0, 100)}...`,
                          }}
                        ></p>
                      ) : (
                        <p
                          dangerouslySetInnerHTML={{
                            __html: faq?.answer,
                          }}
                        ></p>
                      )}{" "}
                      <span style={{ color: "#007bff", cursor: "pointer" }}>
                        Lees verder
                      </span>
                    </CardBody>
                  </Card>
                </Col>
              ))
            ) : (
              <Col>
                <p>Geen resultaten gevonden voor "{searchQuery}".</p>
              </Col>
            )}
          </Row>
        </Col>
      </Row>

      {/* Modal for detailed answer */}
      {selectedFaq && (
        <Modal isOpen={true} toggle={() => setSelectedFaq(null)} size="lg">
          <ModalHeader toggle={() => setSelectedFaq(null)}>
            {selectedFaq.question}
          </ModalHeader>
          <ModalBody>
            <div style={{ display: "flex", flexDirection: "row" }}>
              {selectedFaq.image && (
                <img
                  src={selectedFaq.image}
                  alt={selectedFaq.question}
                  className="img-fluid mr-3"
                  style={{
                    width: "150px",
                    height: "auto",
                    marginRight: "20px",
                    borderRadius: "5px",
                  }}
                />
              )}
              <div
                dangerouslySetInnerHTML={{ __html: selectedFaq.answer }}
                style={{ flex: 1 }}
              ></div>
            </div>
            {selectedFaq.youtube && (
              <div className="mt-4">
                <iframe
                  width="100%"
                  height="315"
                  src={selectedFaq.youtube}
                  title="YouTube video"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            )}
          </ModalBody>
        </Modal>
      )}
    </Container>
  );
};

export default FaqPage;
