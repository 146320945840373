import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../../firebaseConfig";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import BackButton from "../../components/BackButton";

const SinglePackagePage = () => {
  const { id } = useParams();
  const [packageData, setPackageData] = useState(null);
  const [productDetails, setProductDetails] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch package details
  useEffect(() => {
    const fetchPackage = async () => {
      try {
        const packageRef = doc(db, "packages", id);
        const packageSnap = await getDoc(packageRef);

        if (packageSnap.exists()) {
          const packageInfo = packageSnap.data();
          setPackageData(packageInfo);

          // Fetch product details for dependencies
          if (packageInfo.producten?.length > 0) {
            const productDetails = await fetchProducts(packageInfo.producten);
            setProductDetails(productDetails);
          }
        } else {
          console.error("No such package!");
        }
      } catch (error) {
        console.error("Error fetching package:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchPackage();
  }, [id]);

  // Helper function to fetch product details
  const fetchProducts = async (products) => {
    const results = [];
    for (const product of products) {
      try {
        const productRef = doc(db, "productsNew", product.productId);
        const productSnap = await getDoc(productRef);

        if (productSnap.exists()) {
          results.push({
            id: product.productId,
            ...productSnap.data(),
            type: product.type,
          });
        } else {
          console.warn(`Product with ID ${product.productId} not found.`);
          results.push({
            id: product.productId,
            naam: product.naam,
            type: product.type,
          });
        }
      } catch (error) {
        console.error(
          `Error fetching product with ID ${product.productId}:`,
          error
        );
      }
    }
    return results;
  };

  if (loading) return <div>Laden...</div>;
  if (!packageData)
    return (
      <div>
        <BackButton />
        <br />
        <br />
        Pakket niet gevonden.
      </div>
    );

  return (
    <Container>
      <BackButton />
      <Row className="mt-4">
        <Col md={6}>
          <Card>
            <CardBody>
              <h3>{packageData.naam || "Naam onbekend"}</h3>
              <p>
                <strong>Prijs:</strong> €
                {packageData.prijs?.toLocaleString() || "Onbekend"}
              </p>
              <p>
                <strong>Status:</strong>{" "}
                {packageData.active ? "Actief" : "Inactief"}
              </p>
              {packageData.datasheets?.length > 0 && (
                <>
                  <h5>
                    <strong>Datasheets:</strong>
                  </h5>
                  <ul>
                    {packageData.datasheets.map((datasheet, index) => (
                      <li key={index}>
                        <a
                          href={datasheet}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Datasheet {index + 1}
                        </a>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              <Button
                color="warning"
                onClick={() =>
                  (window.location.href = `/admin/pakketten/edit/${id}`)
                }
              >
                Bewerken
              </Button>
            </CardBody>
          </Card>
        </Col>
        <Col md={6}>
          {packageData.images?.length > 0 ? (
            <img
              src={packageData.images[0]}
              alt={packageData.naam || "Pakket"}
              style={{
                width: "100%",
                height: "400px",
                objectFit: "contain",
                backgroundColor: "#ffffff",
                borderRadius: "8px",
              }}
            />
          ) : (
            <div
              style={{
                width: "100%",
                height: "400px",
                backgroundColor: "#f0f0f0",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: "8px",
              }}
            >
              Geen afbeelding beschikbaar
            </div>
          )}
        </Col>
        <Col xs={12}>
          <Card className="mt-4">
            <CardBody>
              <h5>
                <strong>Beschrijving:</strong>
              </h5>
              <p
                dangerouslySetInnerHTML={{
                  __html:
                    packageData.beschrijving || "Geen beschrijving beschikbaar",
                }}
              ></p>
            </CardBody>
          </Card>
        </Col>
        <Col xs={12}>
          <Card className="mt-4">
            <CardBody>
              <h5>
                <strong>Optionele Producten:</strong>
              </h5>
              {productDetails.length > 0 ? (
                <ListGroup>
                  {productDetails.map((product, index) => (
                    <ListGroupItem
                      key={index}
                      className="d-flex justify-content-between align-items-center"
                    >
                      <div>
                        <strong>{product.name || "Naam onbekend"}</strong>{" "}
                        <small>({product.type || "Type onbekend"})</small>
                      </div>
                      {product.price && (
                        <div>
                          <strong>Prijs:</strong> €
                          {product.price.toLocaleString() || "Onbekend"}
                        </div>
                      )}
                    </ListGroupItem>
                  ))}
                </ListGroup>
              ) : (
                <p>Geen producten beschikbaar</p>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default SinglePackagePage;
