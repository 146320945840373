import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig";
import { Button } from "reactstrap";
import { FaLink } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";

const OfferteWidget = ({ title, offertes = [] }) => {
  const navigate = useNavigate();
  const { getAgentName } = useUser();
  return (
    <div className="widget offerte-widget">
      <div className="widget-header">
        <h3>{title}</h3>
        <Button
          color="link"
          className="link-button"
          onClick={() => (window.location.href = "/admin/offertes")}
        >
          <FaLink /> Alle Offertes
        </Button>
      </div>
      <div className="widget-content">
        <table
          className="offerte-table"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Status
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Klant / Pakket
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Agent
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Datum
              </th>
            </tr>
          </thead>
          <tbody>
            {offertes.length > 0 ? (
              offertes.slice(0, 5).map((offerte) => (
                <tr
                  key={offerte.id}
                  style={{ borderBottom: "1px solid #ddd" }}
                  onClick={() => navigate(`/admin/offertes/${offerte.id}`)}
                >
                  <td style={{ padding: "8px" }}>
                    {offerte.status || "Onbekend"}
                  </td>
                  <td style={{ padding: "8px" }}>
                    <strong>
                      {offerte.customerDetails?.firstName || "Onbekend"}{" "}
                      {offerte.customerDetails?.lastName || "Onbekend"}
                    </strong>
                    <br />
                    <small>
                      {offerte.selectedProduct?.naam || "Geen pakket"}
                    </small>
                  </td>
                  <td style={{ padding: "8px" }}>
                    {getAgentName(offerte?.agent)}
                  </td>
                  <td style={{ padding: "8px" }}>
                    {offerte.createdAt?.toDate().toLocaleDateString("nl-NL") ||
                      "Onbekend"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" style={{ textAlign: "center", padding: "8px" }}>
                  Geen offertes beschikbaar
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default OfferteWidget;
