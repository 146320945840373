import axios from "axios";

/**
 * Fetches image details from the server and returns Base64 string, width, and height.
 * @param {string} imageUrl - The URL of the image.
 * @returns {Promise<object>} - A Promise resolving to an object containing Base64 image, width, and height.
 */
// export const imageUrlToBase64 = async (imageUrl) => {
//   try {
//     const proxyUrl = `${
//       process.env.REACT_APP_API_URL
//     }/proxy?url=${encodeURIComponent(imageUrl)}`;
//     const response = await axios.get(proxyUrl);
//     const { base64Image, width, height } = response.data;
//     // console.log(response.data);
//     return { base64Image, width, height };
//   } catch (error) {
//     console.error("Error converting image to Base64:", error.message);
//     throw error;
//   }
// };

export const imageUrlToBase64 = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve({ base64Image: reader.result });
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};
