import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Label,
  Input,
} from "reactstrap";

const CancelModal = ({ isOpen, toggle, onCancel, lead }) => {
  //   console.log(lead);
  const [cancelReason, setCancelReason] = useState("");
  const [cancelDescription, setCancelDescription] = useState("");
  const minChars = 1;
  const handleConfirmCancel = () => {
    if (!cancelReason || cancelDescription.length < minChars) return; // Basic validation
    onCancel({
      reason: cancelReason,
      description: cancelDescription,
      lead: lead,
    });
    setCancelReason("");
    setCancelDescription("");
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Offerte Annuleren</ModalHeader>
      <ModalBody>
        <p>
          Weet u zeker dat u deze offerte wilt annuleren? Selecteer een reden en
          voeg eventueel een toelichting toe.
        </p>
        <Label for="reasonSelect">Reden voor annulering:</Label>
        <Input
          type="select"
          id="reasonSelect"
          value={cancelReason}
          onChange={(e) => setCancelReason(e.target.value)}
        >
          <option value="">Selecteer een reden</option>
          <option value="Te duur">Te duur</option>
          <option value="Niet meer geïnteresseerd">
            Niet meer geïnteresseerd
          </option>
          <option value="Klant heeft een andere oplossing gekozen">
            Klant heeft een andere oplossing gekozen
          </option>
          <option value="Technische problemen">Technische problemen</option>
          <option value="Overig">Overig</option>
        </Input>

        <Label for="additionalDescription" className="mt-3">
          Toelichting:
        </Label>
        <Input
          type="textarea"
          id="additionalDescription"
          value={cancelDescription}
          onChange={(e) => setCancelDescription(e.target.value)}
          placeholder="Vul hier een toelichting in..."
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color="danger"
          onClick={handleConfirmCancel}
          disabled={!cancelReason || cancelDescription.length < minChars} // Disable button if no reason is selected
        >
          Bevestig Annulering
        </Button>
        <Button color="secondary" onClick={toggle}>
          Annuleren
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CancelModal;
