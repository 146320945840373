import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  deleteDoc,
  doc,
  updateDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  FormGroup,
  Input,
  Label,
  Table,
  Badge,
} from "reactstrap";

const AdminMediaPage = () => {
  const [mediaFiles, setMediaFiles] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editingMedia, setEditingMedia] = useState(null);
  const [userGroups, setUserGroups] = useState([]);
  const [newMedia, setNewMedia] = useState({
    title: "",
    category: "",
    fileUrl: "",
    description: "",
    userGroups: [],
  });
  const [loading, setLoading] = useState(false);

  const fetchMediaFiles = async () => {
    try {
      setLoading(true);
      const mediaRef = collection(db, "media");
      const querySnapshot = await getDocs(mediaRef);

      const fetchedMedia = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        userGroups: [], // Initialize userGroups if missing
        ...doc.data(),
      }));
      setMediaFiles(fetchedMedia);
    } catch (error) {
      console.error("Error fetching media files:", error);
    } finally {
      setLoading(false);
    }
  };

  const fetchUserGroups = async () => {
    try {
      const groupsRef = collection(db, "groups");
      const querySnapshot = await getDocs(groupsRef);
      const fetchedGroups = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setUserGroups(fetchedGroups);
    } catch (error) {
      console.error("Error fetching user groups:", error);
    }
  };

  const saveMediaFile = async () => {
    try {
      const mediaData = {
        ...newMedia,
        userGroups: newMedia.userGroups.map((group) => group.id),
      };

      if (editingMedia) {
        await updateDoc(doc(db, "media", editingMedia.id), mediaData);
      } else {
        await addDoc(collection(db, "media"), mediaData);
      }

      setNewMedia({
        title: "",
        category: "",
        fileUrl: "",
        description: "",
        userGroups: [],
      });
      setEditingMedia(null);
      setModalOpen(false);
      fetchMediaFiles();
    } catch (error) {
      console.error("Error saving media file:", error);
    }
  };

  const deleteMediaFile = async (id) => {
    try {
      await deleteDoc(doc(db, "media", id));
      fetchMediaFiles();
    } catch (error) {
      console.error("Error deleting media file:", error);
    }
  };

  const openEditModal = (media) => {
    setNewMedia({
      ...media,
      userGroups: userGroups.filter((group) =>
        (media.userGroups || []).includes(group.id)
      ), // Handle undefined userGroups
    });
    setEditingMedia(media);
    setModalOpen(true);
  };

  useEffect(() => {
    fetchMediaFiles();
    fetchUserGroups();
  }, []);

  return (
    <div className=" admin-media-page">
      <h2>Media Beheer</h2>
      <Button color="primary" onClick={() => setModalOpen(true)}>
        Bestand Toevoegen
      </Button>
      <Table striped className="mt-3">
        <thead>
          <tr>
            <th>Titel</th>
            <th>Categorie</th>
            <th>Beschrijving</th>
            <th>Toegangsgroepen</th>
            <th>Acties</th>
          </tr>
        </thead>
        <tbody>
          {loading ? (
            <tr>
              <td colSpan="5">Bestanden laden...</td>
            </tr>
          ) : mediaFiles.length > 0 ? (
            mediaFiles.map((file) => (
              <tr key={file.id}>
                <td>{file.title}</td>
                <td>{file.category}</td>
                <td>{file.description}</td>
                <td>
                  {(file.userGroups || []).map((groupId) => (
                    <Badge key={groupId} color="secondary" className="me-1">
                      {userGroups.find((group) => group.id === groupId)?.name ||
                        "Onbekend"}
                    </Badge>
                  ))}
                </td>
                <td className="actions">
                  <a
                    href={file.fileUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-info btn-sm"
                  >
                    Download
                  </a>
                  <Button
                    color="warning"
                    size="sm"
                    className="ms-2"
                    onClick={() => openEditModal(file)}
                  >
                    Bewerken
                  </Button>
                  <Button
                    color="danger"
                    size="sm"
                    className="ms-2"
                    onClick={() => deleteMediaFile(file.id)}
                  >
                    Verwijder
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="5">Geen bestanden beschikbaar</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          {editingMedia ? "Media Bewerken" : "Bestand Toevoegen"}
        </ModalHeader>
        <ModalBody>
          <Form>
            <FormGroup>
              <Label for="title">Titel</Label>
              <Input
                type="text"
                id="title"
                value={newMedia.title}
                onChange={(e) =>
                  setNewMedia({ ...newMedia, title: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="category">Categorie</Label>
              <Input
                type="select"
                id="category"
                value={newMedia.category}
                onChange={(e) =>
                  setNewMedia({ ...newMedia, category: e.target.value })
                }
              >
                <option value="">Selecteer een categorie</option>
                <option value="klant">Klant</option>
                <option value="verkoper">Verkoper</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label for="fileUrl">Bestandslink</Label>
              <Input
                type="url"
                id="fileUrl"
                value={newMedia.fileUrl}
                onChange={(e) =>
                  setNewMedia({ ...newMedia, fileUrl: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label for="description">Beschrijving</Label>
              <Input
                type="textarea"
                id="description"
                value={newMedia.description}
                onChange={(e) =>
                  setNewMedia({ ...newMedia, description: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label>Toegangsgroepen</Label>
              <Input
                type="select"
                multiple
                value={newMedia.userGroups.map((group) => group.id)}
                onChange={(e) => {
                  const selectedOptions = Array.from(
                    e.target.selectedOptions
                  ).map((option) =>
                    userGroups.find((g) => g.id === option.value)
                  );
                  setNewMedia({ ...newMedia, userGroups: selectedOptions });
                }}
              >
                {userGroups.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={saveMediaFile}
            disabled={
              !newMedia.title ||
              !newMedia.fileUrl ||
              !newMedia.category ||
              newMedia.userGroups.length === 0
            }
          >
            Opslaan
          </Button>{" "}
          <Button color="secondary" onClick={() => setModalOpen(false)}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdminMediaPage;
