import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { db, fetchLeads, fetchOffertes } from "../firebaseConfig";
import { useUser } from "../context/UserContext";
import { toastHelper } from "../utils/helpers";

const TaskModal = ({
  isOpen,
  toggle,
  agents,
  lead,
  initialData = {},
  refreshTasks,
  isLead = false,
}) => {
  const [leads, setLeads] = useState([]);
  const [offertes, setOffertes] = useState([]);
  const [task, setTask] = useState({
    type: "standaard",
    title: "",
    agent: "",
    startTime: null,
    endTime: null,
    lead: "",
    realLead: "",

    description: "",
    comments: "",
    ...initialData,
  });
  const { user } = useUser();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await fetchLeads(user);
        const offertes = await fetchOffertes(user);
        setLeads(res);
        setOffertes(offertes);
      } catch (error) {
        console.error("Error fetching leads:", error);
      }
    };

    fetchData();
  }, []);

  const updateLeadStatus = async (leadId, status, task) => {
    console.log(leadId, status, task);
    try {
      const leadRef = doc(db, "leads", leadId);
      await updateDoc(leadRef, {
        status,
        appointment: task.startTime,
        appointmentType: task.type,
      });
      // toastHelper.success("Status succesvol bijgewerkt.");
      // fetchLeads(); // Refresh leads after status update
    } catch (error) {
      console.error("Error updating lead status:", error);
      toastHelper.error(
        "Er is een fout opgetreden bij het bijwerken van de status."
      );
    }
  };

  const handleSave = async () => {
    // console.log(task);
    if (!task.agent) {
      task.agent = user.uid;
    }
    if (!task.title || !task.agent || !task.startTime) {
      alert("Alle velden zijn verplicht.");
      return;
    }
    if (!task.lead && !task.realLead) {
      alert("Een lead of offerte is verplicht.");
      return;
    }
    try {
      console.log(task);
      if (task.id) {
        // Update existing task
        const taskRef = doc(db, "tasks", task.id);
        await updateDoc(taskRef, task);
      } else {
        // Create new task
        const taskRef = collection(db, "tasks");
        await addDoc(taskRef, { ...task, createdAt: new Date() });
      }
      if (isLead) {
        updateLeadStatus(task.realLead, "appointment", task);
      }
      toastHelper.success("Taak succesvol opgeslagen.");
      setTask({
        type: "standaard",
        title: "",
        agent: "",
        startTime: null,
        endTime: null,
        lead: "",
        realLead: "",
        description: "",
        comments: "",
        ...initialData,
      });
      toggle();
    } catch (error) {
      console.error("Error saving task:", error);
      alert("Er is een fout opgetreden bij het opslaan van de taak.");
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>Taak Beheren</ModalHeader>
      <ModalBody>
        <Label>Type</Label>
        <Input
          type="select"
          value={task.type}
          onChange={(e) => setTask({ ...task, type: e.target.value })}
        >
          <option value="standaard">Standaard</option>
          <option value="fysieke afspraak">Fysieke afspraak</option>
          <option value="bel afspraak">Bel afspraak</option>
          <option value="schouw">Schouw inplannen</option>
        </Input>
        <Label className="mt-2">Titel</Label>
        <Input
          type="text"
          value={task.title}
          onChange={(e) => setTask({ ...task, title: e.target.value })}
          placeholder="Voer taak titel in..."
        />
        <Label className="mt-2">Toewijzen aan</Label>

        <Input
          type="select"
          value={user.uid}
          onChange={(e) => setTask({ ...task, agent: e.target.value })}
          disabled={user.role !== "admin"}
        >
          <option value="">Selecteer agent...</option>
          {agents.map((agent) => {
            return (
              <option key={agent.id} value={agent.id}>
                {agent.personalInfo?.firstName || "Onbekend"}
              </option>
            );
          })}
        </Input>

        <Row>
          <Col>
            <Label className="mt-2">Start tijd</Label>
            <DatePicker
              selected={task.startTime}
              onChange={(date) => setTask({ ...task, startTime: date })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd-MM-yyyy HH:mm"
              placeholderText="Starttijd selecteren"
              className="form-control"
            />
          </Col>
          <Col>
            <Label className="mt-2">Eind tijd</Label>
            <DatePicker
              selected={task.endTime}
              onChange={(date) => setTask({ ...task, endTime: date })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd-MM-yyyy HH:mm"
              placeholderText="Eindtijd selecteren"
              className="form-control"
            />
          </Col>
        </Row>
        <div className="lead-selection">
          <h5 className="mt-2">Voeg een lead of offerte toe</h5>
          {lead && (
            <>
              <Input
                value={`${lead.customerDetails.firstName} ${lead.customerDetails.lastName} | ${lead.customerDetails.straat} ${lead.customerDetails.huisnummer} ${lead.customerDetails.plaats}`}
                disabled
              ></Input>
            </>
          )}
          {!lead && (
            <Row>
              <Col>
                <Label className="mt-2">Lead</Label>
                <Input
                  type="select"
                  value={task.realLead}
                  onChange={(e) =>
                    setTask({ ...task, realLead: e.target.value })
                  }
                >
                  <option>Maak een keuze</option>

                  {leads.map((lead) => (
                    <option key={lead.id} value={lead.id}>
                      {lead.customerDetails.firstName}{" "}
                      {lead.customerDetails.lastName} |{" "}
                      {lead.customerDetails.straat}{" "}
                      {lead.customerDetails.huisnummer}{" "}
                      {lead.customerDetails.plaats}
                    </option>
                  ))}
                </Input>
              </Col>

              <Col>
                <Label className="mt-2">Offerte</Label>
                <Input
                  type="select"
                  value={task.lead}
                  onChange={(e) => setTask({ ...task, lead: e.target.value })}
                >
                  <option>Maak een keuze</option>
                  {offertes.map((offerte) => (
                    <option key={offerte.id} value={offerte.id}>
                      {offerte.customerDetails?.firstName}{" "}
                      {offerte.customerDetails.lastName} |{" "}
                      {offerte.customerDetails.straat}{" "}
                      {offerte.customerDetails.huisnummer}{" "}
                      {offerte.customerDetails.plaats}
                    </option>
                  ))}
                </Input>
              </Col>
            </Row>
          )}
        </div>
        <Label className="mt-2">Omschrijving</Label>
        <Input
          type="textarea"
          value={task.description}
          onChange={(e) => setTask({ ...task, description: e.target.value })}
          placeholder="Geef een omschrijving van de taak..."
        />
        <Label className="mt-2">Opmerkingen</Label>
        <Input
          type="textarea"
          value={task.comments}
          onChange={(e) => setTask({ ...task, comments: e.target.value })}
          placeholder="Voeg opmerkingen toe..."
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSave}>
          Opslaan
        </Button>
        <Button color="secondary" onClick={toggle}>
          Annuleren
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TaskModal;
