import React, { useState, useEffect, useCallback } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { useDashboardData } from "../context/DataContext";
const ExtraProductsStep = ({ selectedProduct, onProceed, selectedExtras }) => {
  const {
    data: { products },
  } = useDashboardData(); // Access products from the DataProvider
  const [loading, setLoading] = useState(true);
  const [groupedProducts, setGroupedProducts] = useState({});
  const [manualProduct, setManualProduct] = useState({
    name: "",
    description: "",
    price: "",
    inputType: "single", // Used for input type: "single" or "quantity"
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  useEffect(() => {
    console.log("proccessing changes");
    const processProducts = () => {
      // Predefined categories in lowercase
      const categories = {
        "hybride omvormers": [],
        energiebeheer: [], // Combine "Energiebeheer" and "EMS"
        meerwerk: [],
        overig: [], // Default category for unlisted products
      };

      if (selectedProduct?.producten?.length > 0 && products.length > 0) {
        selectedProduct.producten.forEach((pkgProduct) => {
          const fullProduct = products.find(
            (product) => product.id === pkgProduct.productId
          );

          if (fullProduct) {
            const category = (fullProduct.category || "overig").toLowerCase();
            const normalizedCategory =
              category.includes("energiebeheer") || category.includes("ems")
                ? "energiebeheer"
                : categories[category]
                ? category
                : "overig";
            const isRequired = pkgProduct.type === "required";
            categories[normalizedCategory].push({
              ...fullProduct,
              inputStyle: pkgProduct.inputType || "single", // e.g., "single" or "quantity"
              selected:
                isRequired ||
                selectedExtras.some(
                  (extra) => extra.id === pkgProduct.productId
                ),
              quantity:
                selectedExtras.find(
                  (extra) => extra.id === pkgProduct.productId
                )?.quantity || 0,
              isRequired, // New field for required/optional
            });
          }
        });
      }

      const orderedCategories = Object.keys(categories)
        .filter((category) => categories[category].length > 0)
        .reduce((ordered, category) => {
          ordered[category] = categories[category];
          return ordered;
        }, {});

      setGroupedProducts(orderedCategories);
      setLoading(false);
    };

    processProducts();
  }, [selectedProduct, selectedExtras, products]);

  const toggleSelect = (productId, category) => {
    console.log("toggleSelect");
    setGroupedProducts((prev) => ({
      ...prev,
      [category]: prev[category].map((product) =>
        product.id === productId
          ? { ...product, selected: !product.selected }
          : product
      ),
    }));
  };

  const updateQuantity = (productId, category, increment) => {
    setGroupedProducts((prev) => ({
      ...prev,
      [category]: prev[category].map((product) =>
        product.id === productId
          ? {
              ...product,
              quantity: Math.max((product.quantity || 0) + increment, 0),
            }
          : product
      ),
    }));
  };

  const calculateTotal = useCallback(() => {
    const allProducts = Object.values(groupedProducts).flat();
    console.log(allProducts);
    const basePrice = Number(selectedProduct?.prijs) || 0;

    const newAll = allProducts.filter(
      (product) => product.selected || product.quantity > 0
    );

    console.log(newAll);

    return newAll.reduce((total, product) => {
      const extraCost =
        product.inputType === "quantity"
          ? Number(product.price) * Number(product.quantity)
          : product.selected
          ? Number(product.price)
          : 0;
      return total + extraCost;
    }, basePrice);
  }, [groupedProducts, selectedProduct]);

  const handleAddManualProduct = () => {
    if (!manualProduct.name || !manualProduct.price) {
      alert("Vul alle verplichte velden in.");
      return;
    }

    const category = "overig"; // Manual products are defaulted to "Overig" category

    setGroupedProducts((prev) => ({
      ...prev,
      [category]: [
        ...(prev[category] || []), // Ensure the category exists
        {
          id: `manual-${Date.now()}`, // Generate a unique ID for the manual product
          name: manualProduct.name,
          description: manualProduct.description,
          price: Number(manualProduct.price),
          inputType: manualProduct.inputType,
          selected: manualProduct.inputType === "single", // Default selected for "single"
          quantity: manualProduct.inputType === "quantity" ? 1 : 0,
          isRequired: false, // Manual products are not required by default
        },
      ],
    }));

    // Reset the manual product form
    setManualProduct({
      name: "",
      description: "",
      price: "",
      inputType: "single",
    });
    toggleModal();
  };

  const handleProceed = () => {
    const allProducts = Object.values(groupedProducts).flat();

    const requiredUnselected = allProducts.filter(
      (product) => product.isRequired && !product.selected
    );

    if (requiredUnselected.length > 0) {
      alert(
        "Je moet alle verplichte producten selecteren voordat je doorgaat."
      );
      return;
    }

    const selectedExtras = allProducts.filter(
      (product) => product.selected || product.quantity > 0
    );

    onProceed(selectedExtras);
  };

  const renderProductList = () =>
    Object.entries(groupedProducts).map(([category, products]) => (
      <div key={category} className="product-category">
        <h5 className="mt-4">{category}</h5>
        {products.map((product) => (
          <Row
            key={product.id}
            className="mb-3 align-items-center product-line"
          >
            <Col md={6}>
              <h5>{product.name}</h5>
            </Col>
            <Col md={3}>
              <p>€{product.price?.toLocaleString() || "N/A"}</p>
            </Col>
            <Col md={3} className="text-end">
              {product.inputType === "single" ? (
                <FormGroup check>
                  <Label check>
                    <Input
                      type="checkbox"
                      checked={product.selected}
                      onChange={() => toggleSelect(product.id, category)}
                      disabled={product.isRequired}
                    />
                    {product.isRequired && <span> (Verplicht)</span>}
                  </Label>
                </FormGroup>
              ) : (
                <div className="amount-input d-flex align-items-center justify-content-end">
                  <Button
                    color="secondary"
                    size="sm"
                    onClick={() => updateQuantity(product.id, category, -1)}
                  >
                    -
                  </Button>

                  <span>{product.quantity}</span>

                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => updateQuantity(product.id, category, 1)}
                  >
                    +
                  </Button>
                </div>
              )}
            </Col>
          </Row>
        ))}
      </div>
    ));

  if (loading) return <div>Loading extra products...</div>;
  return (
    <div className="container">
      <Row>
        <Col className="app-container">
          <h2>Extra producten</h2>
          {renderProductList()}
          <Button color="warning" className="mt-4" onClick={toggleModal}>
            Handmatig Product Toevoegen
          </Button>
          <Modal isOpen={isModalOpen} toggle={toggleModal}>
            <ModalHeader toggle={toggleModal}>
              Handmatig Product Toevoegen
            </ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="manualName">Titel</Label>
                <Input
                  type="text"
                  id="manualName"
                  placeholder="Titel"
                  value={manualProduct.name}
                  onChange={(e) =>
                    setManualProduct((prev) => ({
                      ...prev,
                      name: e.target.value,
                    }))
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="manualPrice">Prijs</Label>
                <Input
                  type="number"
                  id="manualPrice"
                  placeholder="Prijs"
                  value={manualProduct.price}
                  onChange={(e) =>
                    setManualProduct((prev) => ({
                      ...prev,
                      price: e.target.value,
                    }))
                  }
                />
              </FormGroup>
              <FormGroup>
                <Label for="manualDescription">Beschrijving</Label>
                <Input
                  type="text"
                  id="manualDescription"
                  placeholder="Beschrijving"
                  value={manualProduct.description}
                  onChange={(e) =>
                    setManualProduct((prev) => ({
                      ...prev,
                      description: e.target.value,
                    }))
                  }
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="success" onClick={handleAddManualProduct}>
                Toevoegen
              </Button>
              <Button color="secondary" onClick={toggleModal}>
                Annuleren
              </Button>
            </ModalFooter>
          </Modal>
        </Col>
        <Col xs="4" className="overview-container">
          <Button
            className="mb-3"
            color="success"
            block={true}
            onClick={handleProceed}
          >
            Volgende
          </Button>
          <Card className="p-4 shadow-sm">
            <CardBody>
              <div className="product-overview">
                <h5>Overzicht</h5>
                <p>
                  <strong>{selectedProduct?.naam}</strong>
                </p>
                <p>
                  <strong>Prijs:</strong> €
                  {selectedProduct?.prijs?.toLocaleString() || "N/A"}
                </p>
                <p>
                  <strong>Uiteindelijke kosten:</strong> €
                  {calculateTotal().toLocaleString()}
                </p>
                <h6>Geselecteerde Extra Opties</h6>
                <ul>
                  {Object.values(groupedProducts)
                    .flat()
                    .filter(
                      (product) => product.selected || product.quantity > 0
                    )
                    .map((product) => (
                      <li key={product.id}>
                        {product.name} - €
                        {product.inputType === "quantity"
                          ? (product.price * product.quantity).toLocaleString()
                          : product.price.toLocaleString()}{" "}
                        {product.inputType === "quantity" &&
                          `(${product.quantity}x)`}
                      </li>
                    ))}
                </ul>
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default ExtraProductsStep;
