import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  doc,
  deleteDoc,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Button, Table } from "reactstrap";
import moment from "moment";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import { useUser } from "../../context/UserContext";
import {
  formatDutchPrice,
  getStatusName,
  toastHelper,
} from "../../utils/helpers";

const AdminOffertesPage = () => {
  const [offertes, setOffertes] = useState([]);
  const [logs, setLogs] = useState([]);
  const [filteredOffertes, setFilteredOffertes] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [loading, setLoading] = useState(true);
  const { getUserById } = useUser();
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    const fetchOffertesAndLogs = async () => {
      try {
        setLoading(true);

        // Fetch Offertes
        const offertesRef = collection(db, "offertes");
        const q = query(offertesRef, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);
        const fetchedOffertes = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Fetch Logs
        const logsRef = collection(db, "logs");
        const logsSnapshot = await getDocs(logsRef);
        const fetchedLogs = logsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Map agent names
        const updatedOffertes = await Promise.all(
          fetchedOffertes.map(async (offerte) => {
            if (offerte.agent) {
              const agent = await getUserById(offerte.agent);
              return {
                ...offerte,
                agentName: agent?.personalInfo?.firstName || "Onbekend",
              };
            }
            return { ...offerte, agentName: "Geen agent" };
          })
        );

        setOffertes(updatedOffertes);
        setFilteredOffertes(updatedOffertes);
        setLogs(fetchedLogs);
      } catch (error) {
        console.error("Error fetching offertes or logs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchOffertesAndLogs();
  }, [getUserById]);

  const isOfferteSeen = (offerteId) => {
    return logs.some(
      (log) =>
        log.offerteId === offerteId &&
        log.message === "Klant heeft offerte bekeken"
    );
  };

  // Apply filters
  useEffect(() => {
    let filtered = offertes;

    if (searchQuery) {
      filtered = filtered.filter(
        (offerte) =>
          offerte.customerDetails?.firstName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          offerte.customerDetails?.lastName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          offerte.customerDetails?.straat
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          offerte.customerDetails?.plaats
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          offerte.agentName
            ?.toLowerCase()
            ?.includes(searchQuery.toLowerCase()) ||
          (offerte.offerteNr &&
            offerte.offerteNr
              .toString()
              .toLowerCase()
              .includes(searchQuery.toLowerCase()))
      );
    }

    if (minPrice) {
      filtered = filtered.filter(
        (offerte) => parseFloat(offerte.finalPrice || 0) >= parseFloat(minPrice)
      );
    }

    if (maxPrice) {
      filtered = filtered.filter(
        (offerte) => parseFloat(offerte.finalPrice || 0) <= parseFloat(maxPrice)
      );
    }

    if (statusFilter) {
      filtered = filtered.filter((offerte) => {
        let status = offerte.status;
        if (offerte.status === "cancelled") {
          status = "declined";
        }
        console.log(status);
        if (status === statusFilter) return true;
      });
    }

    setFilteredOffertes(filtered);
  }, [searchQuery, minPrice, maxPrice, statusFilter, offertes]);

  const moveToArchive = async (offerteId) => {
    try {
      // Find the offerte to archive
      const offerteToArchive = offertes.find(
        (offerte) => offerte.id === offerteId
      );
      if (!offerteToArchive) {
        console.error("Offerte not found.");
        return;
      }

      // Add the offerte to the archivedOffertes collection
      await addDoc(collection(db, "archivedOffertes"), {
        ...offerteToArchive,
        archivedAt: new Date(),
      });

      // Optionally delete it from the current collection
      const offerteRef = doc(db, "offertes", offerteId);
      await deleteDoc(offerteRef);

      // Update the local state
      setOffertes(offertes.filter((offerte) => offerte.id !== offerteId));
      setFilteredOffertes(
        filteredOffertes.filter((offerte) => offerte.id !== offerteId)
      );
      toastHelper.success("Offerte succesvol gearchiveerd!");
      // console.log("Offerte moved to archive successfully.");
    } catch (error) {
      toastHelper.error("Fout bij het archiveren van de offerte.");
      // toastHelper.error("Error moving offerte to archive:", error);
    }
  };

  return (
    <div className="widget offerte-widget">
      <div className="widget-header">
        <h3>Offertes Overzicht</h3>
        <Button
          color="primary"
          onClick={() => (window.location.href = "/admin/offertes/add")}
        >
          Voeg Nieuwe Offerte Toe
        </Button>
      </div>
      <div className="widget-content">
        <div className="filters">
          <input
            type="text"
            placeholder="Zoek op klantnaam of agent..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <input
            type="number"
            placeholder="Min. prijs (€)"
            value={minPrice}
            onChange={(e) => setMinPrice(e.target.value)}
          />
          <input
            type="number"
            placeholder="Max. prijs (€)"
            value={maxPrice}
            onChange={(e) => setMaxPrice(e.target.value)}
          />
          <select
            value={statusFilter}
            onChange={(e) => setStatusFilter(e.target.value)}
          >
            <option value="">Alle statussen</option>
            <option value="delivered">Afgeleverd</option>
            <option value="Verzonden">Verzonden</option>
            <option value="pending">In afwachting</option>
            <option value="accepted">Geaccepteerd</option>
            <option value="declined">Geweigerd</option>
            <option value="concept">Concept</option>
            <option value="bounce">Niet Afgeleverd</option>
          </select>
        </div>

        {loading ? (
          <div>Laden...</div>
        ) : (
          <Table responsive striped>
            <thead>
              <tr>
                <th>Status</th>
                <th>Klant / Pakket</th>

                <th>Agent</th>
                <th>Datum</th>
                <th>Bekeken</th>
                <th>Acties</th>
              </tr>
            </thead>
            <tbody>
              {filteredOffertes.length > 0 ? (
                filteredOffertes.map((offerte) => (
                  <tr
                    key={offerte.id}
                    onClick={() =>
                      (window.location.href = `/admin/offertes/${offerte.id}`)
                    }
                    className="clickable-row"
                  >
                    <td>{getStatusName(offerte.status) || "Onbekend"}</td>
                    <td>
                      <strong>
                        {offerte.customerDetails?.firstName || "Onbekend"}{" "}
                        {offerte.customerDetails?.lastName || "Onbekend"}
                      </strong>
                      <br />
                      <small>
                        {offerte.selectedProduct?.naam || "Geen pakket"}
                        <br />
                        {formatDutchPrice(offerte.selectedProduct?.prijs)} ex.
                        btw
                      </small>
                    </td>
                    <td>{offerte.agentName}</td>
                    <td>
                      {moment(offerte.createdAt?.toDate()).format("DD-MM-YYYY")}
                      <br />

                      {offerte.signedAt && (
                        <span className="sign-date-table">
                          <span>Tekendatum:</span>
                          <span>
                            {moment(offerte.signedAt?.toDate()).format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                        </span>
                      )}
                    </td>
                    <td>
                      {isOfferteSeen(offerte.offerteNr) ? (
                        <FaEye title="Bekeken door klant" color="green" />
                      ) : (
                        <FaRegEyeSlash title="Nog niet bekeken" color="gray" />
                      )}
                    </td>

                    <td>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent row click
                          moveToArchive(offerte.id);
                        }}
                      >
                        Archiveer
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="5" className="no-data">
                    Geen offertes beschikbaar.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        )}
      </div>
    </div>
  );
};

export default AdminOffertesPage;
