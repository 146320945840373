import React, { createContext, useContext, useEffect, useState } from "react";
import {
  collection,
  query,
  where,
  getDocs,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../firebaseConfig";

const DataContext = createContext();

export const useDashboardData = () => useContext(DataContext);

export const DataProvider = ({ children }) => {
  const [data, setData] = useState({
    sent: 0,
    signed: 0,
    cancelled: 0,
    offertes: [],
    products: [],
    packages: [],
  });
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      const offertesRef = collection(db, "offertes");

      const sentQuery = query(
        offertesRef,
        where("status", "in", ["delivered", "Verzonden"])
      );
      const signedQuery = query(offertesRef, where("status", "==", "accepted"));
      const cancelledQuery = query(
        offertesRef,
        where("status", "==", "cancelled")
      );

      const [sentSnap, signedSnap, cancelledSnap] = await Promise.all([
        getDocs(sentQuery),
        getDocs(signedQuery),
        getDocs(cancelledQuery),
      ]);

      const allOffertesSnap = await getDocs(offertesRef);

      const allOffertes = allOffertesSnap.docs
        .map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }))
        .sort((a, b) => {
          // Assuming createdAt is a Firestore Timestamp object
          const aDate = b.createdAt?.seconds || 0;
          const bDate = a.createdAt?.seconds || 0;
          return aDate - bDate; // Ascending order
        });

      const updatedData = {
        sent: sentSnap.size,
        signed: signedSnap.size,
        cancelled: cancelledSnap.size,
        offertes: allOffertes,
      };

      setData((prev) => ({ ...prev, ...updatedData }));
    } catch (error) {
      console.error("Error fetching dashboard data:", error);
    }
  };

  const subscribeToCollections = () => {
    const productsRef = collection(db, "productsNew");
    const packagesRef = collection(db, "packages");

    const unsubscribeProducts = onSnapshot(productsRef, (snapshot) => {
      const products = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData((prev) => ({ ...prev, products }));
    });

    const unsubscribePackages = onSnapshot(packagesRef, (snapshot) => {
      const packages = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData((prev) => ({ ...prev, packages }));
    });

    // Return unsubscribe functions to clean up listeners
    return () => {
      unsubscribeProducts();
      unsubscribePackages();
    };
  };

  useEffect(() => {
    fetchData();

    // Set up real-time listeners for products and packages
    const unsubscribe = subscribeToCollections();

    // Cleanup function
    return () => {
      unsubscribe();
    };
  }, []);

  // console.log(data);
  return (
    <DataContext.Provider value={{ data, loading, refetch: fetchData }}>
      {children}
    </DataContext.Provider>
  );
};
