import React, { act, useEffect, useState } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Card,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { db, fetchFinancingOptions } from "../firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Use "bubble" for a different style
import DiscountModal from "../components/DiscountModal";
import { useUser } from "../context/UserContext";

const AccordionSteps = ({
  selectedProduct,
  onSubmit,
  formData,
  selectedExtras,
  lead,
  isSending,
}) => {
  const { user } = useUser();
  const [activeStep, setActiveStep] = useState(1);
  const [selectedFinancingOption, setSelectedFinancingOption] = useState(null);
  const [financingOptions, setFinancingOption] = useState(null);
  const [agreementChecked, setAgreementChecked] = useState(false);
  const [discounts, setDiscounts] = useState([]);
  const [inleiding, setInleiding] = useState("");

  const [customDiscount, setCustomDiscount] = useState({
    name: "",
    value: 0,
    type: "fixed", // Options: "fixed" or "percentage"
    selected: false,
  });

  const [customerDetails, setCustomerDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  // console.log(lead)

  const [discountModalOpen, setDiscountModalOpen] = useState(false);

  const toggleDiscountModal = () => {
    setDiscountModalOpen((prev) => !prev);
  };

  const handleSaveDiscounts = (updatedDiscounts, updatedCustomDiscount) => {
    setDiscounts(updatedDiscounts);
    setCustomDiscount(updatedCustomDiscount);
  };

  useEffect(() => {
    if (lead) {
      if (!customerDetails.firstName) {
        Object.keys(customerDetails).map((a) => {
          setCustomerDetails((prev) => ({ ...prev, [a]: lead[a] }));
        });
      }
    }
  }, [lead]);

  useEffect(() => {
    const fetchFinance = async () => {
      const options = await fetchFinancingOptions();

      setFinancingOption(options);
    };

    fetchFinance();
  }, []);

  useEffect(() => {
    if (selectedProduct) {
      const initialDiscounts = [
        {
          id: 1,
          name: "Early adopter korting",
          value: 5,
          type: "percentage",
          selected: false,
        },
        {
          id: 2,
          name: "Seizoenskorting",
          value: 200,
          type: "fixed",
          selected: false,
        },
      ];
      setDiscounts(
        selectedProduct.appliedDiscounts
          ? initialDiscounts.map((d) => ({
              ...d,
              selected: selectedProduct.appliedDiscounts.some(
                (ad) => ad.id === d.id
              ),
            }))
          : initialDiscounts
      );
    }
  }, [selectedProduct]);

  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "discounts"));
        const fetchedDiscounts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
          selected: false,
        }));
        setDiscounts(fetchedDiscounts);
      } catch (error) {
        console.error("Error fetching discounts:", error);
      }
    };
    fetchDiscounts();
  }, []);

  useEffect(() => {
    const generateInleiding = () => {
      const defaultInleiding = `Beste ${customerDetails?.firstName} ${customerDetails?.lastName},

Naar aanleiding van uw aanvraag heb ik het genoegen u een persoonlijk aanbod te doen voor een thuisbatterijsysteem. Het voorgestelde systeem, inclusief de ${selectedProduct.naam}, is zorgvuldig samengesteld om perfect aan uw behoeften te voldoen en biedt een optimale combinatie van energieopslag, onafhankelijkheid en duurzaamheid.

Met dit systeem maakt u een bewuste keuze voor meer controle over uw energieverbruik, lagere kosten en een positieve impact op het milieu. In de bijlage vindt u alle details over het systeem, de installatie en de voordelen die u kunt verwachten.

Wij staan uiteraard klaar om eventuele vragen te beantwoorden en u verder te begeleiden in dit traject.

Uw Duurzaamheidsadviseur
${user.personalInfo.firstName} ${user.personalInfo.lastName} 
${user.email}
${user.personalInfo.phoneNumber}
`;
      setInleiding(defaultInleiding);
    };

    generateInleiding();
  }, [selectedProduct, customerDetails]);
  const [errors, setErrors] = useState({});

  const VAT_RATE = 0.21; // 21% VAT rate

  const getPriceIncludingVAT = () => {
    return (selectedProduct.prijs * (1 + VAT_RATE)).toFixed(2);
  };

  const getPriceVAT = () => {
    return (selectedProduct.prijs * VAT_RATE).toFixed(2);
  };

  const getPriceExcludingVAT = () => {
    return (selectedProduct.prijs * 1).toFixed(2);
  };

  const toggleDiscount = (index) => {
    setDiscounts((prev) =>
      prev.map((discount, i) =>
        i === index ? { ...discount, selected: !discount.selected } : discount
      )
    );
  };

  const toggleCustomDiscount = () => {
    setCustomDiscount((prev) => ({ ...prev, selected: !prev.selected }));
  };

  const calculateTotalPrice = () => {
    // Calculate the base price including extras
    const basePrice =
      parseFloat(Number(selectedProduct.prijs) || 0) +
      selectedExtras.reduce((total, extra) => {
        return (
          total +
          (extra.type === "quantity"
            ? Number(extra.price) * (Number(extra.quantity) || 0)
            : Number(extra.price))
        );
      }, 0);

    // Apply discounts
    const discountedPrice = discounts
      .filter((discount) => discount.selected)
      .concat(customDiscount.selected ? customDiscount : [])
      .reduce((total, discount) => {
        if (discount.type === "percentage") {
          return total - (discount.value / 100) * total;
        }
        return total - discount.value;
      }, basePrice);

    // Calculate VAT
    const VAT = discountedPrice * VAT_RATE;
    const totalIncludingVAT = discountedPrice + VAT;

    return {
      basePrice: basePrice.toFixed(2),
      discountedPrice: discountedPrice.toFixed(2),
      VAT: VAT.toFixed(2),
      totalIncludingVAT: totalIncludingVAT.toFixed(2),
    };
  };

  const getPriceDetails = calculateTotalPrice;

  const calculateDiscount = () => {
    const priceIncludingVAT = parseFloat(getPriceExcludingVAT()); // Parse to ensure it's a number
    const appliedDiscounts = discounts.filter((d) => d.selected);
    if (customDiscount.selected) appliedDiscounts.push(customDiscount);

    const discountedPrice = appliedDiscounts.reduce((total, discount) => {
      if (discount.type === "percentage") {
        return total - (discount.value / 100) * total;
      }
      return total - discount.value;
    }, priceIncludingVAT);

    return discountedPrice.toFixed(2);
  };

  const handleCustomDiscountChange = (e) => {
    const { name, value } = e.target;
    setCustomDiscount((prev) => ({
      ...prev,
      [name]: name === "value" ? parseFloat(value) || 0 : value,
    }));
  };

  const handleCustomerChange = (e) => {
    const { name, value } = e.target;
    setCustomerDetails((prev) => ({ ...prev, [name]: value }));
  };

  const validateStep = (step) => {
    const validationErrors = {};
    if (step === 2) {
      // Validate Klantgegevens (Step 2)
      if (!customerDetails.firstName) {
        validationErrors.firstName = "Voornaam is verplicht.";
      }
      if (!customerDetails.lastName) {
        validationErrors.lastName = "Achternaam is verplicht.";
      }
      if (
        !customerDetails.email ||
        !/\S+@\S+\.\S+/.test(customerDetails.email)
      ) {
        validationErrors.email = "Een geldig e-mailadres is verplicht.";
      }
      if (!customerDetails.phone || customerDetails.phone.length < 10) {
        validationErrors.phone = "Een geldig telefoonnummer is verplicht.";
      }
    } else if (step === 4) {
      // Validate Financing Options
      if (!selectedFinancingOption) {
        validationErrors.financingOption =
          "Kies een financieringsmogelijkheid.";
      }
    }
    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleNext = () => {
    if (validateStep(activeStep)) {
      if (activeStep < 5) {
        setActiveStep((prev) => prev + 1);
      } else {
        onSubmit({
          discounts,
          customerDetails,
          finalPrice: selectedProduct.price,
          selectedFinancingOption, // Replace with your final price calculation
        });
      }
    }
  };

  const handlePrevious = () => {
    if (activeStep > 1) {
      setActiveStep((prev) => prev - 1);
    }
  };

  const handleSubmit = (action) => {
    const priceDetails = calculateTotalPrice();
    // Filter applied discounts
    const appliedDiscounts = discounts
      .filter((discount) => discount.selected)
      .concat(customDiscount.selected ? customDiscount : []);
    // adding address to customer details
    customerDetails.straat = formData.straat;
    customerDetails.huisnummer = formData.huisnummer;
    customerDetails.toevoeging = formData.toevoeging;
    customerDetails.postcode = formData.postcode;
    customerDetails.plaats = formData.plaats;
    customerDetails.provincie = formData.provincie;

    // Prepare data for submission
    const finalData = {
      selectedProduct: selectedProduct, // Product details
      customerDetails: customerDetails, // Customer information
      selectedExtras: selectedExtras, // Selected additional products or services
      appliedDiscounts: appliedDiscounts, // All discounts including custom
      finalPrice: priceDetails.discountedPrice,
      priceDetails: priceDetails,
      selectedFinancingOption,
      inleiding,
    };

    // console.log(finalData);
    onSubmit(finalData, action);
  };

  return (
    <Container className="accordion-steps">
      <Row>
        {/* Accordion Steps */}
        <Col md={8}>
          {/* <div className={`accordion-step ${activeStep === 1 ? "active" : ""}`}>
            <h3>1. Kortingen</h3>
            {activeStep === 1 && (
              <Card className="p-4 shadow-sm">
                <h5>Selecteer kortingen</h5>
                {discounts.map((discount, index) => (
                  <Row key={discount.id} className="align-items-center mb-3">
                    <Col md={6}>
                      <h6>{discount.name}</h6>
                    </Col>
                    <Col md={3}>
                      <p>
                        {discount.type === "percentage"
                          ? `-${discount.value}%`
                          : `-€${discount.value}`}
                      </p>
                    </Col>
                    <Col md={3}>
                      <FormGroup check>
                        <Label check>
                          <Input
                            type="checkbox"
                            checked={discount.selected}
                            onChange={() => toggleDiscount(index)}
                          />
                        </Label>
                      </FormGroup>
                    </Col>
                  </Row>
                ))}

                <h5>Voeg een aangepaste korting toe</h5>
                <Row className="align-items-center mb-3">
                  <Col md={4}>
                    <Input
                      type="text"
                      name="name"
                      value={customDiscount.name}
                      onChange={handleCustomDiscountChange}
                      placeholder="Naam van korting"
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      type="number"
                      name="value"
                      value={customDiscount.value}
                      onChange={handleCustomDiscountChange}
                      placeholder="Waarde"
                    />
                  </Col>
                  <Col md={3}>
                    <Input
                      type="select"
                      name="type"
                      value={customDiscount.type}
                      onChange={handleCustomDiscountChange}
                    >
                      <option value="fixed">Vast bedrag (€)</option>
                      <option value="percentage">Percentage (%)</option>
                    </Input>
                  </Col>
                  <Col md={2}>
                    <FormGroup check>
                      <Label check>
                        <Input
                          type="checkbox"
                          checked={customDiscount.selected}
                          onChange={toggleCustomDiscount}
                        />
                      </Label>
                    </FormGroup>
                  </Col>
                </Row>

                <div className="d-flex justify-content-between mt-4">
                  {activeStep > 1 && (
                    <Button color="secondary" onClick={handlePrevious}>
                      Terug
                    </Button>
                  )}
                  {activeStep < 4 && (
                    <Button color="success" onClick={handleNext}>
                      Volgende
                    </Button>
                  )}
                  {activeStep === 4 && (
                    <Button color="success" onClick={handleSubmit}>
                      Verzenden
                    </Button>
                  )}
                </div>
              </Card>
            )}
          </div> */}

          {/* Customer Details Step */}
          <div className={`accordion-step ${activeStep === 1 ? "active" : ""}`}>
            <h3 onClick={() => setActiveStep(1)}>1. Klantgegevens</h3>
            {activeStep === 1 && (
              <Card className="p-4 shadow-sm">
                <h5>Vul klantgegevens in</h5>
                <Row>
                  {/* Name Field */}
                  <Col md={6}>
                    <FormGroup>
                      <Label for="firstName">Voornaam</Label>
                      <Input
                        type="text"
                        name="firstName"
                        value={customerDetails.firstName}
                        onChange={handleCustomerChange}
                        invalid={!!errors.firstName}
                      />
                      {errors.firstName && (
                        <p className="text-danger">{errors.firstName}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label for="lastName">Achternaam</Label>
                      <Input
                        type="text"
                        name="lastName"
                        value={customerDetails.lastName}
                        onChange={handleCustomerChange}
                        invalid={!!errors.lastName}
                      />
                      {errors.name && (
                        <p className="text-danger">{errors.lastName}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Email</Label>
                      <Input
                        type="email"
                        name="email"
                        value={customerDetails.email}
                        onChange={handleCustomerChange}
                        invalid={!!errors.email}
                      />
                      {errors.email && (
                        <p className="text-danger">{errors.email}</p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label>Telefoon</Label>
                      <Input
                        type="text"
                        name="phone"
                        value={customerDetails.phone}
                        onChange={handleCustomerChange}
                        invalid={!!errors.phone}
                      />
                      {errors.phone && (
                        <p className="text-danger">{errors.phone}</p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                {/* Address Section */}
                <Row className="mt-4">
                  <Col md={8}>
                    <h5>Adres</h5>
                    <p>
                      {formData.straat} {formData.huisnummer}{" "}
                      {formData.toevoeging ? formData.toevoeging : ""}
                      <br /> {formData.postcode} {formData.plaats}{" "}
                    </p>
                  </Col>
                </Row>
                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between mt-4">
                  {activeStep > 1 && (
                    <Button color="secondary" onClick={handlePrevious}>
                      Terug
                    </Button>
                  )}
                  {activeStep < 4 && (
                    <Button color="success" onClick={handleNext}>
                      Volgende
                    </Button>
                  )}
                  {activeStep === 4 && (
                    <Button color="primary" onClick={handleSubmit}>
                      Verzenden
                    </Button>
                  )}
                </div>
              </Card>
            )}
          </div>

          {/* Product Step */}
          <div className={`accordion-step ${activeStep === 2 ? "active" : ""}`}>
            <h3 onClick={() => setActiveStep(2)}>2. Productgegevens</h3>
            {activeStep === 2 && (
              <Card className="p-4 shadow-sm">
                <h5>Overzicht van geselecteerde producten</h5>
                <div>
                  <img
                    src={selectedProduct.image}
                    alt={selectedProduct.name}
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                  <h6>{selectedProduct.name}</h6>
                </div>
                <p>
                  <strong>Product:</strong> {selectedProduct.naam}
                </p>
                {selectedExtras.length > 0 && (
                  <p>
                    <ul>
                      {selectedExtras.map((a, i) => {
                        // console.log(a)
                        return (
                          <li key={i}>
                            {a.name}:{" "}
                            {a.type === "quantity" && <>X {a.quantity}</>}
                          </li>
                        );
                      })}
                    </ul>
                  </p>
                )}

                <p className="pricing">
                  <strong>Prijs (excl. BTW):</strong>{" "}
                  <span>€{getPriceDetails().basePrice}</span>
                </p>
                {getPriceDetails().basePrice -
                  getPriceDetails().discountedPrice >
                  0 && (
                  <>
                    <p className="pricing discount">
                      <strong>- Korting:</strong>{" "}
                      <span>
                        - €
                        {(
                          getPriceDetails().basePrice -
                          getPriceDetails().discountedPrice
                        ).toLocaleString()}
                      </span>
                    </p>
                    <p className="pricing">
                      <strong>Totaal na korting : </strong>{" "}
                      <span>
                        €{getPriceDetails().discountedPrice.toLocaleString()}
                      </span>
                    </p>
                  </>
                )}

                <div className="separator price"></div>

                <p className="pricing">
                  <strong>BTW (21%):</strong>{" "}
                  <span>€{getPriceDetails().VAT}</span>
                </p>
                <div className="separator price"></div>

                <p className="pricing total-price">
                  <strong>Totaal (incl. BTW):</strong>{" "}
                  <span>€{getPriceDetails().totalIncludingVAT}</span>
                </p>

                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between mt-4">
                  {activeStep > 1 && (
                    <Button color="secondary" onClick={handlePrevious}>
                      Terug
                    </Button>
                  )}
                  {activeStep < 4 && (
                    <Button color="success" onClick={handleNext}>
                      Volgende
                    </Button>
                  )}
                </div>
              </Card>
            )}
          </div>

          {/* Financing Options Step */}
          <div className={`accordion-step ${activeStep === 3 ? "active" : ""}`}>
            <h3 onClick={() => setActiveStep(3)}>3. Betalingscondities</h3>
            {activeStep === 3 && (
              <Card className="p-4 shadow-sm">
                <h5>Mogelijke betalingscondities</h5>
                <ul className="financing-options">
                  {/* Ensure "100% eigen middelen" is always listed first */}
                  {financingOptions
                    ?.sort((a, b) =>
                      a.name === "100% uit eigen middelen" ? -1 : 1
                    )
                    .map((option) => (
                      <li key={option.id} className="mb-3">
                        <Input
                          type="radio"
                          name="financingOption"
                          id={option.id}
                          checked={selectedFinancingOption?.id === option.id} // Maintain selection
                          onChange={() => {
                            setSelectedFinancingOption(option);
                            setAgreementChecked(false); // Reset agreement checkbox
                          }}
                        />
                        <Label for={option.id}>
                          {option.name} <span>{option.paymentCondition}</span>
                        </Label>
                      </li>
                    ))}
                </ul>

                {/* Display Financing Partners Dropdown */}
                {selectedFinancingOption?.financingParties &&
                  selectedFinancingOption.financingParties.length > 0 && (
                    <FormGroup className="mt-4">
                      <Label for="financingPartner">
                        <strong>Kies een financieringspartner:</strong>
                      </Label>
                      <Input
                        type="select"
                        id="financingPartner"
                        value={selectedFinancingOption.selectedPartner || ""}
                        onChange={(e) =>
                          setSelectedFinancingOption({
                            ...selectedFinancingOption,
                            selectedPartner: e.target.value,
                          })
                        }
                      >
                        <option value="">Selecteer een partner</option>
                        {selectedFinancingOption.financingParties.map(
                          (party, index) => (
                            <option key={index} value={party}>
                              {party}
                            </option>
                          )
                        )}
                      </Input>
                    </FormGroup>
                  )}

                {/* Error message */}
                {errors.financingOption && (
                  <p className="text-danger">{errors.financingOption}</p>
                )}

                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between mt-4">
                  <Button color="secondary" onClick={handlePrevious}>
                    Terug
                  </Button>
                  <Button color="success" onClick={handleNext}>
                    Volgende
                  </Button>
                </div>
              </Card>
            )}
          </div>

          {/* Offerte Step */}
          <div className={`accordion-step ${activeStep === 4 ? "active" : ""}`}>
            <h3 onClick={() => setActiveStep(4)}>4. Offerte</h3>
            {activeStep === 4 && (
              <Card className="p-4 shadow-sm">
                <h5>Bevestig en verstuur offerte</h5>
                <p>
                  Door op "Verzenden" te klikken, bevestigt u de geselecteerde
                  financieringsmogelijkheid en ingevulde gegevens.
                </p>
                <p>
                  <strong>Inleiding offerte</strong>
                </p>

                <Input
                  type="textarea"
                  value={inleiding}
                  onChange={(e) => setInleiding(e.target.value)}
                  placeholder="Schrijf hier een inleiding voor de offerte... Laat leeg voor het standaard bericht"
                  rows="8"
                />

                {/* <ReactQuill
                  value={inleiding}
                  onChange={(content) => setInleiding(content)}
                  // placeholder={}
                  theme="snow"
                /> */}

                {/* Navigation Buttons */}
                <div className="d-flex justify-content-between mt-4">
                  <Button color="secondary" onClick={handlePrevious}>
                    Terug
                  </Button>
                  <div className="submit-buttons">
                    <Button
                      className="mr-4"
                      color="warning"
                      onClick={() => handleSubmit("saveAsConcept")}
                    >
                      Opslaan als concept
                    </Button>
                    <Button
                      color="success"
                      onClick={() => handleSubmit("sendToClient")}
                      disabled={isSending}
                    >
                      Verzenden naar klant
                    </Button>
                  </div>
                </div>
              </Card>
            )}
          </div>
        </Col>

        {/* Overview Sidebar */}
        <Col md={4}>
          <Card className="p-4 shadow-sm">
            <h5>Overzicht</h5>
            <div className="separator divider price"></div>

            <div className="price-data">
              <p className="pricing">
                Prijs (excl. BTW): <span>€{getPriceDetails().basePrice}</span>
              </p>
              {/* {console.log(getPriceDetails())} */}
              {getPriceDetails().basePrice - getPriceDetails().discountedPrice >
                0 && (
                <>
                  <p className="pricing discount">
                    - Korting:{" "}
                    <span>
                      - €
                      {(
                        getPriceDetails().basePrice -
                        getPriceDetails().discountedPrice
                      ).toLocaleString()}
                    </span>
                  </p>
                  <p className="pricing">
                    Prijs na korting (excl. BTW):{" "}
                    <span>
                      €{getPriceDetails().discountedPrice.toLocaleString()}
                    </span>
                  </p>
                </>
              )}

              <div className="separator price"></div>

              <p className="pricing">
                BTW (21%): <span>€{getPriceDetails().VAT}</span>
              </p>
              <div className="separator price"></div>

              <p className="pricing total-price">
                <strong>Totaal (incl. BTW):</strong>{" "}
                <span>€{getPriceDetails().totalIncludingVAT}</span>
              </p>
            </div>
            <div className="separator divider price"></div>
            <div onClick={toggleDiscountModal} className="korting-toepassen">
              Korting toepassen
            </div>
            <div>
              <Row style={{ marginBottom: 40, marginTop: 20 }}>
                <Col>
                  <img
                    src={selectedProduct.images[0]}
                    alt={selectedProduct.naam}
                    style={{ width: "100%", borderRadius: "4px" }}
                  />
                </Col>
                <Col xs={6}>
                  <h5 style={{ margin: "20px 0" }}>{selectedProduct.naam}</h5>
                </Col>
              </Row>
              <p
                dangerouslySetInnerHTML={{
                  __html: selectedProduct.beschrijving,
                }}
              ></p>
              <div className="separator price"></div>

              <h5>Meerwerk</h5>
              {selectedExtras.length > 0 && (
                <p>
                  <ul>
                    {selectedExtras.map((a, i) => {
                      // console.log(a)
                      return (
                        <li key={i}>
                          {a.name}:{" "}
                          {a.type === "quantity" && <>X {a.quantity}</>}
                        </li>
                      );
                    })}
                  </ul>
                </p>
              )}
            </div>
          </Card>
        </Col>
      </Row>
      <DiscountModal
        isOpen={discountModalOpen}
        toggle={toggleDiscountModal}
        discounts={discounts}
        onSave={handleSaveDiscounts}
        customDiscount={customDiscount}
      />
    </Container>
  );
};

export default AccordionSteps;
