import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import {
  collection,
  getDocs,
  getDoc,
  doc,
  query,
  where,
  addDoc,
  updateDoc,
} from "firebase/firestore";
import { db, fetchOfferteById, saveTask } from "../firebaseConfig";
import { useUser } from "../context/UserContext"; // Assume this provides the logged-in user's info
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Container,
  Card,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import "react-big-calendar/lib/css/react-big-calendar.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AgendaPage.css";
import { Link } from "react-router-dom";

const localizer = momentLocalizer(moment);

const AgendaPage = () => {
  const { user } = useUser(); // Get the logged-in user
  const [events, setEvents] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [detailModalOpen, setDetailModalOpen] = useState(false);
  const [newEvent, setNewEvent] = useState({
    title: "",
    start: null,
    end: null,
    type: "Task",
  });
  const [selectedEvent, setSelectedEvent] = useState(null);

  // Fetch tasks and meetings for the logged-in user
  useEffect(() => {
    const fetchTasks = async () => {
      await fetchTasksAndMeetings();
    };

    fetchTasks();
  }, [user]);

  const offerteExists = async (id) => {
    const userDocRef = doc(db, "offertes", id);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      return true;
    } else {
      return false;
    }
  };

  const fetchTasksAndMeetings = async () => {
    try {
      const tasksQuery = query(
        collection(db, "tasks"),
        where("agent", "==", user?.uid) // Filter tasks assigned to the current user
      );
      const meetingsQuery = query(
        collection(db, "meetings"),
        where("attendees", "array-contains", user?.uid) // Filter meetings the user is part of
      );

      const tasksSnap = await getDocs(tasksQuery);
      const meetingsSnap = await getDocs(meetingsQuery);

      const tasks = await Promise.all(
        tasksSnap.docs.map(async (document) => {
          const taskData = document.data();
          let clientData = null;
          let offerteData = null;

          // Fetch related client or offerte

          if (taskData.lead) {
            const offerteDoc = await getDoc(doc(db, "offertes", taskData.lead));

            if (offerteDoc.exists()) {
              clientData = offerteDoc.data();
              clientData.id = offerteDoc.id;
            } else {
              const clientDoc = await getDoc(doc(db, "leads", taskData.lead));
              clientData = clientDoc.exists() ? clientDoc.data() : null;
              taskData.realLead = taskData.lead;
              taskData.lead = "";
            }
          }

          if (!clientData) {
            // const clientDoc = await getDoc(doc(db, "leads", taskData.realLead));
            // clientData = clientDoc.exists() ? clientDoc.data() : null;

            if (taskData.realLead) {
              const clientDoc = await getDoc(
                doc(db, "leads", taskData.realLead)
              );
              clientData = clientDoc.exists() ? clientDoc.data() : null;
            }
          }

          // if (taskData.lead) {
          //   taskData.offerteData = await getOfferteDetails(taskData.lead);
          //   // console.log(offerteData);
          // }
          return {
            id: document.id,
            title: `${taskData.type}: ${taskData.title}`,
            clientInfo: clientData,
            start: taskData.startTime
              ? new Date(taskData.startTime.toDate())
              : null,
            end: taskData.endTime ? new Date(taskData.endTime.toDate()) : null,
            type: "Task",
            // offerteData: offerteData ? offerteData : {},
            description: taskData.description || "Geen beschrijving",
            ...taskData,
          };
        })
      );

      const meetings = meetingsSnap.docs.map((document) => {
        const meetingData = document.data();
        return {
          id: document.id,
          title: `Vergadering: ${meetingData.title}`,
          start: new Date(meetingData.startTime.toDate()),
          end: new Date(meetingData.endTime.toDate()),
          type: "Meeting",
          attendees: meetingData.attendees || [],
          description: meetingData.description || "Geen beschrijving",
        };
      });
      // console.log("Tasks:", tasks); // Debug all tasks

      setEvents([...tasks, ...meetings]);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  const toggleModal = () => setModalOpen(!modalOpen);

  const toggleDetailModal = () => setDetailModalOpen(!detailModalOpen);

  const handleAddEvent = async () => {
    if (!newEvent.title || !newEvent.start) {
      alert("Titel en starttijd zijn verplicht.");
      return;
    }

    try {
      const eventToAdd = { ...newEvent };

      if (newEvent.type === "Task") {
        await addDoc(collection(db, "tasks"), {
          name: newEvent.title,
          startTime: newEvent.start, // Ensure this is properly set
          endTime: newEvent.end, // Optional
          agent: user?.uid,
          description: newEvent.description || "Geen beschrijving",
        });
      } else if (newEvent.type === "Meeting") {
        await addDoc(collection(db, "meetings"), {
          title: newEvent.title,
          startTime: newEvent.start, // Ensure this is properly set
          endTime: newEvent.end, // Required
          attendees: [user?.uid], // Include the current user
        });
      }

      setEvents([...events, { ...eventToAdd }]);
      setNewEvent({ title: "", start: null, end: null, type: "Task" });
      toggleModal();
    } catch (error) {
      console.error("Error adding event:", error);
    }
  };

  const handleSelectEvent = async (event) => {
    if (!event.notes) event.notes = "";
    console.log(event);
    setSelectedEvent(event);
    toggleDetailModal();
  };
  // const getOfferteDetails = async (id) => {
  //   // console.log("getting offerte for", id);
  //   try {
  //     const offerte = await fetchOfferteById(id);
  //     if (offerte) {
  //       // console.log("Fetched Offerte Details:", offerte);
  //       return offerte;
  //     } else {
  //       // console.log("No offerte found.");
  //       return {};
  //     }
  //   } catch (error) {
  //     console.error("Error retrieving offerte details:", error);
  //     return {};
  //   }
  // };
  // console.log(events);
  return (
    <Container>
      <div className="agenda-page">
        <div className="d-flex justify-content-between align-items-center mb-4">
          <h3>Agenda</h3>
          {/* <Button color="primary" onClick={toggleModal}>
            Nieuw Evenement
          </Button> */}
        </div>
        <Card>
          <CardBody>
            <Calendar
              localizer={localizer}
              events={events.map((event) => ({
                ...event,
                end: event.end || event.start, // Fallback to start if end is missing
              }))}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 600 }}
              messages={{
                next: "Volgende",
                previous: "Vorige",
                today: "Vandaag",
                month: "Maand",
                week: "Week",
                day: "Dag",
                agenda: "Agenda",
              }}
              eventPropGetter={(event) => {
                const baseColors = {
                  standaard: "#28a745", // Green for Standaard
                  "fysieke afspraak": "#007bff", // Blue for Fysieke afspraak
                  "bel afspraak": "#fd7e14", // Orange for Bel afspraak
                };

                const baseColor = baseColors[event.type] || "#ffffff"; // Default to white if type is unknown
                const finishedColor = "#2dd55d"; // Grey for finished tasks

                // Blend the base color with grey if the task is finished
                const backgroundColor =
                  event.status === "Afgerond"
                    ? `linear-gradient(to right, ${baseColor} 80%, ${finishedColor} 20%)`
                    : baseColor;

                return {
                  style: {
                    background: backgroundColor,
                    color: "white", // Default text color
                    borderRadius: "4px",
                  },
                };
              }}
              onSelectEvent={handleSelectEvent}
            />
          </CardBody>
        </Card>

        {/* Add Event Modal */}
        <Modal isOpen={modalOpen} toggle={toggleModal}>
          <ModalHeader toggle={toggleModal}>Nieuw Evenement</ModalHeader>
          <ModalBody>
            <Label>Titel</Label>
            <Input
              type="text"
              value={newEvent.title}
              onChange={(e) =>
                setNewEvent({ ...newEvent, title: e.target.value })
              }
              placeholder="Titel van evenement..."
            />
            <Label className="mt-2">Type</Label>
            <Input
              type="select"
              value={newEvent.type}
              onChange={(e) =>
                setNewEvent({ ...newEvent, type: e.target.value })
              }
            >
              <option value="Task">Taak</option>
              <option value="Meeting">Vergadering</option>
            </Input>
            <Label className="mt-2">Starttijd</Label>
            <DatePicker
              selected={newEvent.start}
              onChange={(date) => setNewEvent({ ...newEvent, start: date })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd-MM-yyyy HH:mm"
              placeholderText="Starttijd selecteren"
              className="form-control"
            />
            <Label className="mt-2">Eindtijd</Label>
            <DatePicker
              selected={newEvent.end}
              onChange={(date) => setNewEvent({ ...newEvent, end: date })}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={15}
              dateFormat="dd-MM-yyyy HH:mm"
              placeholderText="Eindtijd selecteren"
              className="form-control"
            />
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={handleAddEvent}>
              Toevoegen
            </Button>
            <Button color="secondary" onClick={toggleModal}>
              Annuleren
            </Button>
          </ModalFooter>
        </Modal>

        {/* Event Detail Modal */}
        <Modal isOpen={detailModalOpen} toggle={toggleDetailModal}>
          <ModalHeader toggle={toggleDetailModal}>
            Taak Details{" "}
            {selectedEvent?.lead && (
              <Link
                to={`/offertes/${selectedEvent?.lead}`}
                className="client-link"
              >
                Bekijk offerte
              </Link>
            )}
            {selectedEvent?.realLead && <span>Lead</span>}
          </ModalHeader>
          <ModalBody>
            {selectedEvent?.clientInfo && (
              <span>
                Klant: {selectedEvent?.clientInfo.customerDetails.firstName}{" "}
                {selectedEvent?.clientInfo.customerDetails.lastName} -{" "}
                {selectedEvent?.clientInfo.customerDetails.straat}{" "}
                {selectedEvent?.clientInfo.customerDetails.huisnummer}{" "}
                {selectedEvent?.clientInfo.customerDetails.toevoeging} -{" "}
                {selectedEvent?.clientInfo.customerDetails.plaats}
              </span>
            )}
            {selectedEvent ? (
              <>
                <Row>
                  <Col>
                    <Label>Type</Label>
                    <Input type="text" value={selectedEvent.type} disabled />
                  </Col>
                  <Col>
                    <Label className="mt-2">Titel</Label>
                    <Input type="text" value={selectedEvent.title} disabled />
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Label className="mt-2">Starttijd</Label>
                    <Input
                      type="text"
                      value={moment(selectedEvent.start).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                      disabled
                    />
                  </Col>
                  <Col>
                    <Label className="mt-2">Eindtijd</Label>
                    <Input
                      type="text"
                      value={
                        selectedEvent.end
                          ? moment(selectedEvent.end).format("DD-MM-YYYY HH:mm")
                          : "Geen eindtijd"
                      }
                      disabled
                    />
                  </Col>
                </Row>

                <Label className="mt-2">Omschrijving</Label>
                <Input
                  type="textarea"
                  value={selectedEvent.description || "Geen beschrijving"}
                  disabled
                />

                <div>
                  <Label className="mt-2">Opmerkingen</Label>
                  <Input
                    type="textarea"
                    value={selectedEvent.notes || ""}
                    onChange={(e) =>
                      setSelectedEvent({
                        ...selectedEvent,
                        notes: e.target.value,
                      })
                    }
                    disabled={selectedEvent.status === "Afgerond"}
                  />
                  {selectedEvent.status !== "Afgerond" && (
                    <small className="text-muted">
                      Minimaal 30 tekens. Aantal tekens:{" "}
                      {selectedEvent.notes?.length || 0}
                    </small>
                  )}
                </div>

                {selectedEvent.status === "Afgerond" && (
                  <div className="mt-3">
                    <Label>Voltooid op</Label>
                    <Input
                      type="text"
                      value={moment(selectedEvent.finishedAt.toDate()).format(
                        "DD-MM-YYYY HH:mm"
                      )}
                      disabled
                    />
                  </div>
                )}
              </>
            ) : (
              <p>Geen taak geselecteerd.</p>
            )}
          </ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={toggleDetailModal}>
              Sluiten
            </Button>
            {selectedEvent?.status !== "Afgerond" && (
              <>
                {/* <Button
                  color="primary"
                  onClick={() => {
                    if (selectedEvent.notes?.length < 30) {
                      alert("Opmerkingen moeten minimaal 30 tekens bevatten.");
                      return;
                    }
                    // Save updated task notes
                    saveTask({ ...selectedEvent })
                      .then(() => {
                        alert("Opmerkingen succesvol opgeslagen.");
                        toggleDetailModal();
                      })
                      .catch((error) => {
                        console.error("Error saving task notes:", error);
                        alert(
                          "Er is een fout opgetreden bij het opslaan van opmerkingen."
                        );
                      });
                  }}
                >
                  Opslaan
                </Button> */}
                <Button
                  color="success"
                  disabled={selectedEvent?.notes?.length < 30}
                  onClick={async () => {
                    if (selectedEvent.notes?.length < 30) {
                      alert("Opmerkingen moeten minimaal 30 tekens bevatten.");
                      return;
                    }

                    const taskRef = doc(db, "tasks", selectedEvent.id);
                    const updatedTask = {
                      status: "Afgerond",
                      notes: selectedEvent.notes, // Save the notes in Firestore
                      finishedAt: new Date(), // Record the completion time
                    };
                    // console.log(updatedTask);
                    await updateDoc(taskRef, updatedTask);
                    await fetchTasksAndMeetings();
                    toggleDetailModal();
                  }}
                >
                  Markeer als voltooid
                </Button>
              </>
            )}
          </ModalFooter>
        </Modal>
      </div>
    </Container>
  );
};

export default AgendaPage;
