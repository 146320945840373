import React, { useState, useEffect } from "react";
import {
  Table,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import CreateGroupModal from "../../components/CreateGroupModal";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebaseConfig";

const AdminGroupsPage = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedGroup, setSelectedGroup] = useState(null);

  const toggleModal = () => setModalOpen(!modalOpen);

  const fetchGroups = async () => {
    try {
      const groupsSnap = await getDocs(collection(db, "groups"));
      const fetchedGroups = groupsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGroups(fetchedGroups);
    } catch (error) {
      console.error("Error fetching groups:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchGroups();
  }, []);

  const handleGroupCreated = () => {
    fetchGroups();
  };

  const editGroup = (group) => {
    console.log(group);
    setSelectedGroup(group);
    toggleModal();
  };

  const closeEditGroup = () => {
    setSelectedGroup(null);
    toggleModal();
  };
  const createNewGroup = () => {
    setSelectedGroup(null);
    toggleModal();
  };
  if (loading) return <div>Loading...</div>;

  return (
    <div className="admin-groups-page">
      <div className="header d-flex justify-content-between align-items-center">
        <h3>Groepen Beheer</h3>
        <Button color="primary" onClick={createNewGroup}>
          Nieuwe Groep Aanmaken
        </Button>
      </div>

      <Table className="groups-table" responsive striped>
        <thead>
          <tr>
            <th>Groepsnaam</th>
            <th>Aantal Admins</th>
            <th>Aantal Gebruikers</th>
            <th>Percentage</th>
            <th>Acties</th>
          </tr>
        </thead>
        <tbody>
          {groups.length > 0 ? (
            groups.map((group) => (
              <tr key={group.id}>
                <td>{group.name}</td>
                <td>{group.groupAdmins?.length || 0}</td>
                <td>{group.users?.length || 0}</td>
                <td>{group.percentage}%</td>
                <td>
                  <Button
                    color="warning"
                    size="sm"
                    onClick={() => editGroup(group)}
                  >
                    Bewerken
                  </Button>
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">
                Geen groepen gevonden.
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      {/* Create Group Modal */}
      <CreateGroupModal
        isOpen={modalOpen}
        toggle={toggleModal}
        toggleEdit={closeEditGroup}
        onGroupCreated={handleGroupCreated}
        onGroupUpdated={handleGroupCreated}
        groupToEdit={selectedGroup}
      />
    </div>
  );
};

export default AdminGroupsPage;
