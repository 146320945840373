import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  setDoc,
  updateDoc,
  doc,
  where,
  query,
  addDoc,
} from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth"; // Firebase Auth methods
import { db, auth } from "../../firebaseConfig";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Row,
  Col,
} from "reactstrap";
import "./Users.css";
import { useUser } from "../../context/UserContext";
import { toastHelper } from "../../utils/helpers";

const AdminUsersPage = () => {
  const { user } = useUser();
  const [groups, setGroups] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalOpen, setModalOpen] = useState(false);
  const [groupModalOpen, setGroupModalOpen] = useState(false);
  const [editUser, setEditUser] = useState(null); // User being edited
  const [newUser, setNewUser] = useState({
    email: "",
    role: "agent",
    status: "unverified",
    groupId: false,
    personalInfo: {
      firstName: "",
      lastName: "",
      infix: "",
      phoneNumber: "",
      address: {
        street: "",
        number: "",
        addon: "",
        city: "",
        postalCode: "",
        province: "",
      },
    },
  });
  const toggleGroupModal = () => setGroupModalOpen(!modalOpen);

  const handleGroupCreated = (newGroup) => {
    setGroups((prevGroups) => [...prevGroups, newGroup]);
  };

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        let usersQuery;
        if (user.role === "admin") {
          // Admin sees all users
          usersQuery = collection(db, "users");
        } else if (user.role === "groupAdmin") {
          // Group admin sees only their group's users
          usersQuery = query(
            collection(db, "users"),
            where("groupId", "==", user.groupId)
          );
        } else {
          throw new Error("Unauthorized access");
        }

        const usersSnap = await getDocs(usersQuery);
        const array = usersSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(array);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    fetchGroups();
    fetchUsers();
  }, []);

  const toggleModal = () => setModalOpen(!modalOpen);

  const handleInputChange = (e, isEdit = false, path = []) => {
    const { name, value } = e.target;
    const targetUser = isEdit ? editUser : newUser;

    const updatedUser = { ...targetUser };
    let current = updatedUser;

    // Safely traverse and initialize nested objects along the path
    path.forEach((key, index) => {
      if (!current[key]) current[key] = {};
      if (index === path.length - 1) {
        current[key] = value;
      } else {
        current = current[key];
      }
    });

    if (path.length === 0) {
      updatedUser[name] = value; // Handle top-level fields
    }

    if (isEdit) {
      setEditUser(updatedUser);
    } else {
      setNewUser(updatedUser);
    }
  };

  const fetchGroups = async () => {
    try {
      const groupsSnap = await getDocs(collection(db, "groups"));
      const array = groupsSnap.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setGroups(array);
    } catch (error) {
      console.error("Error fetching groups:", error);
    }
  };

  const createGroup = async (group) => {
    try {
      const groupRef = await addDoc(collection(db, "groups"), {
        ...group,
        createdAt: new Date(),
      });
      console.log("Group created:", groupRef.id);
    } catch (error) {
      console.error("Error creating group:", error);
    }
  };

  const handleAddUser = async () => {
    if (!newUser.email) {
      return alert("Email is required!");
    }
    const password = `Batterij${newUser.personalInfo.firstName}2024!`;
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        newUser.email,
        newUser.password || password
      );
      const { uid } = userCredential.user;

      const userData = {
        ...newUser,
        uid,
        groupId: user.role === "groupAdmin" ? user.groupId : newUser.groupId, // Assign groupId
        createdAt: new Date(),
      };
      console.log(userData);
      await setDoc(doc(db, "users", uid), userData);
      setUsers([...users, userData]);

      setNewUser({
        email: "",
        role: "agent",
        status: "unverified",
        personalInfo: {
          firstName: "",
          lastName: "",
          infix: "",
          phoneNumber: "",
          address: {
            street: "",
            number: "",
            addon: "",
            city: "",
            postalCode: "",
            province: "",
          },
        },
      });

      await sendWelcomeEmail(userData, password);

      toggleModal();
    } catch (error) {
      console.error("Error adding user:", error);
    }
  };

  const sendWelcomeEmail = async (userData, password) => {
    const emailPayload = {
      to: "info@thuisbatterijexperts.nl",
      subject: "Welcome to ThuisbatterijExperts",
      templateName: "welcome", // This corresponds to the "welcome.html" and "welcome.txt" templates
      placeholders: {
        firstName: userData.personalInfo.firstName,
        lastName: userData.personalInfo.lastName,
        email: userData.email,
        password: password,
      },
      customArgs: {
        client: "", // Replace with your client's ID
        // Replace with your record's unique ID
      },
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/send-email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(emailPayload),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to send email");
      }

      const result = await response.json();
      console.log("Email sent successfully:", result);
      toastHelper.success("Gebruiker succesvol aangemaakt.");
    } catch (error) {
      console.error("Error sending welcome email:", error);
    }
  };

  const handleUpdateUser = async () => {
    try {
      const userDocRef = doc(db, "users", editUser.id);
      await updateDoc(userDocRef, {
        ...editUser,
        updatedAt: new Date(),
      });

      setUsers((prevUsers) =>
        prevUsers.map((user) => (user.id === editUser.id ? editUser : user))
      );

      setEditUser(null);
      toggleModal();
    } catch (error) {
      console.error("Error updating user:", error);
    }
  };

  if (loading) return <div>Loading...</div>;

  console.log(users);
  return (
    <div className="admin-users-page">
      <div className="header">
        <h3>Gebruikersbeheer</h3>
        <Button color="primary" onClick={toggleModal}>
          Nieuwe Gebruiker Toevoegen
        </Button>
      </div>

      <Table className="users-table" responsive striped>
        <thead>
          <tr>
            <th>Email</th>
            <th>Rol</th>
            <th>Regio</th>
            <th>Acties</th>
          </tr>
        </thead>
        <tbody>
          {users.map((user) => (
            <tr key={user.id}>
              <td>{user.email}</td>
              <td>{user.role}</td>
              <td>{user.personalInfo.address.provincie}</td>
              <td>
                <Button
                  color="warning"
                  size="sm"
                  onClick={() => {
                    setEditUser(user);
                    toggleModal();
                  }}
                >
                  Bewerken
                </Button>
                {/* <Button
                  color="warning"
                  size="sm"
                  onClick={() => {
                    sendWelcomeEmail(user, "testwachtwoord");
                    // toggleModal();
                  }}
                >
                  test email
                </Button> */}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {editUser ? "Gebruiker Bewerken" : "Nieuwe Gebruiker Toevoegen"}
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Label className="mt-2">Voornaam</Label>
              <Input
                type="text"
                value={
                  editUser
                    ? editUser.personalInfo?.firstName
                    : newUser.personalInfo.firstName
                }
                onChange={(e) =>
                  handleInputChange(e, !!editUser, [
                    "personalInfo",
                    "firstName",
                  ])
                }
              />
            </Col>
            <Col>
              <Label className="mt-2">Tussenvoegsel</Label>
              <Input
                type="text"
                value={
                  editUser
                    ? editUser.personalInfo?.infix
                    : newUser.personalInfo.infix
                }
                onChange={(e) =>
                  handleInputChange(e, !!editUser, ["personalInfo", "infix"])
                }
              />
            </Col>
            <Col>
              <Label className="mt-2">Achternaam</Label>
              <Input
                type="text"
                value={
                  editUser
                    ? editUser.personalInfo?.lastName
                    : newUser.personalInfo.lastName
                }
                onChange={(e) =>
                  handleInputChange(e, !!editUser, ["personalInfo", "lastName"])
                }
              />
            </Col>
          </Row>

          <Row>
            {!editUser && (
              <Col>
                <Label>Email</Label>
                <Input
                  type="email"
                  name="email"
                  value={editUser ? editUser.email : newUser.email}
                  onChange={(e) => handleInputChange(e, !!editUser)}
                  placeholder="Email invoeren..."
                />
              </Col>
            )}
            <Col>
              <Label>telefoonnummer</Label>
              <Input
                type="text"
                name="phonenumber"
                value={editUser ? editUser.phonenumber : newUser.phonenumber}
                onChange={(e) => handleInputChange(e, !!editUser)}
                placeholder="Telefoonnummer invoeren..."
              />
            </Col>
          </Row>

          <Label className="mt-2">Status</Label>
          <Input
            type="select"
            name="status"
            value={editUser ? editUser.status : newUser.status}
            onChange={(e) => handleInputChange(e, !!editUser)}
          >
            <option value="unverified">Niet Geverifieerd</option>
            <option value="verified">Geverifieerd</option>
          </Input>

          <h5>Adres</h5>
          {/* Additional fields for address and personal info */}
          <Row>
            <Col>
              <Label className="mt-2">Straat</Label>
              <Input
                type="text"
                value={
                  editUser
                    ? editUser.personalInfo?.address?.street
                    : newUser.personalInfo.address.street
                }
                onChange={(e) =>
                  handleInputChange(e, !!editUser, [
                    "personalInfo",
                    "address",
                    "street",
                  ])
                }
              />
            </Col>
            <Col>
              <Label className="mt-2">Huisnummer</Label>
              <Input
                type="text"
                value={
                  editUser
                    ? editUser.personalInfo?.address?.number
                    : newUser.personalInfo.address.number
                }
                onChange={(e) =>
                  handleInputChange(e, !!editUser, [
                    "personalInfo",
                    "address",
                    "number",
                  ])
                }
              />
            </Col>
            <Col>
              <Label className="mt-2">Toev.</Label>
              <Input
                type="text"
                value={
                  editUser
                    ? editUser.personalInfo?.address?.addon
                    : newUser.personalInfo.address.addon
                }
                onChange={(e) =>
                  handleInputChange(e, !!editUser, [
                    "personalInfo",
                    "address",
                    "addon",
                  ])
                }
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Label className="mt-2">Plaats</Label>
              <Input
                type="text"
                value={
                  editUser
                    ? editUser.personalInfo?.address?.city
                    : newUser.personalInfo.address.city
                }
                onChange={(e) =>
                  handleInputChange(e, !!editUser, [
                    "personalInfo",
                    "address",
                    "city",
                  ])
                }
              />
            </Col>
            <Col>
              <Label className="mt-2">Provincie</Label>
              <Input
                type="text"
                value={
                  editUser
                    ? editUser.personalInfo?.address?.provincie
                    : newUser.personalInfo.address.provincie
                }
                onChange={(e) =>
                  handleInputChange(e, !!editUser, [
                    "personalInfo",
                    "address",
                    "provincie",
                  ])
                }
              />
            </Col>
          </Row>

          <Label className="mt-2">Groep</Label>
          <Input
            type="select"
            value={editUser ? editUser.groupId : newUser.groupId}
            onChange={(e) => handleInputChange(e, !!editUser, ["groupId"])}
          >
            <option value="">Selecteer een groep</option>
            {groups.map((group) => (
              <option key={group.id} value={group.id}>
                {group.name}
              </option>
            ))}
          </Input>

          <Label className="mt-2">Rol</Label>
          <Input
            type="select"
            name="role"
            value={editUser ? editUser.role : newUser.role}
            onChange={(e) => handleInputChange(e, !!editUser)}
          >
            <option value="agent">Agent</option>
            <option value="admin">Admin</option>
          </Input>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={editUser ? handleUpdateUser : handleAddUser}
          >
            {editUser ? "Bijwerken" : "Toevoegen"}
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdminUsersPage;
