import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  addDoc,
} from "firebase/firestore";
import { db } from "../firebaseConfig"; // Firebase Firestore configuration
import { useUser } from "../context/UserContext"; // Assume you have a context providing user info
import { Button, Card, CardBody, Container, Table } from "reactstrap";
import "./Offertes.css"; // Optional CSS for styling
import moment from "moment";
import { FaEye, FaRegEyeSlash } from "react-icons/fa";
import { formatDutchPrice, getStatusName } from "../utils/helpers";

const OffertesPage = () => {
  const [offertes, setOffertes] = useState([]);
  const [filteredOffertes, setFilteredOffertes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const { user } = useUser(); // Get current user info from context
  const [searchQuery, setSearchQuery] = useState("");
  const [minPrice, setMinPrice] = useState("");
  const [maxPrice, setMaxPrice] = useState("");
  const [statusFilter, setStatusFilter] = useState("");

  const [showFilterPopup, setShowFilterPopup] = useState(false);
  const [showSortPopup, setShowSortPopup] = useState(false);
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    if (showFilterPopup) {
      setShowSortPopup(false);
    }
  }, [showFilterPopup]);

  useEffect(() => {
    if (showSortPopup) {
      setShowFilterPopup(false);
    }
  }, [showSortPopup]);

  useEffect(() => {
    const fetchOffertes = async () => {
      try {
        let q;
        const offertesRef = collection(db, "offertes");

        q = query(
          offertesRef,
          where("agent", "==", user?.uid),
          orderBy("createdAt", "desc")
        );

        const querySnapshot = await getDocs(q);
        const fetchedOffertes = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setOffertes(fetchedOffertes);
        setFilteredOffertes(fetchedOffertes);
      } catch (err) {
        console.error("Error fetching offertes:", err);
      } finally {
        setLoading(false);
      }
    };

    const fetchLogs = async () => {
      try {
        const logsRef = collection(db, "logs");
        const q = query(logsRef, orderBy("createdAt", "desc"));
        const querySnapshot = await getDocs(q);

        const logsData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLogs(logsData);
      } catch (error) {
        console.error("Error fetching logs:", error);
      }
    };

    fetchOffertes();
    fetchLogs();
  }, [user]);

  const isOfferteSeen = (offerteId) => {
    return logs.some(
      (log) =>
        log.offerteId === offerteId &&
        log.message === "Klant heeft offerte bekeken"
    );
  };
  useEffect(() => {
    let filtered = offertes;

    if (searchQuery) {
      filtered = filtered.filter(
        (offerte) =>
          offerte.customerDetails?.firstName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          offerte.customerDetails?.lastName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          offerte.customerDetails?.straat
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          offerte.customerDetails?.plaats
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          offerte.agentName
            ?.toLowerCase()
            ?.includes(searchQuery.toLowerCase()) ||
          (offerte.offerteNr &&
            offerte.offerteNr
              .toString()
              .toLowerCase()
              .includes(searchQuery.toLowerCase()))
      );
    }

    if (minPrice) {
      filtered = filtered.filter(
        (offerte) => parseFloat(offerte.finalPrice || 0) >= parseFloat(minPrice)
      );
    }

    if (maxPrice) {
      filtered = filtered.filter(
        (offerte) => parseFloat(offerte.finalPrice || 0) <= parseFloat(maxPrice)
      );
    }

    if (statusFilter) {
      filtered = filtered.filter((offerte) => {
        let status = offerte.status;
        if (offerte.status === "Geweigerd") {
          status = "Geannuleerd";
        }
        console.log(status);
        if (status === statusFilter) return true;
      });
    }

    setFilteredOffertes(filtered);
  }, [searchQuery, minPrice, maxPrice, statusFilter, offertes]);

  const handleDuplicateOfferte = async (offerte) => {
    try {
      // Duplicate offerte data
      const newOfferteData = {
        ...offerte,
        customerDetails: {
          ...offerte.customerDetails,
          name: `${offerte.customerDetails?.firstName} (Kopie)`,
        },
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      delete newOfferteData.id; // Remove the original ID

      // Save the duplicated offerte
      const docRef = await addDoc(collection(db, "offertes"), newOfferteData);

      // Redirect to edit/add offerte page with the new offerte ID
      window.location.href = `/offertes/edit/${docRef.id}`;
    } catch (err) {
      console.error("Error duplicating offerte:", err);
      alert("Het dupliceren van de offerte is mislukt.");
    }
  };

  if (loading) return <div>Loading offertes...</div>;
  if (error)
    return (
      <Container>
        <Card>
          <CardBody className={"text-center"}>
            <div className="error mb-5">{error}</div>
            <Button
              style={{ marginLeft: "auto", marginRight: 20 }}
              color="primary"
              onClick={() => (window.location.href = "/offerte")}
            >
              Voeg Nieuwe Offerte Toe
            </Button>
          </CardBody>
        </Card>
      </Container>
    );

  //   console.log(filteredOffertes);
  return (
    <Container className="offertes-page">
      <div className="header-section">
        <h2>Offertes Overzicht</h2>
        <Button
          style={{ marginLeft: "auto", marginRight: 20 }}
          color="primary"
          onClick={() => (window.location.href = "/offerte")}
        >
          Voeg Nieuwe Offerte Toe
        </Button>
        {/* <div className="view-toggle">
                    <Button
                        style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                        color={isTableView ? "secondary" : "primary"}
                        onClick={() => setIsTableView(false)}
                    >
                        <FiGrid style={{ fontSize: 25 }} />
                    </Button>
                    <Button
                        style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                        color={isTableView ? "primary" : "secondary"}
                        onClick={() => setIsTableView(true)}
                    >
                        <FiAlignJustify style={{ fontSize: 25 }} />

                    </Button>
                </div> */}
      </div>

      {/* Filters Section */}
      <div className="filters">
        <input
          className="width-2x"
          type="text"
          placeholder="Zoek op naam, adres of offertenr..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <input
          type="number"
          placeholder="Min. prijs (€)"
          value={minPrice}
          onChange={(e) => setMinPrice(e.target.value)}
        />
        <input
          type="number"
          placeholder="Max. prijs (€)"
          value={maxPrice}
          onChange={(e) => setMaxPrice(e.target.value)}
        />
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
        >
          <option value="">Alle statussen</option>
          <option value="delivered">Afgeleverd</option>
          <option value="Verzonden">Verzonden</option>
          <option value="pending">In afwachting</option>
          <option value="accepted">Geaccepteerd</option>
          <option value="declined">Geweigerd</option>
          <option value="concept">Concept</option>
          <option value="bounce">Niet Afgeleverd</option>
        </select>
      </div>

      {/* Toggle between Table and Card View */}

      <Table responsive striped>
        <thead style={{ textAlign: "left" }}>
          <tr>
            <th>Status</th>
            <th>#</th>
            <th>Offerte</th>
            <th width="200px">Datum aangemaakt</th>
            <th>Bekeken</th>
            {/* <th>Acties</th> */}
          </tr>
        </thead>
        <tbody style={{ textAlign: "left" }}>
          <tr>
            {filteredOffertes.length === 0 && (
              <td
                colSpan={5}
                style={{ width: "100%", textAlign: "center", paddingTop: 10 }}
              >
                Er zijn nog geen offertes gemaakt
              </td>
            )}
          </tr>
          {filteredOffertes
            .sort((a, b) => {
              return (
                new Date(b.createdAt.toDate()) - new Date(a.createdAt.toDate())
              );
            })
            .map((offerte) => (
              <tr
                key={offerte.id}
                className="clickable-tr"
                onClick={() =>
                  (window.location.href = `/offertes/${offerte.id}`)
                }
              >
                <td valign="middle">{getStatusName(offerte.status)}</td>
                <td valign="middle">{offerte.offerteNr || "n.n.b."}</td>
                <td valign="middle">
                  <strong>
                    {offerte.customerDetails?.firstName}{" "}
                    {offerte.customerDetails?.lastName}
                    <br />
                  </strong>
                  {offerte.customerDetails?.straat}{" "}
                  {offerte.customerDetails?.huisnummer},{" "}
                  {offerte.customerDetails?.plaats}
                  <br />
                  <small>
                    {offerte.selectedProduct?.naam}
                    <br />
                    {formatDutchPrice(offerte.finalPrice)} incl. btw
                  </small>
                </td>
                <td valign="middle">
                  {moment(offerte.createdAt.toDate()).format("DD-MM-YYYY") ||
                    "Onbekend"}
                  <br />

                  {offerte.signedAt && (
                    <span className="sign-date-table">
                      <span>Tekendatum:</span>
                      <span>
                        {moment(offerte.signedAt?.toDate()).format(
                          "DD-MM-YYYY"
                        )}
                      </span>
                    </span>
                  )}
                </td>
                <td valign="middle">
                  {isOfferteSeen(offerte.offerteNr) ? (
                    <FaEye title="Bekeken door klant" color="green" />
                  ) : (
                    <FaRegEyeSlash title="Nog niet bekeken" color="gray" />
                  )}
                </td>
                {/* <td>
                                    <Button
                                        color="primary"
                                        size="sm"
                                        onClick={() => (window.location.href = `/offertes/${offerte.id}`)}
                                    >
                                        Bekijken
                                    </Button>{" "}
                                    <Button
                                        color="warning"
                                        size="sm"
                                        onClick={() => (window.location.href = `/offertes/edit/${offerte.id}`)}
                                    >
                                        Bewerken
                                    </Button>{" "}
                                    <Button
                                        color="secondary"
                                        size="sm"
                                        onClick={() => handleDuplicateOfferte(offerte)}
                                    >
                                        Dupliceren
                                    </Button>
                                </td> */}
              </tr>
            ))}
        </tbody>
      </Table>
    </Container>
  );
};

export default OffertesPage;
