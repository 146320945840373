import React, { useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebaseConfig";

const SchouwModal = ({ isOpen, onClose, onSave, lead }) => {
  console.log(lead);
  const [formData, setFormData] = useState({
    schouwDate: "",
    schouwTime: "",
    batteryLocation: "",
    inverterLocation: "",
    spaceDetails: "",
    ventilation: "",
    accessNotes: "",
    photos: [],
  });

  const [uploading, setUploading] = useState(false);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileUpload = (e) => {
    const files = e.target.files;
    const uploadedPhotos = [];

    setUploading(true);

    Array.from(files).forEach((file) => {
      const storageRef = ref(storage, `schouw-photos/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        null,
        (error) => {
          console.error("Upload failed:", error);
          setUploading(false);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          uploadedPhotos.push(downloadURL);

          if (uploadedPhotos.length === files.length) {
            setFormData((prev) => ({
              ...prev,
              photos: [...prev.photos, ...uploadedPhotos],
            }));
            setUploading(false);
          }
        }
      );
    });
  };

  const handleSubmit = () => {
    const updatedLead = {
      ...lead,
      schouwDetails: {
        ...formData,
      },
      status: "installatie_inplannen",
    };
    onSave(updatedLead);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} toggle={onClose} fullscreen>
      <ModalHeader toggle={onClose}>Schouw Uitvoeren</ModalHeader>
      <ModalBody>
        <Container>
          <Row>
            <Col>
              <h4>Klantinformatie</h4>
              <p>
                <strong>Naam:</strong> {lead?.customerDetails.firstName}{" "}
                {lead?.customerDetails.lastName}
              </p>
              <p>
                <strong>Adres:</strong> {lead?.customerDetails.straat}{" "}
                {lead?.customerDetails.huisnummer},{" "}
                {lead?.customerDetails.plaats}
              </p>
              <p>
                <strong>Telefoonnummer:</strong> {lead?.customerDetails.phone}
              </p>
              <p>
                <strong>E-mailadres:</strong> {lead?.customerDetails.email}
              </p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col>
              <Form>
                <FormGroup>
                  <Label for="schouwDate">Schouwdatum</Label>
                  <Input
                    type="date"
                    name="schouwDate"
                    id="schouwDate"
                    value={formData.schouwDate}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="schouwTime">Schouwtijd</Label>
                  <Input
                    type="time"
                    name="schouwTime"
                    id="schouwTime"
                    value={formData.schouwTime}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="batteryLocation">Locatie van de batterij</Label>
                  <Input
                    type="text"
                    name="batteryLocation"
                    id="batteryLocation"
                    value={formData.batteryLocation}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="inverterLocation">Locatie van de omvormer</Label>
                  <Input
                    type="text"
                    name="inverterLocation"
                    id="inverterLocation"
                    value={formData.inverterLocation}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="spaceDetails">Ruimte en ventilatie</Label>
                  <Input
                    type="textarea"
                    name="spaceDetails"
                    id="spaceDetails"
                    value={formData.spaceDetails}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="accessNotes">Toegangsopmerkingen</Label>
                  <Input
                    type="textarea"
                    name="accessNotes"
                    id="accessNotes"
                    value={formData.accessNotes}
                    onChange={handleChange}
                  />
                </FormGroup>
                <FormGroup>
                  <Label for="photos">Foto's uploaden</Label>
                  <Input
                    type="file"
                    name="photos"
                    id="photos"
                    multiple
                    accept="image/*"
                    onChange={handleFileUpload}
                  />
                  {uploading && <p>Bezig met uploaden...</p>}
                </FormGroup>
              </Form>
            </Col>
          </Row>
        </Container>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleSubmit}
          disabled={
            uploading || !formData.schouwDate || !formData.batteryLocation
          }
        >
          Opslaan
        </Button>
        <Button color="secondary" onClick={onClose}>
          Annuleren
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SchouwModal;
