import React, { useState } from "react";
import { getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import { storage, db } from "../../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { collection, addDoc } from "firebase/firestore";
import { v4 as uuidv4 } from "uuid";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import ProductDependencies from "../../components/ProductDependencies";
import BackButton from "../../components/BackButton";

const ProductAddPage = () => {
  const navigate = useNavigate();
  const [productData, setProductData] = useState({
    name: "",
    category: "",
    description: "",
    features: [],
    specifications: "",
    highlights: [],
    variations: [],
    priceNote: "",
  });

  const [featuresInput, setFeaturesInput] = useState("");
  const [highlightsInput, setHighlightsInput] = useState("");
  const [variationInput, setVariationInput] = useState({ name: "", price: "" });
  const [imageFiles, setImageFiles] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => ({ ...prev, [name]: value }));
  };

  const handleAddFeature = () => {
    if (featuresInput.trim()) {
      setProductData((prev) => ({
        ...prev,
        features: [...prev.features, featuresInput.trim()],
      }));
      setFeaturesInput("");
    }
  };

  const handleAddHighlight = () => {
    if (highlightsInput.trim()) {
      setProductData((prev) => ({
        ...prev,
        highlights: [...prev.highlights, highlightsInput.trim()],
      }));
      setHighlightsInput("");
    }
  };

  const handleAddVariation = () => {
    if (variationInput.name && variationInput.price) {
      setProductData((prev) => ({
        ...prev,
        variations: [...prev.variations, variationInput],
      }));
      setVariationInput({ name: "", price: "" });
    }
  };

  const handleRemoveItem = (key, index) => {
    setProductData((prev) => ({
      ...prev,
      [key]: prev[key].filter((_, i) => i !== index),
    }));
  };

  const handleImageUpload = async () => {
    const uploadPromises = imageFiles.map((file) => {
      const storageRef = ref(
        storage,
        `product-images/${uuidv4()}-${file.name}`
      );
      return uploadBytes(storageRef, file).then((snapshot) =>
        getDownloadURL(snapshot.ref)
      );
    });
    return Promise.all(uploadPromises);
  };

  const handlePdfUpload = async () => {
    const uploadPromises = pdfFiles.map((file) => {
      const storageRef = ref(storage, `product-pdfs/${uuidv4()}-${file.name}`);
      return uploadBytes(storageRef, file).then((snapshot) =>
        getDownloadURL(snapshot.ref)
      );
    });
    return Promise.all(uploadPromises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const auth = getAuth();
    if (!auth.currentUser) {
      alert("You must be logged in to add a product.");
      return;
    }

    try {
      // Upload files and save product data
      const imageUrls = await handleImageUpload();
      const pdfUrls = await handlePdfUpload();

      const newProduct = {
        ...productData,
        images: imageUrls,
        pdfs: pdfUrls,
        createdAt: new Date(),
      };

      await addDoc(collection(db, "productsNew"), newProduct);
      alert("Product added successfully!");
      navigate("/products");
    } catch (error) {
      console.error("Error adding product:", error);
      alert("Failed to add product. Please try again.");
    }
  };

  return (
    <Container className="product-add-page">
      <BackButton />
      <Card className="p-4 mt-3 shadow-sm" style={{ backgroundColor: "#fff" }}>
        <CardBody>
          <h2 className="text-center mb-4">Product Toevoegen</h2>
          <Form onSubmit={handleSubmit}>
            {/* Product Name and Category */}
            <Row>
              <Col md={4}>
                <FormGroup>
                  <Label for="name">Product Naam:</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={productData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="category">Categorie:</Label>
                  <Input
                    className="select-input"
                    type="select"
                    name="category"
                    id="category"
                    value={productData.category}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Selecteer een categorie</option>
                    <option value="thuisbatterij">Thuisbatterij</option>
                    <option value="zonnepanelen">Zonnepanelen</option>
                    <option value="omvormers">Omvormers</option>
                    <option value="overig">Overig</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={4}>
                <FormGroup>
                  <Label for="price">Basisprijs:</Label>
                  <Input
                    type="text"
                    name="price"
                    id="price"
                    value={productData.price}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* Description and Specifications */}
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="description">Beschrijving:</Label>
                  <ReactQuill
                    value={productData.description}
                    onChange={(value) =>
                      setProductData((prev) => ({
                        ...prev,
                        description: value,
                      }))
                    }
                    theme="snow"
                  />
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup>
                  <Label for="specifications">Specificaties:</Label>
                  <ReactQuill
                    value={productData.specifications}
                    onChange={(value) =>
                      setProductData((prev) => ({
                        ...prev,
                        specifications: value,
                      }))
                    }
                    theme="snow"
                  />
                </FormGroup>
              </Col>
            </Row>

            {/* Highlights */}
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Belangrijkste Kenmerken:</Label>
                  <div className="d-flex mb-2">
                    <Input
                      type="text"
                      value={highlightsInput}
                      onChange={(e) => setHighlightsInput(e.target.value)}
                    />
                    <Button
                      color="primary"
                      className="ms-2"
                      onClick={handleAddHighlight}
                    >
                      Toevoegen
                    </Button>
                  </div>
                  <ListGroup>
                    {productData.highlights.map((highlight, index) => (
                      <ListGroupItem
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {highlight}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleRemoveItem("highlights", index)}
                        >
                          Verwijderen
                        </Button>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </FormGroup>
              </Col>
            </Row>

            {/* Variations */}
            {/* <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Product Variaties:</Label>
                  <div className="d-flex mb-2">
                    <Input
                      type="text"
                      placeholder="Naam (e.g., 5kW)"
                      value={variationInput.name}
                      onChange={(e) =>
                        setVariationInput((prev) => ({
                          ...prev,
                          name: e.target.value,
                        }))
                      }
                    />
                    <Input
                      type="number"
                      placeholder="Prijs"
                      className="ms-2"
                      value={variationInput.price}
                      onChange={(e) =>
                        setVariationInput((prev) => ({
                          ...prev,
                          price: e.target.value,
                        }))
                      }
                    />
                    <Button
                      color="primary"
                      className="ms-2"
                      onClick={handleAddVariation}
                    >
                      Toevoegen
                    </Button>
                  </div>
                  <ListGroup>
                    {productData.variations.map((variation, index) => (
                      <ListGroupItem
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {variation.name} - €{variation.price}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleRemoveItem("variations", index)}
                        >
                          Verwijderen
                        </Button>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </FormGroup>
              </Col>
            </Row> */}

            {/* Features */}
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label>Features:</Label>
                  <div className="d-flex mb-2">
                    <Input
                      type="text"
                      value={featuresInput}
                      onChange={(e) => setFeaturesInput(e.target.value)}
                    />
                    <Button
                      color="primary"
                      className="ms-2"
                      onClick={handleAddFeature}
                    >
                      Toevoegen
                    </Button>
                  </div>
                  <ListGroup>
                    {productData.features.map((feature, index) => (
                      <ListGroupItem
                        key={index}
                        className="d-flex justify-content-between align-items-center"
                      >
                        {feature}
                        <Button
                          size="sm"
                          color="danger"
                          onClick={() => handleRemoveItem("features", index)}
                        >
                          Verwijderen
                        </Button>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </FormGroup>
              </Col>
            </Row>

            {/* Images and PDFs */}
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="images">Product Afbeeldingen:</Label>
                  <Input
                    type="file"
                    id="images"
                    accept="image/*"
                    multiple
                    onChange={(e) => setImageFiles(Array.from(e.target.files))}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="pdfs">PDF Datasheets:</Label>
                  <Input
                    type="file"
                    id="pdfs"
                    accept=".pdf"
                    multiple
                    onChange={(e) => setPdfFiles(Array.from(e.target.files))}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <ProductDependencies
                  productData={productData}
                  setProductData={setProductData}
                />
              </Col>
            </Row>
            {/* Submit Button */}
            <Row>
              <Col className="text-center">
                <Button type="submit" color="success" className="mt-4">
                  Product Opslaan
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default ProductAddPage;
