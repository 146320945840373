import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { db, storage } from "../../firebaseConfig";
import {
  doc,
  getDoc,
  addDoc,
  updateDoc,
  collection,
  getDocs,
} from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  ListGroup,
  ListGroupItem,
  Card,
  CardBody,
} from "reactstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import BackButton from "../../components/BackButton";
import { toastHelper } from "../../utils/helpers";
import { toast } from "react-toastify";

const ProductFormPage = ({ isEditMode }) => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [productData, setProductData] = useState({
    name: "",
    category: "",
    description: "",
    price: 0,
    images: [],
    pdfs: [],
    packages: [],
  });
  const [allCategories, setAllCategories] = useState([]);
  const [allPackages, setAllPackages] = useState([]);
  const [imageFiles, setImageFiles] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([]);

  useEffect(() => {
    if (isEditMode) {
      const fetchProduct = async () => {
        try {
          const productRef = doc(db, "productsNew", id);
          const productSnap = await getDoc(productRef);
          if (productSnap.exists()) {
            const product = productSnap.data();
            fetchCategories();
            setProductData(product);
            console.log(product);
            setSelectedPackages(product.packages || []);
          } else {
            alert("Product not found!");
            navigate("/admin/products");
          }
        } catch (error) {
          console.error("Error fetching product:", error);
        }
      };
      fetchProduct();
    }

    const fetchPackages = async () => {
      try {
        const packagesSnap = await getDocs(collection(db, "packages"));
        const fetchedPackages = packagesSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAllPackages(fetchedPackages);
      } catch (error) {
        console.error("Error fetching packages:", error);
      }
    };

    fetchPackages();
  }, [isEditMode, id, navigate]);

  useEffect(() => {
    fetchCategories();
  }, []);

  const fetchCategories = async () => {
    console.log("fetch products");
    const packagesSnap = await getDocs(collection(db, "productCategories"));
    const fetchedCategories = packagesSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    console.log(fetchedCategories);
    setAllCategories(fetchedCategories);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData((prev) => ({ ...prev, [name]: value }));
  };

  const handlePackageToggle = (packageId) => {
    setSelectedPackages((prev) =>
      prev.includes(packageId)
        ? prev.filter((id) => id !== packageId)
        : [...prev, packageId]
    );
  };

  const handleFileUpload = async (files, folder) => {
    const uploadPromises = files.map((file) => {
      const storageRef = ref(storage, `${folder}/${file.name}-${Date.now()}`);
      return uploadBytes(storageRef, file).then((snapshot) =>
        getDownloadURL(snapshot.ref)
      );
    });
    return Promise.all(uploadPromises);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const imageUrls = await handleFileUpload(imageFiles, "product-images");
      const pdfUrls = await handleFileUpload(pdfFiles, "product-pdfs");

      const productToSave = {
        ...productData,
        // packages: selectedPackages,
        images: [...productData.images, ...imageUrls],
        pdfs: [...productData.pdfs, ...pdfUrls],
      };

      if (isEditMode) {
        const productRef = doc(db, "productsNew", id);
        await updateDoc(productRef, productToSave);
        toastHelper.success("Product updated successfully!");
      } else {
        await addDoc(collection(db, "productsNew"), {
          ...productToSave,
          createdAt: new Date(),
        });
        toastHelper.success("Product added successfully!");
      }

      navigate("/admin/producten");
    } catch (error) {
      console.error("Error saving product:", error);
      toastHelper.error("Failed to save product.");
    }
  };

  return (
    <Container className="product-form-page mt-4">
      <BackButton />
      <Card className="p-4 shadow-sm">
        <CardBody>
          <h2 className="text-center mb-4">
            {isEditMode ? "Product Bewerken" : "Product Toevoegen"}
          </h2>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="name">Product Naam:</Label>
                  <Input
                    type="text"
                    name="name"
                    id="name"
                    value={productData.name}
                    onChange={handleChange}
                    required
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="category">Categorie:</Label>
                  <Input
                    type="select"
                    name="category"
                    id="category"
                    value={productData.category}
                    onChange={handleChange}
                    required
                  >
                    <option value="">Selecteer een categorie</option>
                    {allCategories.map((m, i) => {
                      return (
                        <option key={i} value={m.name}>
                          {m.name}
                        </option>
                      );
                    })}

                    {/* <option value="thuisbatterij">Thuisbatterij</option>
                    <option value="zonnepanelen">Zonnepanelen</option>
                    <option value="omvormers">Omvormers</option>
                    <option value="meerwerk">Meerwerk</option> */}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label for="price">Prijs:</Label>
                  <Input
                    type="text"
                    name="price"
                    id="price"
                    value={productData.price}
                    onChange={handleChange}
                    min={0}
                  />
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label for="inputType">Type product</Label>
                  <Input
                    type="select"
                    name="inputType"
                    id="inputType"
                    value={productData.inputType}
                    onChange={handleChange}
                    required
                  >
                    <option>Selecteer opties</option>
                    <option value="single">Checkbox</option>
                    <option value="quantity">Aantal</option>
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <FormGroup>
                  <Label for="description">Beschrijving:</Label>
                  <ReactQuill
                    value={productData.description}
                    onChange={(value) =>
                      setProductData((prev) => ({
                        ...prev,
                        description: value,
                      }))
                    }
                    theme="snow"
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row>
              <Col md={6}>
                <FormGroup>
                  <Label>Product Afbeeldingen:</Label>
                  <Input
                    type="file"
                    accept="image/*"
                    multiple
                    onChange={(e) => setImageFiles(Array.from(e.target.files))}
                  />
                  {productData.images.length > 0 && (
                    <ListGroup className="mt-3">
                      {productData.images.map((url, index) => (
                        <ListGroupItem
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            Afbeelding {index + 1}
                          </a>
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() =>
                              setProductData((prev) => ({
                                ...prev,
                                images: prev.images.filter(
                                  (_, i) => i !== index
                                ),
                              }))
                            }
                          >
                            Verwijderen
                          </Button>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <Label>PDF Datasheets:</Label>
                  <Input
                    type="file"
                    accept=".pdf"
                    multiple
                    onChange={(e) => setPdfFiles(Array.from(e.target.files))}
                  />
                  {productData.pdfs.length > 0 && (
                    <ListGroup className="mt-3">
                      {productData.pdfs.map((url, index) => (
                        <ListGroupItem
                          key={index}
                          className="d-flex justify-content-between align-items-center"
                        >
                          <a
                            href={url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            PDF {index + 1}
                          </a>
                          <Button
                            size="sm"
                            color="danger"
                            onClick={() =>
                              setProductData((prev) => ({
                                ...prev,
                                pdfs: prev.pdfs.filter((_, i) => i !== index),
                              }))
                            }
                          >
                            Verwijderen
                          </Button>
                        </ListGroupItem>
                      ))}
                    </ListGroup>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col className="text-center">
                <Button type="submit" color="success" className="mt-4">
                  {isEditMode ? "Opslaan" : "Toevoegen"}
                </Button>
              </Col>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </Container>
  );
};

export default ProductFormPage;
