import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import {
  Button,
  Input,
  Table,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { FaEdit, FaTrashAlt } from "react-icons/fa";

const AdminCategoriesPage = () => {
  const [categories, setCategories] = useState([]);
  const [newCategoryName, setNewCategoryName] = useState("");
  const [editingCategory, setEditingCategory] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);

  // Fetch categories from Firestore
  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoriesRef = collection(db, "productCategories");
        const querySnapshot = await getDocs(categoriesRef);
        const fetchedCategories = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setCategories(fetchedCategories);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  // Add a new category
  const handleAddCategory = async () => {
    if (!newCategoryName.trim()) {
      alert("Categorie naam mag niet leeg zijn.");
      return;
    }

    try {
      const category = { name: newCategoryName };
      await addDoc(collection(db, "productCategories"), category);
      setCategories((prev) => [...prev, category]);
      setNewCategoryName("");
      alert("Categorie succesvol toegevoegd.");
    } catch (error) {
      console.error("Error adding category:", error);
    }
  };

  // Update a category
  const handleUpdateCategory = async () => {
    if (!editingCategory.name.trim()) {
      alert("Categorie naam mag niet leeg zijn.");
      return;
    }

    try {
      const categoryRef = doc(db, "productCategories", editingCategory.id);
      await updateDoc(categoryRef, { name: editingCategory.name });
      setCategories((prev) =>
        prev.map((cat) =>
          cat.id === editingCategory.id
            ? { ...cat, name: editingCategory.name }
            : cat
        )
      );
      setEditingCategory(null);
      toggleModal();
      alert("Categorie succesvol bijgewerkt.");
    } catch (error) {
      console.error("Error updating category:", error);
    }
  };

  // Delete a category
  const handleDeleteCategory = async (categoryId) => {
    if (
      !window.confirm("Weet je zeker dat je deze categorie wilt verwijderen?")
    )
      return;

    try {
      const categoryRef = doc(db, "productCategories", categoryId);
      await deleteDoc(categoryRef);
      setCategories((prev) => prev.filter((cat) => cat.id !== categoryId));
      alert("Categorie succesvol verwijderd.");
    } catch (error) {
      console.error("Error deleting category:", error);
    }
  };

  // Open modal for editing
  const openEditModal = (category) => {
    setEditingCategory(category);
    toggleModal();
  };

  // Toggle modal state
  const toggleModal = () => setModalOpen(!modalOpen);

  return (
    <div className="widget category-widget">
      <div className="widget-header">
        <h3>Productcategorieën Beheer</h3>
      </div>

      <div className="widget-content">
        <Row className="mb-4">
          <Col md={6}>
            <Input
              type="text"
              value={newCategoryName}
              onChange={(e) => setNewCategoryName(e.target.value)}
              placeholder="Nieuwe categorie toevoegen..."
            />
          </Col>
          <Col md={3}>
            <Button color="primary" onClick={handleAddCategory}>
              Categorie Toevoegen
            </Button>
          </Col>
        </Row>

        <Table bordered>
          <thead>
            <tr>
              <th>Naam</th>
              <th>Acties</th>
            </tr>
          </thead>
          <tbody>
            {categories.length > 0 ? (
              categories.map((category) => (
                <tr key={category.id}>
                  <td>{category.name}</td>
                  <td>
                    <Button
                      color="warning"
                      size="sm"
                      className="me-2"
                      onClick={() => openEditModal(category)}
                    >
                      <FaEdit /> Bewerken
                    </Button>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDeleteCategory(category.id)}
                    >
                      <FaTrashAlt /> Verwijderen
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="2" style={{ textAlign: "center" }}>
                  Geen categorieën beschikbaar
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Edit Category Modal */}
      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>Categorie Bewerken</ModalHeader>
        <ModalBody>
          <Input
            type="text"
            value={editingCategory?.name || ""}
            onChange={(e) =>
              setEditingCategory((prev) => ({ ...prev, name: e.target.value }))
            }
            placeholder="Voer een nieuwe naam in..."
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleUpdateCategory}>
            Opslaan
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdminCategoriesPage;
