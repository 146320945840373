import React, { useState, useEffect } from "react";
import {
  Button,
  Row,
  Col,
  Input,
  FormGroup,
  Label,
  Table,
  Card,
  CardBody,
} from "reactstrap";
import { db } from "../../firebaseConfig";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";

const DiscountAdmin = () => {
  const [discounts, setDiscounts] = useState([]);
  const [newDiscount, setNewDiscount] = useState({
    name: "",
    value: 0,
    type: "fixed",
  });

  // Fetch existing discounts
  useEffect(() => {
    const fetchDiscounts = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "discounts"));
        const fetchedDiscounts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setDiscounts(fetchedDiscounts);
      } catch (error) {
        console.error("Error fetching discounts:", error);
      }
    };

    fetchDiscounts();
  }, []);

  // Handle input changes for new discount
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDiscount((prev) => ({
      ...prev,
      [name]: name === "value" ? parseFloat(value) || 0 : value,
    }));
  };

  // Add a new discount
  const handleAddDiscount = async () => {
    if (!newDiscount.name || newDiscount.value <= 0) {
      alert("Please provide a valid name and value for the discount.");
      return;
    }

    try {
      const docRef = await addDoc(collection(db, "discounts"), newDiscount);
      setDiscounts([...discounts, { id: docRef.id, ...newDiscount }]);
      setNewDiscount({ name: "", value: 0, type: "fixed" });
    } catch (error) {
      console.error("Error adding discount:", error);
    }
  };

  // Delete a discount
  const handleDeleteDiscount = async (id) => {
    try {
      await deleteDoc(doc(db, "discounts", id));
      setDiscounts(discounts.filter((discount) => discount.id !== id));
    } catch (error) {
      console.error("Error deleting discount:", error);
    }
  };

  return (
    <div className=" mt-4">
      <Card>
        <CardBody>
          <h3>Beheer Kortingen</h3>
          {/* Add Discount Form */}
          <Row className="mb-4">
            <Col md={4}>
              <FormGroup>
                <Label for="discountName">Naam van korting</Label>
                <Input
                  type="text"
                  id="discountName"
                  name="name"
                  value={newDiscount.name}
                  onChange={handleInputChange}
                  placeholder="Bijv. Winterkorting"
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="discountValue">Waarde</Label>
                <Input
                  type="number"
                  id="discountValue"
                  name="value"
                  value={newDiscount.value}
                  onChange={handleInputChange}
                  placeholder="Bijv. 10 of 200"
                />
              </FormGroup>
            </Col>
            <Col md={3}>
              <FormGroup>
                <Label for="discountType">Type</Label>
                <Input
                  type="select"
                  id="discountType"
                  name="type"
                  value={newDiscount.type}
                  onChange={handleInputChange}
                >
                  <option value="fixed">Vast bedrag (€)</option>
                  <option value="percentage">Percentage (%)</option>
                </Input>
              </FormGroup>
            </Col>
            <Col md={2} className="d-flex align-items-end">
              <Button color="primary" onClick={handleAddDiscount}>
                Korting Toevoegen
              </Button>
            </Col>
          </Row>

          {/* Discount List */}
          <h5>Bestaande Kortingen</h5>
          <Table responsive bordered>
            <thead>
              <tr>
                <th>Naam</th>
                <th>Waarde</th>
                <th>Type</th>
                <th>Acties</th>
              </tr>
            </thead>
            <tbody>
              {discounts.length > 0 ? (
                discounts.map((discount) => (
                  <tr key={discount.id}>
                    <td>{discount.name}</td>
                    <td>
                      {discount.type === "percentage"
                        ? `${discount.value}%`
                        : `€${discount.value}`}
                    </td>
                    <td>
                      {discount.type === "fixed" ? "Vast bedrag" : "Percentage"}
                    </td>
                    <td>
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => handleDeleteDiscount(discount.id)}
                      >
                        Verwijderen
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center">
                    Geen kortingen gevonden.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};

export default DiscountAdmin;
