import React, { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import { Row, Col } from "reactstrap"; // Reactstrap componenten
import "../Header.css";
import usePageIdentifier from "../../utils/usePageIdentifier";
import getInitials from "../../utils/getInitials";
const AdminHeader = ({ onLogout, user }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const currentPage = usePageIdentifier();
  const toggleMenu = () => setMenuOpen(!menuOpen);

  return (
    <header className="admin-header">
      <Row className="align-items-center">
        {/* Logo (NavLink er kolom) */}
        <Col xs="6" md="6">
          <div className="admin-title align-items-center">{currentPage}</div>
        </Col>

        {/* Menu (rechter kolom) */}
        <Col xs="6" md="6" className="text-right">
          <nav className={`nav ${menuOpen ? "open" : ""}`}>
            <ul className="menu-list">
              <li>
                <NavLink
                  className={({ isActive }) => (isActive ? "active" : "")}
                  end
                  to="/"
                  onClick={() => setMenuOpen(false)}
                >
                  Verkooptool
                </NavLink>
              </li>
              <li className="dropdown">
                <div className="dropdown-toggler">
                  <span>MD</span>
                </div>
                <ul className="dropdown-menu">
                  <li className="header-item">
                    <div
                      className="dropdown-toggler"
                      style={{ marginRight: 10 }}
                    >
                      <span className="initials">{getInitials(user.name)}</span>
                    </div>
                    {user.email}
                  </li>

                  <div className="separator price dropdown"></div>
                  <li className="header-item logout" onClick={onLogout}>
                    Uitloggen
                  </li>
                </ul>
              </li>
            </ul>
          </nav>
          <button className="hamburger" onClick={toggleMenu}>
            {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
          </button>
        </Col>
      </Row>
    </header>
  );
};

export default AdminHeader;
