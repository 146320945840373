import React from "react";
import { Card, CardBody } from "reactstrap";
import PropTypes from "prop-types";
import "./AmountWidget.css"; // Add your own styles here

const AmountWidget = ({ title, icon: Icon, count, color }) => {
  return (
    <Card className="widget-card text-center">
      <CardBody>
        <div className={`widget-icon mb-3 text-${color}`}>
          <Icon size={40} />
        </div>
        <h5 className="widget-title">{title}</h5>
        <h3 className="widget-count">{count}</h3>
      </CardBody>
    </Card>
  );
};

AmountWidget.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.elementType.isRequired,
  count: PropTypes.number.isRequired,
  color: PropTypes.string, // e.g., primary, success, danger
};

AmountWidget.defaultProps = {
  color: "primary",
};

export default AmountWidget;
