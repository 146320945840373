import React, { useState } from "react";
import { FiMenu, FiX } from "react-icons/fi";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Container, Row, Col } from "reactstrap"; // Reactstrap componenten
import "./Header.css";
import logo from "../Assets/logo.webp";
import usePageIdentifier from "../utils/usePageIdentifier";
import getInitials from "../utils/getInitials";

const Header = ({ onLogout, user }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => setMenuOpen(!menuOpen);
  const currentPage = usePageIdentifier();
  // console.log(user);

  if (location.pathname.includes("public")) {
    return null; // Don't render the header if "public" is in the URL
  }
  // console.log(user.userGroup);
  return (
    <header className="header">
      <Container>
        <Row className="align-items-center">
          {/* Logo (linker kolom) */}
          <Col xs="4" md="4">
            <div className="inner-header-flex">
              <div className="logo">
                <img
                  src={logo}
                  alt="Thuisbatterij Experts"
                  style={{ height: "100%" }}
                />
              </div>

              {user.userGroup && (
                <div className="company-info">
                  {user.userGroup.name === "ThuisbatterijExperts"
                    ? ""
                    : user.userGroup.name}
                </div>
              )}
            </div>
          </Col>

          {/* Menu (rechter kolom) */}
          <Col xs="8" md="8" className="text-right">
            <nav className={`nav ${menuOpen ? "open" : ""}`}>
              <ul className="menu-list">
                <li>
                  <Link
                    to="/"
                    onClick={() => setMenuOpen(false)}
                    className={currentPage === "Dashboard" ? "active" : ""}
                  >
                    Start
                  </Link>
                </li>
                {/* <li>
                  <Link to="/leads" onClick={() => setMenuOpen(false)}>
                    Leads
                  </Link>
                </li> */}
                <li>
                  <Link
                    to="/offertes"
                    onClick={() => setMenuOpen(false)}
                    className={currentPage === "Offertes" ? "active" : ""}
                  >
                    Offertes
                  </Link>
                </li>
                <li>
                  <Link
                    to="/leads"
                    onClick={() => setMenuOpen(false)}
                    className={currentPage === "Leads" ? "active" : ""}
                  >
                    Leads
                  </Link>
                </li>
                <li>
                  <Link
                    to="/taken"
                    onClick={() => setMenuOpen(false)}
                    className={currentPage === "Taken" ? "active" : ""}
                  >
                    Taken
                  </Link>
                </li>
                <li>
                  <Link
                    to="/agenda"
                    onClick={() => setMenuOpen(false)}
                    className={currentPage === "Agenda" ? "active" : ""}
                  >
                    Agenda
                  </Link>
                </li>
                <li>
                  <Link
                    to="/media"
                    onClick={() => setMenuOpen(false)}
                    className={currentPage === "Media" ? "active" : ""}
                  >
                    Media
                  </Link>
                </li>
                <li>
                  <Link
                    to="/faq"
                    onClick={() => setMenuOpen(false)}
                    className={currentPage === "Faq" ? "active" : ""}
                  >
                    Kennisbank
                  </Link>
                </li>
                {/* <li>
                  <Link
                    to="/sales-tools"
                    onClick={() => setMenuOpen(false)}
                    className={currentPage === "Sales-tools" ? "active" : ""}
                    end
                  >
                    Sales Tools
                  </Link>
                </li> */}
                <li className="dropdown">
                  <div className="dropdown-toggler">
                    <span className="initials">
                      {getInitials(
                        `${user.personalInfo.firstName} ${user.personalInfo.lastName}`
                      )}
                    </span>
                  </div>
                  <ul className="dropdown-menu">
                    <li className="header-item">
                      <div
                        className="dropdown-toggler"
                        style={{ marginRight: 10 }}
                      >
                        <span className="initials">
                          {getInitials(
                            `${user.personalInfo.firstName} ${user.personalInfo.lastName}`
                          )}
                        </span>
                      </div>
                      {user.email}
                    </li>
                    {/* <li>
                      <Link onClick={() => console.log("Profiel weergeven")}>
                        Profiel weergeven
                      </Link>
                    </li> */}
                    {user.role === "admin" && (
                      <li>
                        <Link to={"/admin"}>Admin Panel</Link>
                      </li>
                    )}

                    {/* <li>
                      <Link onClick={() => console.log("Uitnodigingen")}>
                        Uitnodigingen
                      </Link>
                    </li> */}
                    <div className="separator price dropdown"></div>
                    <li className="header-item logout" onClick={onLogout}>
                      Uitloggen
                    </li>
                  </ul>
                </li>
              </ul>
            </nav>
            <button className="hamburger" onClick={toggleMenu}>
              {menuOpen ? <FiX size={24} /> : <FiMenu size={24} />}
            </button>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
