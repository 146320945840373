import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  where,
  addDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import { Button, Input, FormGroup, Label, Row, Col } from "reactstrap";
import { FaEdit, FaTrash, FaEye, FaCopy } from "react-icons/fa";

const AdminPackagesPage = () => {
  const [packages, setPackages] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterStatus, setFilterStatus] = useState(""); // "all", "active", "inactive"

  useEffect(() => {
    fetchPackages();
  }, [filterStatus]);

  const fetchPackages = async () => {
    try {
      let packagesRef = collection(db, "packages");
      let q = query(packagesRef, orderBy("naam", "asc"));

      // Apply filter based on status
      if (filterStatus === "active") {
        q = query(
          packagesRef,
          where("active", "==", true),
          orderBy("naam", "asc")
        );
      } else if (filterStatus === "inactive") {
        q = query(
          packagesRef,
          where("active", "==", false),
          orderBy("naam", "asc")
        );
      }

      const querySnapshot = await getDocs(q);
      const fetchedPackages = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      setPackages(fetchedPackages);
    } catch (error) {
      console.error("Error fetching packages:", error);
    }
  };

  const handleSearch = (e) => {
    setSearchTerm(e.target.value.toLowerCase());
  };

  const handleDelete = async (id) => {
    if (window.confirm("Weet u zeker dat u dit pakket wilt verwijderen?")) {
      try {
        // Add Firestore delete logic here
        console.log(`Package with ID: ${id} deleted.`);
        fetchPackages();
      } catch (error) {
        console.error("Error deleting package:", error);
        alert("Fout bij het verwijderen van het pakket.");
      }
    }
  };

  const handleDuplicatePackage = async (packageItem) => {
    try {
      // Duplicate package data
      const newPackageData = {
        ...packageItem,
        naam: `${packageItem.naam} (Kopie)`,
        createdAt: new Date(),
        updatedAt: new Date(),
      };
      delete newPackageData.id; // Remove the original ID

      // Save the duplicated package
      const docRef = await addDoc(collection(db, "packages"), newPackageData);

      // Redirect to edit/add package page with the new package ID
      window.location.href = `/edit-package/${docRef.id}`;
    } catch (err) {
      console.error("Error duplicating package:", err);
      alert("Het dupliceren van het pakket is mislukt.");
    }
  };

  const filteredPackages = packages.filter((pkg) =>
    pkg.naam?.toLowerCase().includes(searchTerm)
  );

  return (
    <div className="widget package-widget">
      <div className="widget-header d-flex justify-content-between align-items-center">
        <h3>Nieuwste Pakketten</h3>
        <Button
          color="primary"
          className="link-button"
          onClick={() => (window.location.href = "/admin/pakketten/add")}
        >
          Pakket Toevoegen
        </Button>
      </div>

      {/* Search and Filters */}
      <div className="filters d-flex  align-items-center">
        <FormGroup className="search-bar">
          <Label for="search">Zoek op naam:</Label>
          <Input
            type="text"
            id="search"
            placeholder="Zoek pakket..."
            value={searchTerm}
            onChange={handleSearch}
          />
        </FormGroup>

        <FormGroup className="filter-status">
          <Label for="statusFilter">Filter op status:</Label>
          <Input
            type="select"
            id="statusFilter"
            value={filterStatus}
            onChange={(e) => setFilterStatus(e.target.value)}
          >
            <option value="">Alles</option>
            <option value="active">Actief</option>
            <option value="inactive">Inactief</option>
          </Input>
        </FormGroup>
      </div>

      <div className="widget-content">
        <table
          className="package-table"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",

                  borderBottom: "2px solid #ddd",
                }}
              >
                Actief
              </th>
              <th
                style={{
                  textAlign: "left",

                  borderBottom: "2px solid #ddd",
                }}
              >
                Naam
              </th>
              <th
                style={{
                  textAlign: "left",

                  borderBottom: "2px solid #ddd",
                }}
              >
                Prijs
              </th>
              <th
                style={{
                  textAlign: "left",

                  borderBottom: "2px solid #ddd",
                }}
              >
                Datum
              </th>
              <th
                style={{
                  textAlign: "center",

                  borderBottom: "2px solid #ddd",
                }}
              >
                Acties
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredPackages.length > 0 ? (
              filteredPackages.map((packageItem) => (
                <tr
                  key={packageItem.id}
                  style={{ borderBottom: "1px solid #ddd" }}
                >
                  <td>{packageItem.active ? "Actief" : "Inactief"}</td>
                  <td style={{ fontSize: 11, fontWeight: 500 }}>
                    {packageItem.naam || "Onbekend"}
                  </td>
                  <td>€{packageItem.prijs?.toLocaleString() || "N/A"}</td>
                  <td>
                    {packageItem.createdAt
                      ?.toDate()
                      .toLocaleDateString("nl-NL") || "Onbekend"}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Button
                      color="info"
                      size="sm"
                      onClick={
                        () => console.log(packageItem)
                        // (window.location.href = `/admin/pakketten/${packageItem.id}`)
                      }
                    >
                      <FaEye />
                    </Button>{" "}
                    <Button
                      color="warning"
                      size="sm"
                      onClick={() =>
                        (window.location.href = `/admin/pakketten/edit/${packageItem.id}`)
                      }
                    >
                      <FaEdit />
                    </Button>{" "}
                    <Button
                      color="dark"
                      size="sm"
                      onClick={() => handleDuplicatePackage(packageItem)}
                    >
                      <FaCopy />
                    </Button>{" "}
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDelete(packageItem.id)}
                    >
                      <FaTrash />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" style={{ textAlign: "center", padding: "8px" }}>
                  Geen pakketten beschikbaar
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminPackagesPage;
