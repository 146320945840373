import React, { useEffect, useState } from "react";
import {
  collection,
  getDocs,
  query,
  orderBy,
  limit,
  where,
  deleteDoc,
  doc,
  updateDoc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import {
  Button,
  Input,
  Row,
  Col,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { FaEdit, FaTrashAlt, FaEye } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { toastHelper } from "../../utils/helpers";

const AdminProductsPage = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categories, setCategories] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [packageId, setPackageId] = useState(""); // For assigning products to a package
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedPakket, setSelectedPakket] = useState("");
  const [pakketten, setPakketten] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsRef = collection(db, "productsNew");
        const q = query(productsRef, orderBy("createdAt", "desc"), limit(100));
        const querySnapshot = await getDocs(q);

        const fetchedProducts = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProducts(fetchedProducts);
        setFilteredProducts(fetchedProducts);

        // Extract unique categories for the filter dropdown
        const uniqueCategories = [
          ...new Set(
            fetchedProducts.map((product) => product.category || "Onbekend")
          ),
        ];
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching products:", error);
      }

      const pakkettenRef = collection(db, "packages");
      const pakkettenSnapshot = await getDocs(pakkettenRef);
      const fetchedPakketten = pakkettenSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setPakketten(fetchedPakketten);
    };
    fetchProducts();
  }, []);

  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);

    const filtered = products.filter(
      (product) =>
        product.name?.toLowerCase().includes(query) ||
        product.category?.toLowerCase().includes(query)
    );

    setFilteredProducts(
      selectedCategory
        ? filtered.filter((product) => product.category === selectedCategory)
        : filtered
    );
  };

  const handleCategoryFilter = (e) => {
    const category = e.target.value;
    setSelectedCategory(category);

    const filtered = products.filter(
      (product) =>
        (!category || product.category === category) &&
        (product.name?.toLowerCase().includes(searchQuery) ||
          product.category?.toLowerCase().includes(searchQuery))
    );

    setFilteredProducts(filtered);
  };

  const handleDeleteProduct = async (productId) => {
    if (window.confirm("Weet je zeker dat je dit product wilt verwijderen?")) {
      try {
        await deleteDoc(doc(db, "productsNew", productId));
        setProducts((prev) =>
          prev.filter((product) => product.id !== productId)
        );
        setFilteredProducts((prev) =>
          prev.filter((product) => product.id !== productId)
        );
        alert("Product succesvol verwijderd.");
      } catch (error) {
        console.error("Error deleting product:", error);
        alert("Er is een fout opgetreden bij het verwijderen van het product.");
      }
    }
  };

  const handleSelectProduct = (productId) => {
    setSelectedProducts((prevSelected) =>
      prevSelected.includes(productId)
        ? prevSelected.filter((id) => id !== productId)
        : [...prevSelected, productId]
    );
  };

  const handleSelectAll = () => {
    if (selectedProducts.length === filteredProducts.length) {
      setSelectedProducts([]);
    } else {
      setSelectedProducts(filteredProducts.map((product) => product.id));
    }
  };

  const handleAssignToPackage = async () => {
    if (!selectedPakket.length) {
      alert("Selecteer minimaal één pakket om producten aan toe te wijzen.");
      return;
    }

    try {
      // Get the products to assign
      const productsToAssign =
        selectedProducts.length > 0
          ? selectedProducts
          : filteredProducts.map((p) => p.id);

      await Promise.all(
        selectedPakket.map(async (packageId) => {
          const packageRef = doc(db, "packages", packageId);
          const packageSnapshot = await getDoc(packageRef);
          const packageData = packageSnapshot.data();

          // Update the package with the assigned products
          const updatedProducts = [
            ...(packageData?.producten || []),
            ...productsToAssign.map((productId) => ({
              productId,
              isRequired:
                products.find((p) => p.id === productId)?.isRequired || false,
            })),
          ];

          // Ensure no duplicate products in the package
          const uniqueProducts = Array.from(
            new Map(updatedProducts.map((p) => [p.productId, p])).values()
          );

          await updateDoc(packageRef, { producten: uniqueProducts });
        })
      );

      toastHelper.success("Producten succesvol toegewezen aan de pakketten.");
      setSelectedProducts([]);
      setModalOpen(false);
    } catch (error) {
      console.error("Error assigning products to packages:", error);
      toastHelper.error(
        "Er is een fout opgetreden bij het toewijzen van producten."
      );
    }
  };

  const openSingle = (productId) => {
    navigate(`/admin/producten/${productId}`);
  };

  const editProduct = (productId) => {
    navigate(`/admin/producten/edit/${productId}`);
  };

  return (
    <div className="widget product-widget">
      <div className="widget-header d-flex justify-content-between align-items-center">
        <h3>Producten Beheer</h3>
        <Button
          color="primary"
          className="link-button"
          onClick={() => navigate("/admin/producten/add")}
        >
          Product Toevoegen
        </Button>
      </div>

      <div className="widget-filters mb-4">
        <Row className="align-items-center">
          <Col md={5}>
            <Input
              type="text"
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Zoek op naam of categorie..."
              className="w-100"
            />
          </Col>
          <Col md={4}>
            <Input
              type="select"
              value={selectedCategory}
              onChange={handleCategoryFilter}
              className="w-100"
            >
              <option value="">Alle categorieën</option>
              {categories.map((category, index) => (
                <option key={index} value={category}>
                  {category}
                </option>
              ))}
            </Input>
          </Col>
          {/* <Col md={3} className="text-end">
            <Input
              type="text"
              placeholder="Pakket ID"
              value={packageId}
              onChange={(e) => setPackageId(e.target.value)}
              className="mb-2"
            />
            <Button
              color="success"
              onClick={handleAssignToPackage}
              disabled={selectedProducts.length === 0}
            >
              Toewijzen aan Pakket
            </Button>
          </Col> */}
        </Row>
      </div>

      <div className="widget-content">
        {selectedProducts.length > 0 && (
          <div>
            <Button color="link" onClick={() => setModalOpen(true)}>
              Toewijzen aan pakket ({selectedProducts.length})
            </Button>
          </div>
        )}

        <table
          className="product-table"
          style={{ width: "100%", borderCollapse: "collapse" }}
        >
          <thead>
            <tr>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                <Input
                  type="checkbox"
                  checked={
                    selectedProducts.length === filteredProducts.length &&
                    filteredProducts.length > 0
                  }
                  onChange={handleSelectAll}
                />
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Naam
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Prijs
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Categorie
              </th>
              <th
                style={{
                  textAlign: "left",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Datum
              </th>
              <th
                style={{
                  textAlign: "center",
                  padding: "8px",
                  borderBottom: "2px solid #ddd",
                }}
              >
                Acties
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredProducts.length > 0 ? (
              filteredProducts.map((product) => (
                <tr key={product.id} style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px" }}>
                    <Input
                      type="checkbox"
                      checked={selectedProducts.includes(product.id)}
                      onChange={() => handleSelectProduct(product.id)}
                    />
                  </td>
                  <td style={{ padding: "8px" }}>
                    {product.name || "Onbekend"}
                  </td>
                  <td style={{ padding: "8px" }}>
                    €{product.price?.toLocaleString() || "N/A"}
                  </td>
                  <td style={{ padding: "8px" }}>
                    {product.category || "Onbekend"}
                  </td>
                  <td style={{ padding: "8px" }}>
                    {product.createdAt?.toDate().toLocaleDateString("nl-NL") ||
                      "Onbekend"}
                  </td>
                  <td style={{ padding: "8px", textAlign: "center" }}>
                    <Button
                      color="primary"
                      size="sm"
                      className="me-2"
                      onClick={() => openSingle(product.id)}
                    >
                      <FaEye /> Open
                    </Button>
                    <Button
                      color="warning"
                      size="sm"
                      className="me-2"
                      onClick={() => editProduct(product.id)}
                    >
                      <FaEdit /> Bewerken
                    </Button>
                    <Button
                      color="danger"
                      size="sm"
                      onClick={() => handleDeleteProduct(product.id)}
                    >
                      <FaTrashAlt /> Verwijderen
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="6" style={{ textAlign: "center", padding: "8px" }}>
                  Geen producten beschikbaar
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        size="xl"
        isOpen={isModalOpen}
        toggle={() => setModalOpen(!isModalOpen)}
      >
        <ModalHeader toggle={() => setModalOpen(!isModalOpen)}>
          Toewijzen aan Pakket
        </ModalHeader>
        <ModalBody>
          {/* Select All Packages */}
          <Row>
            <Col>
              <div>
                <Input
                  type="checkbox"
                  id="selectAllPackages"
                  onChange={(e) =>
                    setSelectedPakket(
                      e.target.checked
                        ? pakketten.map((pakket) => pakket.id)
                        : []
                    )
                  }
                  checked={selectedPakket.length === pakketten.length}
                />
                <label htmlFor="selectAllPackages">
                  Selecteer Alle Pakketten
                </label>
              </div>
              <hr />

              {/* Package Selection */}
              <div className="select-items">
                <h5>Selecteer Pakketten</h5>
                <Row>
                  {pakketten
                    .sort((a, b) => a.naam.localeCompare(b.naam))
                    .map((pakket) => (
                      <Col xs={6} key={pakket.id}>
                        <div className="select-item">
                          <Input
                            type="checkbox"
                            id={`pakket-${pakket.id}`}
                            value={pakket.id}
                            checked={selectedPakket.includes(pakket.id)}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              setSelectedPakket(
                                (prev) =>
                                  isChecked
                                    ? [...prev, pakket.id] // Add to the list
                                    : prev.filter((id) => id !== pakket.id) // Remove from the list
                              );
                            }}
                          />
                          <label htmlFor={`pakket-${pakket.id}`}>
                            {pakket.naam}
                          </label>
                        </div>
                      </Col>
                    ))}
                </Row>
              </div>
            </Col>
            <Col>
              {/* Mark Products as Mandatory */}
              <div>
                <h5>Maak Producten Verplicht</h5>
                {selectedProducts.map((productId) => {
                  const product = products.find((p) => p.id === productId);
                  return (
                    <div key={product.id}>
                      <Input
                        type="checkbox"
                        id={`mandatory-${product.id}`}
                        checked={product?.isRequired || false}
                        onChange={(e) => {
                          const isChecked = e.target.checked;
                          setProducts((prevProducts) =>
                            prevProducts.map((p) =>
                              p.id === product.id
                                ? { ...p, isRequired: isChecked }
                                : p
                            )
                          );
                        }}
                      />
                      <label htmlFor={`mandatory-${product.id}`}>
                        {product.name}
                      </label>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleAssignToPackage}>
            Toewijzen
          </Button>
          <Button color="secondary" onClick={() => setModalOpen(false)}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdminProductsPage;
