import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Card,
  CardBody,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import classnames from "classnames";
import moment from "moment";
import { saveTask } from "../firebaseConfig";

const TaskTabs = ({ tasks = [], onAddTask, agent, onFinishTask }) => {
  const [activeTab, setActiveTab] = useState("1");
  const [currentPage, setCurrentPage] = useState(1);
  const tasksPerPage = 3; // Number of tasks per page
  const [selectedTask, setSelectedTask] = useState(null);
  const [taskModalOpen, setTaskModalOpen] = useState(false);

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      setCurrentPage(1); // Reset to first page when switching tabs
    }
  };

  const getRowStyle = (task) => {
    const now = new Date();
    const startTime = new Date(task.startTime.toDate());
    const differenceInDays = Math.ceil(
      (startTime - now) / (1000 * 60 * 60 * 24)
    );

    if (differenceInDays <= 0) return { "--bs-table-bg": "#FFCCCC" }; // Red for overdue
    if (differenceInDays === 1) return { "--bs-table-bg": "#FFE4B5" }; // Orange for 1 day ahead
    return {};
  };

  const paginate = (tasks) => {
    const startIndex = (currentPage - 1) * tasksPerPage;
    return tasks.slice(startIndex, startIndex + tasksPerPage);
  };

  const handlePageChange = (direction, totalPages) => {
    if (direction === "prev" && currentPage > 1) {
      setCurrentPage(currentPage - 1);
    } else if (direction === "next" && currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const openTaskModal = (task) => {
    setSelectedTask(task);
    setTaskModalOpen(true);
  };

  const closeTaskModal = () => {
    setSelectedTask(null);
    setTaskModalOpen(false);
  };

  const handleFinishTask = () => {
    if (selectedTask) {
      const updatedTask = {
        ...selectedTask,
        status: "Afgerond",
        finishedAt: new Date(),
      };
      console.log(updatedTask);
      saveTask(updatedTask);
      closeTaskModal();
      onFinishTask();
    }
  };

  // Separate tasks into active and completed
  const activeTasks = tasks.filter((task) => task.status !== "Afgerond");
  const completedTasks = tasks.filter((task) => task.status === "Afgerond");

  const activeTotalPages = Math.ceil(activeTasks.length / tasksPerPage);
  const completedTotalPages = Math.ceil(completedTasks.length / tasksPerPage);

  return (
    <Card className="mt-4 mb-4">
      <Nav tabs>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            Actieve Taken
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            Historische Taken
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        {/* Active Tasks */}
        <TabPane tabId="1">
          <CardBody>
            {activeTasks.length > 0 ? (
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Titel</th>
                      <th>Beschrijving</th>
                      <th>Datum</th>
                      <th>Acties</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginate(activeTasks).map((task) => (
                      <tr key={task.id} style={getRowStyle(task)}>
                        <td>{task.title}</td>
                        <td>{task.description || "Geen beschrijving"}</td>
                        <td>{moment(task.startTime.toDate()).format("LLL")}</td>
                        <td>
                          <Button
                            color="info"
                            size="sm"
                            onClick={() => openTaskModal(task)}
                          >
                            Bekijken
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <Button
                    color="secondary"
                    disabled={currentPage === 1}
                    onClick={() => handlePageChange("prev", activeTotalPages)}
                  >
                    Vorige
                  </Button>
                  <span>
                    Pagina {currentPage} van {activeTotalPages}
                  </span>
                  <Button
                    color="secondary"
                    disabled={currentPage === activeTotalPages}
                    onClick={() => handlePageChange("next", activeTotalPages)}
                  >
                    Volgende
                  </Button>
                </div>
              </>
            ) : (
              <p>Geen actieve taken gevonden.</p>
            )}
            <Button color="primary" onClick={onAddTask} className="mt-3">
              Nieuwe Taak Toevoegen
            </Button>
          </CardBody>
        </TabPane>

        {/* Completed Tasks */}
        <TabPane tabId="2">
          <CardBody>
            {completedTasks.length > 0 ? (
              <>
                <Table responsive>
                  <thead>
                    <tr>
                      <th>Titel</th>
                      <th>Beschrijving</th>
                      <th>Voltooid Op</th>
                    </tr>
                  </thead>
                  <tbody>
                    {paginate(completedTasks).map((task) => (
                      <tr key={task.id}>
                        <td>{task.title}</td>
                        <td>{task.description || "Geen beschrijving"}</td>
                        <td>
                          {moment(task.finishedAt.toDate()).format("LLL")}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
                <div className="d-flex justify-content-between align-items-center mt-3">
                  <Button
                    color="secondary"
                    disabled={currentPage === 1}
                    onClick={() =>
                      handlePageChange("prev", completedTotalPages)
                    }
                  >
                    Vorige
                  </Button>
                  <span>
                    Pagina {currentPage} van {completedTotalPages}
                  </span>
                  <Button
                    color="secondary"
                    disabled={currentPage === completedTotalPages}
                    onClick={() =>
                      handlePageChange("next", completedTotalPages)
                    }
                  >
                    Volgende
                  </Button>
                </div>
              </>
            ) : (
              <p>Geen historische taken gevonden.</p>
            )}
          </CardBody>
        </TabPane>
      </TabContent>

      {/* Task Modal */}
      <Modal isOpen={taskModalOpen} toggle={closeTaskModal}>
        <ModalHeader toggle={closeTaskModal}>Taak Details</ModalHeader>
        <ModalBody>
          {selectedTask ? (
            <>
              <Row>
                <Col>
                  <Label>Type</Label>
                  <Input type="text" value={selectedTask.type} disabled />
                </Col>
                <Col>
                  <Label className="mt-2">Titel</Label>
                  <Input type="text" value={selectedTask.title} disabled />
                </Col>
              </Row>
              <Row>
                <Col>
                  <Label className="mt-2">Toegewezen aan</Label>
                  <Input
                    type="text"
                    value={agent?.personalInfo?.firstName || "Onbekend"}
                    disabled
                  />
                </Col>
              </Row>

              <Label className="mt-2">Omschrijving</Label>
              <Input
                type="textarea"
                value={selectedTask.description || "Geen omschrijving"}
                disabled
              />

              <Label className="mt-2">Opmerkingen</Label>
              <Input
                type="textarea"
                value={selectedTask.notes || ""}
                placeholder="Voer opmerkingen in over de uitkomst van de taak (minimaal 100 karakters)"
                onChange={(e) =>
                  setSelectedTask((prev) => ({
                    ...prev,
                    notes: e.target.value,
                  }))
                }
              />
              <div className="text-end mt-1">
                <small>{selectedTask?.notes?.length || 0}/30 karakters</small>
              </div>
            </>
          ) : (
            <p>Geen taak geselecteerd.</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={closeTaskModal}>
            Sluiten
          </Button>
          {selectedTask?.status !== "Afgerond" && (
            <Button
              color="success"
              onClick={handleFinishTask}
              disabled={selectedTask?.notes?.length < 30}
            >
              Markeer als voltooid
            </Button>
          )}
        </ModalFooter>
      </Modal>
    </Card>
  );
};

export default TaskTabs;
