import React, { useState, useEffect } from "react";
import { addNoAnswer, archiveLead, db } from "../firebaseConfig"; // Replace with your actual Firebase config
import {
  addDoc,
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useUser } from "../context/UserContext";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Card, CardBody, Table } from "reactstrap";
import moment from "moment";
import { toastHelper } from "../utils/helpers";
import "./LeadsPage.css";
import LeadList from "../components/LeadList";
import PhaseOneModal from "../components/process/PhaseOneModal"; // First modal example
import CancelModal from "../components/CancelModal";
import SchouwModal from "../components/process/SchouwModal";
import "../components/process/process.css";

const phases = [
  {
    title: "Nieuwe Lead",
    status: "new",
    next: "afspraak_gepland",
    action: "Plan een afspraak",
    cta: "Afspraak Inplannen", // CTA voor de knop
    roles: ["sales", "admin"],
    modal: {
      name: "PhaseOneModal",
      fields: [
        { name: "appointmentDate", label: "Afspraakdatum", type: "date" },
        { name: "appointmentTime", label: "Afspraaktijd", type: "time" },
        { name: "notes", label: "Opmerkingen", type: "textarea" },
      ],
    },
  },
  {
    title: "Afspraak Gepland",
    status: "afspraak_gepland",
    next: "offerte_verzonden",
    action: "Maak en verstuur een offerte",
    cta: "Offerte Opstellen",
    ctaConcept: "Bekijk offerte", // CTA voor de knop
    roles: ["sales", "admin"],
    modal: {
      name: "handleCreateOffer",
    },
  },
  {
    title: "Offerte concepten",
    status: "offerte_concept",
    next: "offerte_verzonden",
    action: "Maak en verstuur een offerte",
    cta: "Offerte Opstellen",
    ctaConcept: "Bekijk offerte", // CTA voor de knop
    roles: ["sales", "admin"],
    modal: {
      name: "EditConceptoffer",
    },
  },
  {
    title: "Offerte Verzonden",
    status: "offerte_verzonden",
    next: "wachten_op_akkoord",
    action: "Wacht op goedkeuring van de klant",
    cta: "Offerte details", // CTA voor de knop
    roles: ["sales", "admin"],
    modal: {
      name: "offerteDetails",
    },
  },
  {
    title: "Offerte Geaccepteerd - Schouw Nodig",
    status: "wachten_op_akkoord",
    next: "schouw_controleren",
    action: "Controleer of een schouw nodig is",
    cta: "Schouw Controleren", // CTA voor de knop
    roles: ["sales", "admin", "installateur"],
  },
  {
    title: "Schouw Controleren/Uitvoeren",
    status: "schouw_controleren",
    next: "installatie_inplannen",
    action: "Markeer schouw als voltooid of niet nodig",
    cta: "Schouw Markeren", // CTA voor de knop
    roles: ["installateur", "admin"],
  },
  {
    title: "Installatie Inplannen",
    status: "installatie_inplannen",
    next: "installatie_gepland",
    action: "Plan een datum voor de installatie",
    cta: "Installatie Inplannen", // CTA voor de knop
    roles: ["installateur", "admin"],
  },
  {
    title: "Installatie Gepland",
    status: "installatie_gepland",
    next: "uitgevoerd",
    action: "Voer de installatie uit",
    cta: "Installatie Uitvoeren", // CTA voor de knop
    roles: ["installateur", "admin"],
  },
  {
    title: "Installatie Uitgevoerd - Wachten op Happycall",
    status: "uitgevoerd",
    next: "nazorg",
    action: "Voer een happy call uit",
    cta: "Happy Call Uitvoeren", // CTA voor de knop
    roles: ["sales", "admin"],
  },
  {
    title: "Nazorg/After Sales",
    status: "nazorg",
    next: null,
    action: "Rond de nazorg af en sluit de lead",
    cta: "Nazorg Afronden", // CTA voor de knop
    roles: ["sales", "admin"],
  },
  {
    title: "Afgewezen",
    status: "afgewezen",
    next: "afspraak_gepland",
    action: "Geen actie nodig - klant afgewezen",
    cta: "Bekijk Details", // CTA voor de knop
    roles: ["sales", "admin"],
    modal: {
      name: "handleCreateOffer",
    },
  },
];

const ThuisbatterijProcess = () => {
  const { user } = useUser();
  const [leads, setLeads] = useState([]);
  const [selectedLead, setSelectedLead] = useState(null); // Current lead being edited
  const [isCancelModalOpen, setCancelModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null); // Current modal being shown
  const [isSchouwModalOpen, setSchouwModalOpen] = useState(false);

  const toggleSchouwModal = (lead) => {
    // console.log(lead);
    setSchouwModalOpen(!isSchouwModalOpen);

    if (!isSchouwModalOpen) {
      // console.log("open schouw modal");
      setSelectedLead(lead);
    } else {
      // console.log("close schouw modal");

      setSelectedLead(null);
    }
  };

  const navigate = useNavigate();
  const [agents, setAgents] = useState([]);

  const toggleCancelModal = (lead) => {
    setCancelModalOpen(!isCancelModalOpen);

    if (!isCancelModalOpen) {
      console.log("open cancel modal");
      setSelectedLead(lead);
    } else {
      console.log("close cancel modal");

      setSelectedLead(null);
    }
  };

  useEffect(() => {
    const fetchLeadsAndAgents = async () => {
      try {
        await fetchLeads();

        // Agents ophalen
        const agentsSnap = await getDocs(collection(db, "users"));
        const fetchedAgents = agentsSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setAgents(fetchedAgents);
      } catch (error) {
        console.error("Error fetching leads or agents:", error);
      }
    };

    fetchLeadsAndAgents();
  }, [user.role, user.uid]);

  const fetchLeads = async () => {
    setLeads([]);
    try {
      // Leads ophalen
      let leadsQuery;
      let processQuery;
      if (user.role === "admin") {
        // Original
        //   processQuery = collection(db, "process");
        //   leadsQuery = collection(db, "leads");

        // Testing
        processQuery = query(
          collection(db, "process"),
          where("agent", "==", user.uid)
        );
        leadsQuery = query(
          collection(db, "leads"),
          where("agent", "==", user.uid),
          where("archived", "==", false)
        );
      } else {
        processQuery = query(
          collection(db, "process"),
          where("agent", "==", user.uid)
        );
        leadsQuery = query(
          collection(db, "leads"),
          where("agent", "==", user.uid)
        );
      }
      const leadsSnap = await getDocs(leadsQuery);
      const fetchedLeads = leadsSnap.docs.map((doc) => {
        // console.log(fetchedLeads);
        return {
          id: doc.id,
          table: "leads",
          fase: "new",
          nietBereikt: 0,
          saleAfspraak: {
            datum: null,
            tijd: null,
            type: null,
            opmerkingen: null,
            agent: user,
          },
          ...doc.data(),
        };
      });
      const processSnap = await getDocs(processQuery);

      const fetchedProcess = processSnap.docs.map((doc) => {
        let data = doc.data();
        // console.log(data);

        return {
          id: doc.id,
          table: "process",
          ...doc.data(),
        };
      });

      const sortedFetchedLeads = [...fetchedLeads, ...fetchedProcess].sort(
        (a, b) => {
          return (
            new Date(a.createdAt.toDate()) - new Date(b.createdAt.toDate())
          );
        }
      );

      setLeads(sortedFetchedLeads);
    } catch (error) {
      console.error("Error fetching leads:", error);
    }
  };

  const updateLeadStatus = async (lead, phase, newPhase = null) => {
    const newStatus = newPhase ? newPhase : phase.next;

    if (lead?.table === "leads") {
      lead.fase = newStatus;
      lead.saleAfspraak = {
        datum: null,
        tijd: null,
        type: null,
        opmerkingen: null,
        agent: user,
      };
      lead.status = "appointment";

      console.log(lead);
      try {
        await setDoc(doc(db, "process", lead.id), lead);
        toastHelper.success("Status succesvol bijgewerkt.");
        archiveLead(lead.id);
        fetchLeads();
      } catch (error) {
        console.error("Error updating lead status:", error);
        toastHelper.error(
          "Er is een fout opgetreden bij het bijwerken van de status."
        );
      }
    }
    // try {
    //   const leadRef = doc(db, "process", lead.id); // Update collection name if needed
    //   await updateDoc(leadRef, { status: newStatus });
    //   toastHelper.success("Status succesvol bijgewerkt.");
    //   fetchLeads();
    // } catch (error) {
    //   console.error("Error updating lead status:", error);
    //   toastHelper.error(
    //     "Er is een fout opgetreden bij het bijwerken van de status."
    //   );
    // }
  };

  const openModal = (lead, modalName) => {
    console.log("Open: ", modalName);
    setSelectedLead(lead);

    if (modalName === "handleCreateOffer") {
      console.log("Inside: ", modalName);

      if (["concept", "afgewezen"].includes(lead.status)) {
        navigate(`/process/offerte/${lead.id}`);
      } else {
        handleCreateOffer(lead);
      }

      return;
    }
    if (modalName === "EditConceptoffer") {
      navigate(`/process/offerte/${lead.id}`);
      return;
    }
    if (modalName === "offerteDetails") {
      navigate(`/process/offerte/${lead.id}`);
      return;
    }
    setActiveModal(modalName);
  };

  const closeModal = () => {
    setSelectedLead(null);
    setActiveModal(null);
  };

  const savePhaseOne = async (lead) => {
    if (!lead) return;
    console.log(lead);
    try {
      if (lead?.table === "leads") {
        lead.fase = "afspraak_gepland";
        lead.saleAfspraak.agent = user;
        lead.status = "appointment";

        console.log(lead);
        try {
          await setDoc(doc(db, "process", lead.id), lead);
          toastHelper.success("Status succesvol bijgewerkt.");
          archiveLead(lead.id);
        } catch (error) {
          console.error("Error updating lead status:", error);
          toastHelper.error(
            "Er is een fout opgetreden bij het bijwerken van de status."
          );
        }
      } else {
        const leadRef = doc(db, "process", lead.id);
        await updateDoc(leadRef, lead);
      }

      setLeads((prev) =>
        prev.map((lead) => (lead.id === selectedLead.id ? { ...lead } : lead))
      );
      //   fetchLeads();
      closeModal();
    } catch (error) {
      console.error("Error updating lead:", error);
    }
  };
  //   const saveModalData = async (updatedData) => {
  //     if (!selectedLead) return;
  //     try {
  //       const leadRef = doc(db, "process", selectedLead.id);
  //       await updateDoc(leadRef, updatedData);
  //       setLeads((prev) =>
  //         prev.map((lead) =>
  //           lead.id === selectedLead.id ? { ...lead, ...updatedData } : lead
  //         )
  //       );
  //       closeModal();
  //     } catch (error) {
  //       console.error("Error updating lead:", error);
  //     }
  //   };
  const handleCreateOffer = async (lead) => {
    console.log("inside create offer", lead);
    if (!lead) return;
    lead.status = "concept";
    try {
      navigate("/process/offerte", { state: { lead: lead } });
    } catch (error) {
      console.error("Error creating offer:", error);
      alert("Fout bij het aanmaken van de offerte.");
    }
  };

  const onNoAnswer = async (lead) => {
    let newNumber = lead.nietBereikt + 1;
    console.log(lead, newNumber);
    const res = await addNoAnswer(lead.id, newNumber);
    // fetchLeads();
    if (res) {
      toastHelper.success("Niet bereikt plus 1");
      fetchLeads();
    } else {
      toastHelper.error("Er is iets fout gegaan.");
    }
  };
  const openSchouwForm = async (lead) => {
    toggleSchouwModal(lead);
    // let newNumber = lead.nietBereikt + 1;
    // console.log(lead, newNumber);
    // const res = await addNoAnswer(lead.id, newNumber);
    // // fetchLeads();
    // if (res) {
    //   toastHelper.success("Niet bereikt plus 1");
    //   fetchLeads();
    // } else {
    //   toastHelper.error("Er is iets fout gegaan.");
    // }
  };

  const handleCancelOfferte = async ({ reason, description, lead }) => {
    console.log("Cancellation Reason:", reason);
    console.log("Cancellation Description:", description);
    console.log(lead);
    const newLead = {
      ...lead,
      status: "afgewezen",
      fase: "afgewezen",
      cancelReason: reason,
      cancelDescription: description,
      canceledAt: new Date(),
      updatedAt: new Date(),
    };
    console.log(newLead);
    try {
      // await updateLeadStatus(lead.id, null, "afgewezen");
      const leadRef = doc(db, "process", lead.id); // Update collection name if needed
      await updateDoc(leadRef, newLead);
      // toastHelper.success("Status succesvol bijgewerkt.");
      toastHelper.success("Afwijzen gelukt");
      fetchLeads();
    } catch (err) {
      toastHelper.error(
        "Afwijzen mislukt. Neem contact op met ThuisbatterijExperts"
      );
    }
    // updateLeadStatus(lead, null, "afgewezen");
  };

  return (
    <Container>
      <h3 className="mt-4">Thuisbatterij Workflow</h3>
      <Row>
        {phases
          .filter((phase) => phase.roles.includes(user.role)) // Alleen fases tonen die zichtbaar zijn voor de rol
          .map((phase, index) => {
            if (["new", "lead"].includes(phase.status)) {
              return (
                <Col xs={12} className="mt-4" key={index}>
                  <Card>
                    <CardBody>
                      <h4>{phase.title}</h4>

                      <LeadList
                        leads={leads.filter((lead) =>
                          ["new", "lead"].includes(lead.status)
                        )}
                        // onStatusChange={(leadId) =>
                        //   phase.next && updateLeadStatus(leadId, phase)
                        // }
                        onStatusChange={() =>
                          openModal(
                            leads.find((lead) => lead.fase === phase.status),
                            phase.modal?.name
                          )
                        }
                        onReject={(leadId) =>
                          updateLeadStatus(leadId, phase, "afgewezen")
                        }
                        phase={phase}
                        onNoAnswer={onNoAnswer}
                        openSchouwForm={openSchouwForm}
                      />
                    </CardBody>
                  </Card>
                </Col>
              );
            }
            return (
              <Col xs={12} className="mt-4" key={index}>
                <Card>
                  <CardBody>
                    <h4>{phase.title}</h4>

                    <LeadList
                      leads={leads.filter((lead) => lead.fase === phase.status)}
                      //   onStatusChange={(lead) =>
                      //     phase.next && updateLeadStatus(lead, phase)
                      //   }
                      onStatusChange={() =>
                        openModal(
                          leads.find((lead) => lead.fase === phase.status),
                          phase.modal?.name
                        )
                      }
                      onReject={(lead) => toggleCancelModal(lead)}
                      phase={phase}
                      onNoAnswer={onNoAnswer}
                      openSchouwForm={(lead) => openSchouwForm(lead)}
                    />
                  </CardBody>
                </Card>
              </Col>
            );
          })}
      </Row>
      <PhaseOneModal
        isOpen={activeModal === "PhaseOneModal"}
        onClose={closeModal}
        onSave={savePhaseOne}
        lead={selectedLead}
      />
      <CancelModal
        isOpen={isCancelModalOpen}
        toggle={toggleCancelModal}
        onCancel={(lead) => handleCancelOfferte(lead)}
        lead={selectedLead}
      />
      <SchouwModal
        isOpen={isSchouwModalOpen}
        onClose={() => setSchouwModalOpen(false)}
        onSave={savePhaseOne} // Replace with your save handler
        lead={selectedLead}
      />
    </Container>
  );
};

export default ThuisbatterijProcess;
