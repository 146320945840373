import React from "react";
import Sidebar from "../../components/admin/SideBar";
import AdminContent from "../../components/admin/AdminContent";
import { Routes, Route } from "react-router-dom";
import AdminHeader from "../../components/admin/AdminHeader";
import GraphWidget from "../../components/GraphWidget";
import Dashboard from "../admin/Dashboard";
import AdminOffertesPage from "./Offertes";
import AdminPackagesPage from "./AdminPackagesPage";
import AdminProductsPage from "./AdminProductsPage";
import ProductAddPage from "./ProductAddPage";
import PackageEditPage from "./PackageEdit";
import SingleProductPage from "./SingleProduct";
import BackButton from "../../components/BackButton";
import AdminUsersPage from "./Users";
import { useUser } from "../../context/UserContext";
import AdminMediaPage from "./AdminMediaPage";
import AdminFinancieringsopties from "./FinanceOptions";
import DiscountAdmin from "./DiscountsPage";
import AdminSingleOfferte from "./AdminSingleOfferte";
import AdminGroupsPage from "./AdminGroups";
import AdminLeadsPage from "./AdminLeads";
import AdminFaqPage from "./AdminFaq";
import AdminTasks from "./AdminTasks";
import AdminCategoriesPage from "./AdminProductCategories";
import ProductEditPage from "./ProductEditPage";
import SinglePackagePage from "./SinglePackage";

const AdminPanel = () => {
  const { logout, user } = useUser();
  return (
    <div className="admin-panel">
      <Sidebar />
      <AdminContent>
        <AdminHeader onLogout={logout} user={user} />

        <div className="content-area">
          <Routes>
            {/* Dashboard */}
            <Route path="dashboard" element={<Dashboard />} />

            {/* Offertes */}
            <Route path="offertes" element={<AdminOffertesPage />} />
            <Route path="offertes/:id" element={<AdminSingleOfferte />} />

            {/* Leads and Tasks */}
            <Route path="leads" element={<AdminLeadsPage />} />
            <Route path="taken" element={<AdminTasks />} />

            {/* Products */}
            <Route path="producten" element={<AdminProductsPage />} />
            <Route
              path="producten/add"
              element={<ProductEditPage isEditMode={false} />}
            />
            <Route path="producten/:id" element={<SingleProductPage />} />
            <Route
              path="producten/edit/:id"
              element={<ProductEditPage isEditMode={true} />}
            />
            <Route
              path="producten-categorieen"
              element={<AdminCategoriesPage />}
            />

            {/* Packages */}
            <Route path="pakketten" element={<AdminPackagesPage />} />
            <Route path="pakketten/add" element={<PackageEditPage />} />
            <Route path="pakketten/:id" element={<SinglePackagePage />} />
            <Route path="pakketten/edit/:id" element={<PackageEditPage />} />

            {/* Discounts */}
            <Route path="kortingen" element={<DiscountAdmin />} />

            {/* Users and Groups */}
            <Route path="gebruikers" element={<AdminUsersPage />} />
            <Route path="gebruikers/add" element={<AdminUsersPage />} />
            <Route path="gebruikers/edit/:id" element={<AdminUsersPage />} />
            <Route path="groepen" element={<AdminGroupsPage />} />

            {/* Media */}
            <Route path="media" element={<AdminMediaPage />} />

            {/* FAQs */}
            <Route path="faq" element={<AdminFaqPage />} />

            {/* Financing */}
            <Route
              path="financieringsopties"
              element={<AdminFinancieringsopties />}
            />
          </Routes>
        </div>
      </AdminContent>
    </div>
  );
};

export default AdminPanel;
