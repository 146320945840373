import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Table,
  Input,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db, storage } from "../../firebaseConfig";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AdminFaqPage = () => {
  const [faqs, setFaqs] = useState([]);
  const [categories, setCategories] = useState([]);
  const [filteredFaqs, setFilteredFaqs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentFaq, setCurrentFaq] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 6; // Items per page
  const [newFaq, setNewFaq] = useState({
    question: "",
    summary: "",
    answer: "",
    category: "",
    pinned: false,
    image: "",
    youtube: "",
  });
  const [newCategory, setNewCategory] = useState("");
  const [imageFile, setImageFile] = useState(null);

  useEffect(() => {
    const fetchFaqsAndCategories = async () => {
      try {
        const faqSnap = await getDocs(collection(db, "faqs"));
        const fetchedFaqs = faqSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const uniqueCategories = [
          ...new Set(fetchedFaqs.map((faq) => faq.category)),
        ];

        setFaqs(fetchedFaqs);
        setFilteredFaqs(fetchedFaqs);
        setCategories(uniqueCategories);
      } catch (error) {
        console.error("Error fetching FAQs:", error);
      }
    };

    fetchFaqsAndCategories();
  }, []);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
    if (modalOpen) {
      setEditMode(false);
      setCurrentFaq(null);
      setNewFaq({
        question: "",
        summary: "",
        answer: "",
        category: "",
        pinned: false,
        image: "",
        youtube: "",
      });
      setImageFile(null);
      setNewCategory("");
    }
  };

  const handleImageUpload = async () => {
    if (!imageFile) return "";
    try {
      const imageRef = ref(storage, `faq_images/${imageFile.name}`);
      await uploadBytes(imageRef, imageFile);
      const downloadURL = await getDownloadURL(imageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image:", error);
      return "";
    }
  };

  const handleSaveFaq = async () => {
    const category = newFaq.category === "new" ? newCategory : newFaq.category;
    const imageUrl = await handleImageUpload();

    const faqData = {
      ...newFaq,
      category,
      image: imageUrl || newFaq.image,
    };

    try {
      if (editMode && currentFaq) {
        const faqRef = doc(db, "faqs", currentFaq.id);
        await updateDoc(faqRef, faqData);
        setFaqs((prevFaqs) =>
          prevFaqs.map((faq) =>
            faq.id === currentFaq.id ? { ...faq, ...faqData } : faq
          )
        );
      } else {
        const docRef = await addDoc(collection(db, "faqs"), faqData);
        setFaqs((prevFaqs) => [...prevFaqs, { id: docRef.id, ...faqData }]);
      }

      setFilteredFaqs((prevFaqs) => [...prevFaqs, faqData]);
      toggleModal();
    } catch (error) {
      console.error("Error saving FAQ:", error);
    }
  };

  const handleEditFaq = (faq) => {
    setEditMode(true);
    setCurrentFaq(faq);
    setNewFaq(faq);
    toggleModal();
  };

  const handleDeleteFaq = async (faqId) => {
    try {
      const faqRef = doc(db, "faqs", faqId);
      await deleteDoc(faqRef);
      setFaqs((prevFaqs) => prevFaqs.filter((faq) => faq.id !== faqId));
      setFilteredFaqs((prevFaqs) => prevFaqs.filter((faq) => faq.id !== faqId));
    } catch (error) {
      console.error("Error deleting FAQ:", error);
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = faqs.filter(
      (faq) =>
        faq.question.toLowerCase().includes(query.toLowerCase()) ||
        faq.summary.toLowerCase().includes(query.toLowerCase()) ||
        faq.answer.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredFaqs(filtered);
    setCurrentPage(1); // Reset to the first page on search
  };

  const totalItems = filteredFaqs.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const currentItems = filteredFaqs.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  console.log(faqs);
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mt-4">
        <h3>FAQ Beheer</h3>
        <Button color="primary" onClick={toggleModal}>
          Nieuwe FAQ
        </Button>
      </div>

      <Input
        type="text"
        className="mt-3"
        placeholder="Zoek naar een vraag of antwoord..."
        value={searchQuery}
        onChange={(e) => handleSearch(e.target.value)}
      />

      <Row className="mt-4">
        <Col>
          <Card>
            <CardBody>
              <Table bordered>
                <thead>
                  <tr>
                    <th>Vraag</th>
                    <th>Samenvatting</th>
                    <th>Categorie</th>
                    <th>Acties</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.length > 0 ? (
                    currentItems.map((faq) => (
                      <tr key={faq.id}>
                        <td>{faq.question}</td>
                        <td>{faq.summary}</td>
                        <td>{faq.category}</td>
                        <td>
                          <Button
                            color="warning"
                            size="sm"
                            className="mr-2"
                            onClick={() => handleEditFaq(faq)}
                          >
                            Bewerken
                          </Button>
                          <Button
                            color="danger"
                            size="sm"
                            onClick={() => handleDeleteFaq(faq.id)}
                          >
                            Verwijderen
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        Geen resultaten gevonden
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {/* Pagination */}
              {totalPages > 1 && (
                <Pagination className="mt-3 justify-content-center">
                  {[...Array(totalPages).keys()].map((page) => (
                    <PaginationItem
                      key={page}
                      active={currentPage === page + 1}
                    >
                      <PaginationLink
                        onClick={() => handlePageChange(page + 1)}
                      >
                        {page + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                </Pagination>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Modal isOpen={modalOpen} toggle={toggleModal}>
        <ModalHeader toggle={toggleModal}>
          {editMode ? "FAQ Bewerken" : "Nieuwe FAQ"}
        </ModalHeader>
        <ModalBody>
          <Label>Vraag</Label>
          <Input
            type="text"
            value={newFaq.question}
            onChange={(e) => setNewFaq({ ...newFaq, question: e.target.value })}
          />

          <Label className="mt-3">Samenvatting</Label>
          <Input
            type="textarea"
            value={newFaq.summary}
            onChange={(e) => setNewFaq({ ...newFaq, summary: e.target.value })}
          />

          <Label className="mt-3">Antwoord</Label>
          <ReactQuill
            theme="snow"
            value={newFaq.answer}
            onChange={(value) => setNewFaq({ ...newFaq, answer: value })}
          />

          <Label className="mt-3">Categorie</Label>
          <Input
            type="select"
            value={newFaq.category}
            onChange={(e) => setNewFaq({ ...newFaq, category: e.target.value })}
          >
            <option value="">Selecteer een categorie</option>
            {categories.map((category) => (
              <option key={category} value={category}>
                {category}
              </option>
            ))}
            <option value="new">Nieuwe categorie</option>
          </Input>

          {newFaq.category === "new" && (
            <Input
              type="text"
              placeholder="Nieuwe categorie"
              className="mt-2"
              value={newCategory}
              onChange={(e) => setNewCategory(e.target.value)}
            />
          )}

          <Label className="mt-3">Afbeelding</Label>
          <Input
            type="file"
            onChange={(e) => setImageFile(e.target.files[0])}
          />

          <Label className="mt-3">YouTube URL</Label>
          <Input
            type="text"
            value={newFaq.youtube}
            onChange={(e) => setNewFaq({ ...newFaq, youtube: e.target.value })}
          />

          <Label className="mt-3">Gepind</Label>
          <Input
            type="checkbox"
            checked={newFaq.pinned}
            onChange={(e) => setNewFaq({ ...newFaq, pinned: e.target.checked })}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={handleSaveFaq}>
            Opslaan
          </Button>
          <Button color="secondary" onClick={toggleModal}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AdminFaqPage;
