import React from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { UserProvider, useUser } from "./context/UserContext"; // Import UserContext
import Login from "./pages/Login";
import Dashboard from "./pages/admin/Dashboard";
import Rekentool from "./pages/Offerte";
import RekentoolProcess from "./pages/OfferteProcess";
import Header from "./components/Header";
import StartPage from "./pages/StartPage";
import "./App.css";
import ProductPage from "./pages/ProductPage";
import SingleProductPage from "./pages/SingleProduct";
import ProductAddPage from "./pages/admin/ProductAddPage";
import ProductsPage from "./pages/ProductsPage";
import ProductEditPage from "./pages/admin/ProductEditPage";
import PackagesPage from "./pages/Packages";
import PackageEditPage from "./pages/admin/PackageEdit";
import OffertesOverview from "./pages/OffertesOverview";
import SingleOffertePage from "./pages/SingleOffertePage";
import EditOffertePage from "./pages/OfferteEdit";
import AdminPanel from "./pages/admin/AdminPanel";
import TakenPage from "./pages/Taken";
import AgendaPage from "./pages/AgendaPage";
import CustomerOfferPage from "./pages/Customer/CustomerOfferPage";
import LeadsPage from "./pages/LeadsPage";
import MediaPage from "./pages/MediaPage";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import useIdleLogout from "./components/admin/useIdleLogout";
import { auth } from "./firebaseConfig";
import { DataProvider } from "./context/DataContext";
import FaqPage from "./pages/Faq";
import BatteryCalculator from "./pages/Calculator";
import ThuisbatterijProcess from "./pages/Process";
import ProcessOffertePage from "./pages/ProcessOffertePage";
import ProcessOfferteEdit from "./pages/ProcessOfferteEdit";

const AppContent = () => {
  const { user, loading, logout } = useUser();
  const location = useLocation();
  // const showWarning = useIdleLogout(auth);

  // Check if the current route is an admin route
  const isAdminRoute = location.pathname.startsWith("/admin");

  if (loading) {
    return <div className="loading">Laden...</div>;
  }
  return (
    <div className="main">
      {/* {showWarning && (
        <div className="warning">
          <p>U wordt over 30 seconden uitgelogd vanwege inactiviteit.</p>
        </div>
      )} */}
      {user ? (
        <>
          {isAdminRoute ? <></> : <Header onLogout={logout} user={user} />}
          <Routes>
            <Route path="/" element={<StartPage />} />
            <Route path="/process" element={<ThuisbatterijProcess />} />
            <Route path="/process/offerte" element={<RekentoolProcess />} />
            <Route
              path="/process/edit/offerte/:id"
              element={<ProcessOfferteEdit />}
            />

            <Route
              path="/process/offerte/:id"
              element={<ProcessOffertePage />}
            />

            <Route path="/start" element={<StartPage />} />
            <Route path="/offerte" element={<Rekentool />} />
            <Route path="/products" element={<ProductsPage />} />

            <Route path="/add/product" element={<ProductAddPage />} />
            <Route path="/edit/product/:id" element={<ProductEditPage />} />
            <Route
              path="/products/:productId"
              element={<SingleProductPage />}
            />
            <Route path="/packages" element={<PackagesPage />} />
            <Route path="/add-package" element={<PackageEditPage />} />
            <Route path="/edit-package/:id" element={<PackageEditPage />} />

            <Route path="/offertes" element={<OffertesOverview />} />
            <Route path="/offertes/:id" element={<SingleOffertePage />} />
            <Route path="/offertes/edit/:id" element={<EditOffertePage />} />

            <Route path="/leads" element={<LeadsPage />} />
            <Route path="/taken" element={<TakenPage />} />
            <Route path="/agenda" element={<AgendaPage />} />
            <Route path="/media" element={<MediaPage />} />
            <Route path="/faq" element={<FaqPage />} />
            {/* <Route path="/calculator" element={<BatteryCalculator />} /> */}

            {/* Admin Routes */}
            <Route path="/admin/*" element={<AdminPanel />} />

            {/* Redirect any undefined admin route to dashboard */}
            <Route path="/admin" element={<Navigate to="/admin/dashboard" />} />
            <Route
              path="/public/offerte/:offerId/:passcode"
              element={<CustomerOfferPage />}
            />
          </Routes>
        </>
      ) : (
        <Routes>
          <Route
            path="/public/offerte/:offerId/:passcode"
            element={<CustomerOfferPage />}
          />
          <Route path="*" element={<Login />} />
        </Routes>
      )}
    </div>
  );
};

const App = () => (
  <UserProvider>
    <DataProvider>
      <AppContent />
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </DataProvider>
  </UserProvider>
);

export default App;
