import React, { useState, useEffect } from "react";
import { FaCheckCircle, FaCog, FaFileAlt, FaBolt } from "react-icons/fa";
import "./AnimatedOverlay.css";

const AnimatedProgressFlow = ({ onComplete }) => {
  const messages = [
    {
      icon: <FaCog />,
      message: "We verwerken uw gegevens...",
    },
    {
      icon: <FaBolt />,
      message: "Uw configuratie wordt geoptimaliseerd...",
    },
    {
      icon: <FaFileAlt />,
      message: "De prestaties van uw thuisbatterij worden gecontroleerd...",
    },
    {
      icon: <FaCog />,
      message: "Uw offerte wordt voorbereid, nog een momentje...",
    },
    {
      icon: <FaCheckCircle />,
      message: "Gefeliciteerd! Uw unieke thuisbatterij is klaar voor gebruik.",
    },
  ];

  const [currentIndex, setCurrentIndex] = useState(0);
  const [progress, setProgress] = useState(new Array(messages.length).fill(0));
  const [timestamps, setTimestamps] = useState(
    new Array(messages.length).fill("Nog niet begonnen")
  );
  const [isComplete, setIsComplete] = useState(false);

  useEffect(() => {
    if (currentIndex < messages.length) {
      const stepDuration = Math.random() * (4000 - 2000) + 2000; // Random duration between 2-4 seconds
      let progressInterval;

      const startProgress = () => {
        progressInterval = setInterval(() => {
          setProgress((prevProgress) => {
            const updated = [...prevProgress];
            if (updated[currentIndex] < 100) {
              updated[currentIndex] += 5; // Increment progress by 5%
            }
            return updated;
          });
        }, stepDuration / 20);
      };

      startProgress();

      const completeStep = setTimeout(() => {
        clearInterval(progressInterval);

        // Mark the progress bar as full
        setProgress((prevProgress) => {
          const updated = [...prevProgress];
          updated[currentIndex] = 100;
          return updated;
        });

        // Add a timestamp to the step
        setTimestamps((prev) => {
          const updated = [...prev];
          updated[currentIndex] = new Date().toLocaleTimeString("nl-NL");
          return updated;
        });

        if (currentIndex < messages.length - 1) {
          setCurrentIndex((prevIndex) => prevIndex + 1);
        } else {
          setIsComplete(true);
        }
      }, stepDuration);

      return () => {
        clearInterval(progressInterval);
        clearTimeout(completeStep);
      };
    }
  }, [currentIndex]);

  const handleComplete = () => {
    onComplete();
  };

  return (
    <div className="overlay">
      <div className="progress-flow-container">
        <ul className="timeline">
          {messages.map((step, index) => (
            <li
              key={index}
              className={`timeline-item ${
                index <= currentIndex ? "active" : ""
              }`}
            >
              <div className="icon-container">{step.icon}</div>
              <div className="message-container">
                <span className="timestamp">
                  {timestamps[index] !== "Nog niet begonnen"
                    ? timestamps[index]
                    : index <= currentIndex
                    ? "Bezig..."
                    : "Nog niet begonnen"}
                </span>
                <p>{step.message}</p>
                {index <= currentIndex && (
                  <div className="progress-bar">
                    <div
                      className="progress"
                      style={{
                        width: `${progress[index]}%`,
                        backgroundColor: "#FFA500", // Soft orange
                      }}
                    />
                  </div>
                )}
              </div>
            </li>
          ))}
        </ul>
        {isComplete && (
          <button className="complete-button" onClick={handleComplete}>
            Voltooien
          </button>
        )}
      </div>
    </div>
  );
};

export default AnimatedProgressFlow;
