import React, { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  addDoc,
  updateDoc,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { db } from "../../firebaseConfig";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  CustomInput,
} from "reactstrap";

const AdminFinancieringsopties = () => {
  const [options, setOptions] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [currentOptionId, setCurrentOptionId] = useState(null);
  const [newOption, setNewOption] = useState({
    name: "",
    description: "",
    requiresAgreement: false,
    paymentCondition: "",
    financingParties: [],
  });

  const financingPartiesList = [
    { id: "greenloans", name: "GreenLoans" },
    { id: "warmtefonds", name: "Warmtefonds (max €8500 incl. BTW)" },
  ];

  // Fetch options from Firestore
  useEffect(() => {
    const fetchOptions = async () => {
      const optionsRef = collection(db, "financieringsopties");
      const optionsSnapshot = await getDocs(optionsRef);
      const fetchedOptions = optionsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setOptions(fetchedOptions);
    };
    fetchOptions();
  }, []);

  // Add or update a financing option
  const handleSaveOption = async () => {
    if (
      !newOption.name ||
      !newOption.description ||
      !newOption.paymentCondition
    ) {
      alert("Alle velden zijn verplicht!");
      return;
    }

    if (editMode) {
      // Update existing option
      const optionRef = doc(db, "financieringsopties", currentOptionId);
      await updateDoc(optionRef, newOption);
      alert("Optie bijgewerkt!");
    } else {
      // Add new option
      await addDoc(collection(db, "financieringsopties"), newOption);
      alert("Nieuwe optie toegevoegd!");
    }

    setModalOpen(false);
    setNewOption({
      name: "",
      description: "",
      requiresAgreement: false,
      paymentCondition: "",
      financingParties: [],
    });
    setEditMode(false);
    setCurrentOptionId(null);
  };

  // Open modal in edit mode
  const handleEditOption = (option) => {
    setNewOption(option);
    setEditMode(true);
    setCurrentOptionId(option.id);
    setModalOpen(true);
  };

  // Delete a financing option
  const handleDeleteOption = async (id) => {
    if (window.confirm("Weet u zeker dat u deze optie wilt verwijderen?")) {
      const optionRef = doc(db, "financieringsopties", id);
      await deleteDoc(optionRef);
      alert("Optie verwijderd!");
      setOptions((prev) => prev.filter((option) => option.id !== id));
    }
  };

  // Toggle financing party selection
  const toggleFinancingParty = (partyId) => {
    setNewOption((prev) => ({
      ...prev,
      financingParties: prev.financingParties.includes(partyId)
        ? prev.financingParties.filter((id) => id !== partyId)
        : [...prev.financingParties, partyId],
    }));
  };

  return (
    <div className="admin-financieringsopties">
      <h2>Financieringsopties</h2>
      <Button color="primary" onClick={() => setModalOpen(true)}>
        Nieuwe Optie Toevoegen
      </Button>
      <Table striped>
        <thead>
          <tr>
            <th>Naam</th>
            <th>Betalingsconditie</th>
            <th>Financieringspartijen</th>
            <th>Acties</th>
          </tr>
        </thead>
        <tbody>
          {options.map((option) => (
            <tr key={option.id}>
              <td>{option.name}</td>
              <td>{option.paymentCondition}</td>
              <td>
                {option.financingParties?.map((id, i) => {
                  let name = id;

                  if (name.includes("Warmtefonds")) name = "Warmtefonds";
                  return (
                    <span key={i} className="pill">
                      {name}
                    </span>
                  );
                })}
              </td>
              <td>
                <Button
                  color="warning"
                  size="sm"
                  onClick={() => handleEditOption(option)}
                >
                  Bewerken
                </Button>{" "}
                <Button
                  color="danger"
                  size="sm"
                  onClick={() => handleDeleteOption(option.id)}
                >
                  Verwijderen
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)}>
        <ModalHeader>
          {editMode
            ? "Financieringsoptie Bewerken"
            : "Nieuwe Financieringsoptie"}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label for="name">Naam:</Label>
            <Input
              type="text"
              id="name"
              value={newOption.name}
              onChange={(e) =>
                setNewOption({ ...newOption, name: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="description">Beschrijving:</Label>
            <Input
              type="textarea"
              id="description"
              value={newOption.description}
              onChange={(e) =>
                setNewOption({ ...newOption, description: e.target.value })
              }
            />
          </FormGroup>
          <FormGroup>
            <Label for="paymentCondition">Betalingsconditie:</Label>
            <Input
              type="select"
              id="paymentCondition"
              value={newOption.paymentCondition}
              onChange={(e) =>
                setNewOption({ ...newOption, paymentCondition: e.target.value })
              }
            >
              <option value="">Selecteer een optie</option>
              <option value="Volledig eigen middelen">
                Volledig eigen middelen
              </option>
              <option value="50% eigen middelen en 50% financieren">
                50% eigen middelen en 50% financieren
              </option>
              <option value="100% financieren">100% financieren</option>
            </Input>
          </FormGroup>
          <FormGroup>
            <Label>Financieringspartijen:</Label>
            {financingPartiesList.map((party) => (
              <div key={party.id} className="mb-2">
                <Input
                  type="checkbox"
                  id={party.id}
                  checked={newOption.financingParties.includes(party.id)}
                  onChange={() => toggleFinancingParty(party.id)}
                />
                <Label for={party.id} className="ml-2">
                  {party.name}
                </Label>
              </div>
            ))}
          </FormGroup>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={newOption.requiresAgreement}
                onChange={() =>
                  setNewOption({
                    ...newOption,
                    requiresAgreement: !newOption.requiresAgreement,
                  })
                }
              />
              Akkoord Vereist
            </Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={handleSaveOption}>
            {editMode ? "Opslaan" : "Toevoegen"}
          </Button>
          <Button color="secondary" onClick={() => setModalOpen(false)}>
            Annuleren
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdminFinancieringsopties;
