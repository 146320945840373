// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

// Initialize Firebase App
const app = initializeApp(firebaseConfig);

// Export necessary Firebase services
const auth = getAuth(app); // For authentication
const db = getFirestore(app); // For Firestore database
const storage = getStorage(app); // For Firebase storage

const fetchLeads = async (user) => {
  let leads = [];

  let leadsQuery;
  if (user?.role === "admin") {
    leadsQuery = collection(db, "leads");
  } else {
    leadsQuery = query(
      collection(db, "leads"),
      where("agent", "==", user?.uid)
    );
  }
  const leadsSnap = await getDocs(leadsQuery);

  const fetchedLeads = leadsSnap.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));

  return fetchedLeads;
};

const fetchOffertes = async (user) => {
  try {
    let offertesQuery;

    if (user?.role === "admin") {
      // Admin sees all offertes
      offertesQuery = collection(db, "offertes");
    } else {
      // Non-admin sees only their offertes
      offertesQuery = query(
        collection(db, "offertes"),
        where("agent", "==", user?.uid)
      );
    }

    const offertesSnap = await getDocs(offertesQuery);
    const fetchedOffertes = offertesSnap.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return fetchedOffertes;
  } catch (error) {
    console.error("Error fetching offertes:", error);
  }
};

const updateOfferteStatus = async (offerteId, status, additionalData = {}) => {
  const offerteRef = doc(db, "offertes", offerteId);

  try {
    // Prepare the update object
    const updateData = {
      status,
      updatedAt: new Date(), // Update the timestamp
      ...additionalData, // Add any additional fields if provided
    };

    // Update the document in Firestore
    await updateDoc(offerteRef, updateData);

    console.log(`Offerte ${offerteId} updated to status: ${status}`);
  } catch (error) {
    console.error(`Error updating offerte ${offerteId} status:`, error);
    throw error; // Re-throw to handle errors in the calling function
  }
};

const updateOfferteStatusProcess = async (
  offerteId,
  status,
  additionalData = {}
) => {
  const offerteRef = doc(db, "process", offerteId);

  try {
    // Prepare the update object
    const updateData = {
      status,
      updatedAt: new Date(), // Update the timestamp
      ...additionalData, // Add any additional fields if provided
    };

    // Update the document in Firestore
    await updateDoc(offerteRef, updateData);

    console.log(`Offerte ${offerteId} updated to status: ${status}`);
  } catch (error) {
    console.error(`Error updating offerte ${offerteId} status:`, error);
    throw error; // Re-throw to handle errors in the calling function
  }
};

const fetchFinancingOptions = async () => {
  try {
    const optionsRef = collection(db, "financieringsopties");
    const querySnapshot = await getDocs(optionsRef);
    const fetchedOptions = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return fetchedOptions;
  } catch (error) {
    console.error("Error fetching financing options:", error);
  }
};

// Function to save or update a task
const saveTask = async (task) => {
  try {
    const tasksRef = collection(db, "tasks");

    if (task.id) {
      // If task has an ID, update the existing document
      const taskRef = doc(tasksRef, task.id);
      await updateDoc(taskRef, {
        updatedAt: serverTimestamp(),
        ...task,
      });
      console.log(`Task ${task.id} successfully updated.`);
    } else {
      // If task does not have an ID, create a new document
      const newTaskRef = await addDoc(tasksRef, {
        ...task,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      console.log(`New task created with ID: ${newTaskRef.id}`);
    }
  } catch (error) {
    console.error("Error saving/updating task:", error);
    throw error;
  }
};

const fetchOfferteById = async (offerteId) => {
  try {
    const offerteRef = doc(db, "offertes", offerteId);
    const offerteSnap = await getDoc(offerteRef);

    if (offerteSnap.exists()) {
      return { id: offerteSnap.id, ...offerteSnap.data() };
    } else {
      // console.error(`No offerte found with ID: ${offerteId}`);
      return null; // Return null if no offerte exists with the provided ID
    }
  } catch (error) {
    console.error(`Error fetching offerte with ID ${offerteId}:`, error);
    throw error; // Re-throw to handle errors in the calling function
  }
};

// Function to save or update a task
const saveGroup = async (group) => {
  try {
    const groupsRef = collection(db, "groups");

    if (group.id) {
      // If task has an ID, update the existing document
      const groupRef = doc(groupsRef, group.id);
      await updateDoc(groupRef, {
        updatedAt: serverTimestamp(),
        ...group,
      });
      console.log(`Task ${group.id} successfully updated.`);
    } else {
      // If task does not have an ID, create a new document
      const newGroupRef = await addDoc(groupsRef, {
        ...group,
        createdAt: serverTimestamp(),
        updatedAt: serverTimestamp(),
      });
      console.log(`New task created with ID: ${newGroupRef.id}`);
    }
  } catch (error) {
    console.error("Error saving/updating task:", error);
    throw error;
  }
};
const getGroupById = async (groupId) => {
  try {
    const groupRef = doc(db, "groups", groupId);
    const groupSnap = await getDoc(groupRef);

    if (groupSnap.exists()) {
      return { id: groupSnap.id, ...groupSnap.data() };
    } else {
      // console.error(`No offerte found with ID: ${groupId}`);
      return null; // Return null if no offerte exists with the provided ID
    }
  } catch (error) {
    console.error(`Error fetching group with ID ${groupId}:`, error);
    throw error; // Re-throw to handle errors in the calling function
  }
};

const getAgents = async () => {
  try {
    const optionsRef = collection(db, "users");
    const querySnapshot = await getDocs(optionsRef);
    const fetchedOptions = querySnapshot.docs.map((doc) => {
      let data = doc.data();
      // console.log(data);
      return {
        id: doc.id,
        ...doc.data(),
      };
    });
    return fetchedOptions;
  } catch (error) {
    console.error("Error fetching financing options:", error);
  }
};

const archiveLead = async (leadId) => {
  try {
    const tasksRef = collection(db, "leads");
    const taskRef = doc(tasksRef, leadId);
    await updateDoc(taskRef, {
      updatedAt: serverTimestamp(),
      archived: true,
    });
  } catch (error) {
    console.error("Error archiving lead:", error);
    throw error;
  }
};

const addNoAnswer = async (leadId, newNumber) => {
  try {
    const tasksRef = collection(db, "leads");
    const taskRef = doc(tasksRef, leadId);
    await updateDoc(taskRef, {
      updatedAt: serverTimestamp(),
      nietBereikt: newNumber,
    });
    return true;
  } catch (error) {
    console.error("Error archiving lead:", error);
    return false;
  }
};

export {
  auth,
  db,
  storage,
  getAgents,
  fetchLeads,
  fetchOffertes,
  updateOfferteStatus,
  fetchFinancingOptions,
  saveTask,
  fetchOfferteById,
  saveGroup,
  getGroupById,
  archiveLead,
  addNoAnswer,
  updateOfferteStatusProcess,
};
