import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  Label,
  Badge,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
import { collection, getDocs, doc, updateDoc } from "firebase/firestore";
import { db, saveGroup } from "../firebaseConfig";

const CreateGroupModal = ({
  isOpen,
  toggle,
  closeEditGroup,
  onGroupUpdated,
  onGroupCreated,
  groupToEdit,
}) => {
  const [groupName, setGroupName] = useState("");
  const [percentage, setPercentage] = useState(100); // Percentage slider value
  const [adminSearchQuery, setAdminSearchQuery] = useState("");
  const [userSearchQuery, setUserSearchQuery] = useState("");
  const [users, setUsers] = useState([]);
  const [filteredAdmins, setFilteredAdmins] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [groupAdmins, setGroupAdmins] = useState([]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersSnap = await getDocs(collection(db, "users"));
        const allUsers = usersSnap.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(allUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    if (groupToEdit) {
      setGroupName(groupToEdit.name);
      setPercentage(groupToEdit.percentage);
      setSelectedUsers(groupToEdit.users || []);
      setGroupAdmins(groupToEdit.groupAdmins || []);
    } else {
      setGroupName("");
      setPercentage(100);
      setSelectedUsers([]);
      setGroupAdmins([]);
    }
  }, [groupToEdit]);

  useEffect(() => {
    if (adminSearchQuery.trim() === "") {
      setFilteredAdmins([]);
    } else {
      const filtered = users.filter(
        (user) =>
          (user.personalInfo?.firstName
            ?.toLowerCase()
            .includes(adminSearchQuery.toLowerCase()) ||
            user.personalInfo?.lastName
              ?.toLowerCase()
              .includes(adminSearchQuery.toLowerCase()) ||
            user.email
              ?.toLowerCase()
              .includes(adminSearchQuery.toLowerCase())) &&
          !groupAdmins.some((admin) => admin.id === user.id)
      );
      setFilteredAdmins(filtered);
    }
  }, [adminSearchQuery, users, groupAdmins]);

  useEffect(() => {
    if (userSearchQuery.trim() === "") {
      setFilteredUsers([]);
    } else {
      const filtered = users.filter(
        (user) =>
          (user.personalInfo?.firstName
            ?.toLowerCase()
            .includes(userSearchQuery.toLowerCase()) ||
            user.personalInfo?.lastName
              ?.toLowerCase()
              .includes(userSearchQuery.toLowerCase()) ||
            user.email
              ?.toLowerCase()
              .includes(userSearchQuery.toLowerCase())) &&
          !selectedUsers.some((selected) => selected.id === user.id)
      );
      setFilteredUsers(filtered);
    }
  }, [userSearchQuery, users, selectedUsers]);

  const handleAdminSelect = (user) => {
    if (!groupAdmins.some((admin) => admin.id === user.id)) {
      setGroupAdmins([...groupAdmins, user]);
    }
    setAdminSearchQuery("");
    setFilteredAdmins([]);
  };

  const handleUserSelect = (user) => {
    if (!selectedUsers.some((u) => u.id === user.id)) {
      setSelectedUsers([...selectedUsers, user]);
    }
    setUserSearchQuery("");
    setFilteredUsers([]);
  };

  const handleAdminRemove = (userId) => {
    setGroupAdmins(groupAdmins.filter((admin) => admin.id !== userId));
  };

  const handleUserRemove = (userId) => {
    setSelectedUsers(selectedUsers.filter((user) => user.id !== userId));
  };

  const updateUserGroup = async (userId, groupId) => {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { groupId });
  };

  const updateAdminRole = async (adminId) => {
    const adminRef = doc(db, "users", adminId);
    await updateDoc(adminRef, { role: "groupAdmin" });
  };

  const handleSaveGroup = async () => {
    if (!groupName.trim()) {
      alert("Groepsnaam is verplicht!");
      return;
    }

    const groupData = {
      name: groupName,
      percentage,
      users: selectedUsers,
      groupAdmins,
      updatedAt: new Date(),
    };

    if (groupToEdit?.id) {
      groupData.id = groupToEdit?.id;
    }
    console.log(groupData);
    await saveGroup(groupData);

    for (const user of selectedUsers) {
      await updateUserGroup(user.id, groupData.id || groupToEdit?.id);
    }

    for (const admin of groupAdmins) {
      await updateAdminRole(admin.id);
    }

    if (groupToEdit) {
      onGroupUpdated();
    } else {
      onGroupCreated();
    }

    setGroupName("");
    setPercentage(100);
    setSelectedUsers([]);
    setGroupAdmins([]);
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} size="lg">
      <ModalHeader toggle={toggle}>
        {groupToEdit ? "Groep Bewerken" : "Nieuwe Groep Aanmaken"}
      </ModalHeader>
      <ModalBody>
        <Label for="groupName">Groepsnaam</Label>
        <Input
          type="text"
          id="groupName"
          value={groupName}
          onChange={(e) => setGroupName(e.target.value)}
          placeholder="Voer groepsnaam in..."
        />

        <Label for="percentage" className="mt-3">
          Percentage
        </Label>
        <div className="d-flex align-items-center">
          <Input
            type="range"
            id="percentage"
            value={percentage}
            onChange={(e) => setPercentage(Number(e.target.value))}
            min="92"
            max="120"
            step="1"
            className="form-range"
          />
          <span className="ms-3">{percentage}%</span>
        </div>

        <Label className="mt-3">Groepsbeheerders</Label>
        <div className="selected-users">
          {groupAdmins.map((admin) => (
            <Badge
              key={admin.id}
              color="success"
              className="me-2 mb-2"
              pill
              style={{ cursor: "pointer" }}
              onClick={() => handleAdminRemove(admin.id)}
            >
              {admin.personalInfo?.firstName} {admin.personalInfo?.lastName} ✕
            </Badge>
          ))}
        </div>

        <Label for="adminSearch" className="mt-3">
          Beheerders Zoeken
        </Label>
        <Input
          type="text"
          id="adminSearch"
          value={adminSearchQuery}
          onChange={(e) => setAdminSearchQuery(e.target.value)}
          placeholder="Zoek op naam of e-mail..."
        />

        {filteredAdmins.length > 0 && (
          <ListGroup className="mt-2">
            {filteredAdmins.map((user) => (
              <ListGroupItem
                key={user.id}
                onClick={() => handleAdminSelect(user)}
                style={{ cursor: "pointer" }}
              >
                {user.personalInfo?.firstName} {user.personalInfo?.lastName} -{" "}
                {user.email}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}

        <Label className="mt-3">Geselecteerde Gebruikers</Label>
        <div className="selected-users">
          {selectedUsers.map((user) => (
            <Badge
              key={user.id}
              color="primary"
              className="me-2 mb-2"
              pill
              style={{ cursor: "pointer" }}
              onClick={() => handleUserRemove(user.id)}
            >
              {user.personalInfo?.firstName} {user.personalInfo?.lastName} ✕
            </Badge>
          ))}
        </div>

        <Label for="userSearch" className="mt-3">
          Gebruikers Zoeken
        </Label>
        <Input
          type="text"
          id="userSearch"
          value={userSearchQuery}
          onChange={(e) => setUserSearchQuery(e.target.value)}
          placeholder="Zoek op naam of e-mail..."
        />

        {filteredUsers.length > 0 && (
          <ListGroup className="mt-2">
            {filteredUsers.map((user) => (
              <ListGroupItem
                key={user.id}
                onClick={() => handleUserSelect(user)}
                style={{ cursor: "pointer" }}
              >
                {user.personalInfo?.firstName} {user.personalInfo?.lastName} -{" "}
                {user.email}
              </ListGroupItem>
            ))}
          </ListGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={handleSaveGroup}>
          {groupToEdit ? "Opslaan" : "Groep Aanmaken"}
        </Button>
        <Button color="secondary" onClick={toggle}>
          Annuleren
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CreateGroupModal;
