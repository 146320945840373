import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";

const PackageModal = ({ packageItem, onClose, onSelect }) => {
  const requiredProducts = packageItem.producten?.filter((product) => {
    return product.type === "required";
  });

  return (
    <Modal isOpen toggle={onClose}>
      <ModalHeader toggle={onClose}>{packageItem.naam}</ModalHeader>
      <ModalBody>
        {packageItem.images?.length > 0 && (
          <>
            <h5>Afbeeldingen</h5>
            <div className="images">
              {packageItem.images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Package Image ${index + 1}`}
                  style={{
                    width: "100%",
                    marginBottom: "10px",
                    borderRadius: "8px",
                  }}
                />
              ))}
            </div>
          </>
        )}
        <h5>Beschrijving</h5>
        <p
          dangerouslySetInnerHTML={{
            __html:
              packageItem.beschrijving || "Geen beschrijving beschikbaar.",
          }}
        ></p>

        {requiredProducts?.length > 0 && (
          <>
            <h5>Vereiste Producten</h5>
            <ul>
              {requiredProducts.map((product) => (
                <li key={product.productId}>
                  {product.naam || "Naam onbekend"}
                </li>
              ))}
            </ul>
          </>
        )}

        {packageItem.datasheets?.length > 0 && (
          <>
            <h5>Datasheets</h5>
            <ul>
              {packageItem.datasheets.map((datasheet, index) => (
                <li key={index}>
                  <a href={datasheet} target="_blank" rel="noopener noreferrer">
                    Datasheet {index + 1}
                  </a>
                </li>
              ))}
            </ul>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={onSelect}>
          Selecteer
        </Button>
        <Button color="secondary" onClick={onClose}>
          Sluiten
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default PackageModal;
