import React, { useState } from "react";
import Select from "react-select";
import DatePicker from "react-datepicker";
import jsPDF from "jspdf";
import { PDFDocument } from "pdf-lib";
import { logoUrl } from "../Logo.js";
import AddressStep from "../offerte/AddressStep.js";
import { useLocation } from "react-router-dom";
import "jspdf-autotable";
import "react-datepicker/dist/react-datepicker.css";
import "./Offerte.css";
import ProductStep from "../offerte/ProductStep.js";
import ExtraProductsStep from "../offerte/ExtraProductsStep.js";
import { FiChevronLeft } from "react-icons/fi";
import { Col, Row } from "reactstrap";
import AccordionSteps from "../offerte/AccordionSteps.js";
import { auth, db, updateOfferteStatus } from "../firebaseConfig"; // Import Firebase Firestore
import { collection, addDoc, doc, getDoc, deleteDoc } from "firebase/firestore";
import { useNavigate } from "react-router-dom";
import {
  toastHelper,
  generatePasscode,
  getNextOfferteNumber,
  sendQuoteEmail,
  createFollowUpTask,
  addLog,
} from "../utils/helpers.js";
import AnimatedOverlay from "../components/AnimatedOverlay"; // Import the overlay component

const energyProviders = [
  { value: "Vattenfall", label: "Vattenfall" },
  { value: "Essent", label: "Essent" },
  { value: "Eneco", label: "Eneco" },
  { value: "Greenchoice", label: "Greenchoice" },
  { value: "Budget Energie", label: "Budget Energie" },
  { value: "Oxxio", label: "Oxxio" },
  { value: "Nuon", label: "Nuon" },
  { value: "Delta", label: "Delta" },
  { value: "Engie", label: "Engie" },
  { value: "Qurrent", label: "Qurrent" },
  { value: "ANWB Energie", label: "ANWB Energie" },
  { value: "Pure Energie", label: "Pure Energie" },
  { value: "United Consumers", label: "United Consumers" },
];

const solarPanelBrands = [
  { value: "SunPower", label: "SunPower" },
  { value: "LG", label: "LG" },
  { value: "Panasonic", label: "Panasonic" },
  { value: "JA Solar", label: "JA Solar" },
  { value: "Trina Solar", label: "Trina Solar" },
  { value: "Jinko Solar", label: "Jinko Solar" },
  { value: "LONGi", label: "LONGi" },
  { value: "Canadian Solar", label: "Canadian Solar" },
  { value: "Q CELLS", label: "Q CELLS" },
  { value: "Risen", label: "Risen" },
];

const budgetOptions = [
  { value: 5000, label: "€5,000" },
  { value: 10000, label: "€10,000" },
  { value: 15000, label: "€15,000" },
];

const capacityOptions = [
  { value: 5, label: "5 kWh" },
  { value: 10, label: "10 kWh" },
  { value: 15, label: "15 kWh" },
  { value: 20, label: "20 kWh" },
];
const woonSituatieOptions = [
  { value: "overdag thuis", label: "Overdag thuis" },
  { value: "overdag niet thuis", label: "Overdag niet thuis" },
];

function App() {
  const location = useLocation();
  const [formData, setFormData] = useState({
    postcode: "",
    huisnummer: "",
    toevoeging: "",
    straat: "",
    plaats: "",
    provincie: "",
    lat: null,
    lng: null,
    woonfunctie: "",
    bouwjaar: null,
    oppervlakte: "",
    energieContract: "",
    kostenPerKwh: "",
    gasGebruik: false,
    woonSituatie: "overdag thuis",
    zonnepaneelMerk: "",
    omvormerType: "",
    zonnepaneelWattpiek: "",
    zonnepaneelOpbrengst: "",
    slimSysteem: true,
    noodstroom: true,
    brandveiligheid: true,
    budget: "",
    capaciteitsbehoefte: "",
    betalingsOptie: "Direct",
    terugbelAfspraak: null,
    email: "",
    heeftZonnepanelen: false,
    jaarverbruik: "",
    onbalansMarkt: false,
  });
  const [lead, setLead] = useState({
    ...location.state?.lead?.customerDetails,
  });
  const [leadId, setLeadId] = useState({ id: location.state?.lead?.id });
  const [errors, setErrors] = useState({});
  const [step, setStep] = useState(1);
  const [addressFound, setAddressFound] = useState(false);
  const [adviesRapport, setAdviesRapport] = useState(null);
  const [otherEnergyProvider, setOtherEnergyProvider] = useState(false);
  const [otherSolarPanelBrand, setOtherSolarPanelBrand] = useState(false);
  const [otherBudget, setOtherBudget] = useState(false);
  const [otherCapacity, setOtherCapacity] = useState(false);
  const [addressObject, setAddressObject] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedExtras, setSelectedExtras] = useState([]);
  const [isSending, setIsSending] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [id, setId] = useState(null);

  const navigate = useNavigate();

  const handleNextProduct = (product) => {
    setSelectedProduct(product);

    // setTimeout(() => {
    handleNextStep();
    // }, 1000);
  };
  const handleNextStep = () => {
    if (validateStep(step)) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handlePreviousStep = () => setStep((prevStep) => prevStep - 1);

  const handleFormChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleSelectChange = (selectedOption, { name }) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: selectedOption ? selectedOption.value : "",
    }));
  };

  const handleDateChange = (date) => {
    setFormData((prevData) => ({
      ...prevData,
      terugbelAfspraak: date,
    }));
  };

  const validateStep = (currentStep) => {
    let validationErrors = {};
    switch (currentStep) {
      case 6:
        if (!formData.energieContract)
          validationErrors.energieContract = "Energiecontract is vereist";
        if (!formData.kostenPerKwh)
          validationErrors.kostenPerKwh = "Kosten per kWh is vereist";
        break;
      case 7:
        if (formData.heeftZonnepanelen) {
          if (!formData.zonnepaneelMerk)
            validationErrors.zonnepaneelMerk = "Zonnepaneel merk is vereist";
          if (!formData.zonnepaneelWattpiek)
            validationErrors.zonnepaneelWattpiek = "Capaciteit is vereist";
          if (!formData.zonnepaneelOpbrengst)
            validationErrors.zonnepaneelOpbrengst = "Opbrengst is vereist";
          if (!formData.omvormerType)
            validationErrors.omvormerType = "Selecteer een type omvormer";
        } else if (!formData.jaarverbruik) {
          validationErrors.jaarverbruik = "Jaarverbruik is vereist";
        }

        break;
      case 8:
        if (!formData.budget) validationErrors.budget = "Budget is vereist";
        if (!formData.capaciteitsbehoefte)
          validationErrors.capaciteitsbehoefte =
            "Capaciteitsbehoefte is vereist";
        break;
      case 4:
        if (!formData.email.includes("@"))
          validationErrors.email = "Ongeldig e-mailadres";
        break;
      default:
        break;
    }
    setErrors(validationErrors);
    // console.log("Validation Errors: ", validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const calculateSolarPanels = () => {
    // Calculate panel recommendation based on usage if no panels exist
    if (!formData.heeftZonnepanelen) {
      const dailyUsage = formData.jaarverbruik / 365;
      const recommendedPanels = Math.ceil(dailyUsage / 1.1); // Assuming average panel output of 1.1 kWh/day
      return recommendedPanels;
    }
    return 0;
  };

  const handleExtrasProceed = (extras) => {
    setSelectedExtras(extras);
    // console.log("Selected Extras:", extras);
    handleNextStep();
    // Navigate to the next step
  };

  const TOTAL_STEPS = 5;
  // console.log(errors);

  const saveOfferteAsConcept = async (data) => {
    if (isSending) return;
    setIsSending(true);

    const passcode = generatePasscode();
    try {
      const offerteNr = await getNextOfferteNumber();
      const docRef = await addDoc(collection(db, "offertes"), {
        ...data,
        status: "concept",
        agent: auth.currentUser.uid,
        createdAt: new Date(),
        updatedAt: new Date(),
        sentAt: null,
        signedAt: null,
        signature: null,
        passcode,
        offerteNr,
        notes: lead.notes || [],
      });
      // console.log("Offerte saved as concept with ID: ", docRef.id);
      setIsSending(false);

      await addLog({
        offerteId: offerteNr,
        userId: auth.currentUser.uid,
        message: "Offerte is aangemaakt als concept.",
      });

      navigate(`/offertes/${docRef.id}`);
    } catch (error) {
      console.error("Error saving offerte as concept: ", error);
    }
  };

  // Function to send offerte to the client
  const sendOfferteToClient = async (data) => {
    if (isSending) return;
    setIsSending(true);
    setShowOverlay(true);
    const passcode = generatePasscode();

    try {
      const offerteNr = await getNextOfferteNumber();
      let newData = {
        ...data,
        agent: auth.currentUser.uid,
        status: "concept",
        sentAt: new Date(),
        createdAt: new Date(),
        updatedAt: new Date(),
        signedAt: null,
        signature: null,
        passcode,
        offerteNr,
        notes: lead.notes || [],
      };

      const docRef = await addDoc(collection(db, "offertes"), newData);
      newData.id = docRef.id;
      const res = await sendQuoteEmail(newData);
      if (res) {
        // await updateOfferteStatus(docRef.id, "Verzonden");
        // Voeg automatisch een taak toe voor opvolging
        await createFollowUpTask(newData);
        // navigate(`/offertes/${docRef.id}`);
        await addLog({
          offerteId: newData.offerteNr,
          userId: auth.currentUser.uid,
          message: "Offerte is naar de klant gestuurd.",
        });
        // toastHelper.success("Offerte is succesvol verstuurd!");
        setId(docRef.id);
        // navigate(`/offertes/${docRef.id}`);
      } else {
        await updateOfferteStatus(docRef.id, "Verzenden mislukt");
        toastHelper.error(
          "Er is iets misgegaan. Probeer het later nog eens of neem contact op met je contactpersoon."
        );
        // setIsSending(false);
      }

      // Optional: You can trigger an email API here
      // Example: Call an external API or email service
      // await sendEmail(data.customerDetails.email, docRef.id, data);
    } catch (error) {
      console.error("Error sending offerte to client: ", error);
      if (data.id) {
        await updateOfferteStatus(data.id, "Verzenden mislukt", {
          errorMessage: error.message,
        });
        toastHelper.error(
          "Er is iets misgegaan. Probeer het later nog eens of neem contact op met je contactpersoon."
        );
        // setIsSending(false);
      }
    } finally {
      setIsSending(false);
      // setShowOverlay(false); // Hide the overlay when done
    }
  };

  // Function to handle submission from Accordion
  const handleAccordionSubmit = async (data, action) => {
    try {
      if (leadId.id) {
        // Fetch the lead from Firestore
        const leadRef = doc(db, "leads", leadId.id);
        const leadSnap = await getDoc(leadRef);

        if (leadSnap.exists()) {
          const leadData = leadSnap.data();

          // Add the lead to the archivedLeads table
          await addDoc(collection(db, "archivedLeads"), {
            ...leadData,
            archivedAt: new Date(), // Add a timestamp to indicate when it was archived
          });

          // Optionally delete the lead from the current leads collection
          await deleteDoc(leadRef);

          // console.log(`Lead with ID ${leadId} has been archived.`);
        } else {
          console.error(`Lead with ID ${leadId.id} does not exist.`);
        }
      }
      console.log(data);
      console.log(action);
      if (action === "saveAsConcept") {
        saveOfferteAsConcept(data);
      } else if (action === "sendToClient") {
        sendOfferteToClient(data);
      } else {
        console.error("Invalid action: ", action);
      }
    } catch (error) {
      console.error("Error handling accordion submit: ", error);
    }
  };
  // toastHelper.error("Er is iets misgegaan. Probeer het later nog eens of neem contact op met je contactpersoon.");
  return (
    <>
      {step > 1 && (
        <div className="container content-header">
          <Row>
            <Col>
              <button className="backbutton" onClick={handlePreviousStep}>
                <FiChevronLeft />
                Vorige
              </button>
            </Col>
            <Col className="align-middle">
              <div className="addressinfo">
                Uw adres is {formData.straat} {formData.huisnummer}{" "}
                {formData.toevoeging} in {formData.plaats} ({formData.postcode}
                ).{" "}
                <span className="clickable-text" onClick={() => setStep(1)}>
                  Adres wijzigen
                </span>
              </div>
            </Col>
          </Row>
        </div>
      )}

      <div className="container">
        <div className="progress-bar-custom">
          <div
            className="progress-custom"
            style={{ width: `${(step / TOTAL_STEPS) * 100}%` }}
          ></div>
        </div>
      </div>
      {step === 1 && (
        <>
          <div className="container app-container">
            <AddressStep
              lead={lead}
              formData={formData}
              setFormData={setFormData}
              addressFound={addressFound}
              setAddressFound={setAddressFound}
              onNext={() => setStep(2)} // Ga naar de volgende stap
              addressObject={addressObject}
              setAddressObject={setAddressObject}
            />
          </div>
        </>
      )}

      {step === 2 && (
        <>
          <div className="container app-container">
            <ProductStep
              onSelect={(product) => handleNextProduct(product)}
              selectedProduct={selectedProduct}
            />
          </div>
          <div>&nbsp;</div>
          {/* <div className="button-block mt-5">
            <div>
              <button className="back" onClick={handlePreviousStep}>
                Vorige
              </button>
            </div>
            <div>
              <button onClick={handleNextStep}>Volgende</button>
            </div>
          </div> */}
        </>
      )}

      {step === 3 && (
        <>
          <ExtraProductsStep
            selectedProduct={selectedProduct}
            onProceed={handleExtrasProceed}
            selectedExtras={selectedExtras}
            step={step}
          />
          <div>&nbsp;</div>
        </>
      )}
      {step === 4 && (
        <>
          <AccordionSteps
            selectedProduct={selectedProduct}
            selectedExtras={selectedExtras}
            onSubmit={handleAccordionSubmit}
            formData={formData}
            lead={lead}
            isSending={isSending}
          />
          <div>&nbsp;</div>
        </>
      )}
      {step === 10 && (
        <div className="form-step">
          <h2>Stap 1: Basisinformatie</h2>
          <label>Naam Energiemaatschappij:</label>
          <Select
            name="energieContract"
            options={[...energyProviders, { value: "other", label: "Anders" }]}
            value={
              energyProviders.find(
                (option) => option.value === formData.energieContract
              ) ||
              (formData.energieContract
                ? {
                    value: formData.energieContract,
                    label: formData.energieContract,
                  }
                : null)
            }
            onChange={(selectedOption) => {
              if (selectedOption.value === "other") {
                setOtherEnergyProvider(true);
                setFormData((prevData) => ({
                  ...prevData,
                  energieContract: "",
                }));
              } else {
                setOtherEnergyProvider(false);
                handleSelectChange(selectedOption, { name: "energieContract" });
              }
            }}
            placeholder="Selecteer Energiemaatschappij"
          />
          {otherEnergyProvider && (
            <input
              name="energieContract"
              type="text"
              placeholder="Voer uw energieleverancier in"
              value={formData.energieContract}
              onChange={handleFormChange}
            />
          )}
          {errors.energieContract && (
            <p className="error">{errors.energieContract}</p>
          )}

          <label>Kosten per kWh:</label>
          <input
            name="kostenPerKwh"
            type="number"
            value={formData.kostenPerKwh}
            onChange={handleFormChange}
            placeholder="bijv. 0.25"
          />
          {errors.kostenPerKwh && (
            <p className="error">{errors.kostenPerKwh}</p>
          )}

          <label>Woonsituatie:</label>
          <Select
            name="woonSituatie"
            options={woonSituatieOptions}
            onChange={(selectedOption) =>
              handleSelectChange(selectedOption, { name: "woonSituatie" })
            }
            value={woonSituatieOptions.find(
              (option) => option.value === formData.woonSituatie
            )}
            placeholder="Selecteer uw woonsituatie"
          />

          <div className="checkbox-aligned">
            <input
              name="gasGebruik"
              type="checkbox"
              checked={formData.gasGebruik}
              onChange={handleFormChange}
            />
            <label htmlFor="gasGebruik">Gebruikt u gas?</label>
          </div>
          <div className="checkbox-aligned">
            <input
              name="onbalansMarkt"
              type="checkbox"
              checked={formData.onbalansMarkt}
              onChange={handleFormChange}
            />
            <label htmlFor="onbalansMarkt">
              Interesse in handelen op de onbalans markt:
            </label>
          </div>

          <div className="button-block">
            <div>
              <button className="back" onClick={handlePreviousStep}>
                Vorige
              </button>
            </div>
            <div>
              <button onClick={handleNextStep}>Volgende</button>
            </div>
          </div>
        </div>
      )}

      {step === 10 && (
        <div className="form-step">
          <h2>Stap 2: Persoonlijke Situatie</h2>
          <label>Heeft u al zonnepanelen?</label>
          <input
            name="heeftZonnepanelen"
            type="checkbox"
            checked={formData.heeftZonnepanelen}
            onChange={handleFormChange}
          />
          {formData.heeftZonnepanelen ? (
            <>
              <label>Zonnepaneel Merk:</label>
              <Select
                name="zonnepaneelMerk"
                options={[
                  ...solarPanelBrands,
                  { value: "other", label: "Anders" },
                ]}
                value={
                  solarPanelBrands.find(
                    (option) => option.value === formData.zonnepaneelMerk
                  ) ||
                  (formData.zonnepaneelMerk
                    ? {
                        value: formData.zonnepaneelMerk,
                        label: formData.zonnepaneelMerk,
                      }
                    : null)
                }
                onChange={(selectedOption) => {
                  if (selectedOption.value === "other") {
                    setOtherSolarPanelBrand(true);
                    setFormData((prevData) => ({
                      ...prevData,
                      zonnepaneelMerk: "",
                    }));
                  } else {
                    setOtherSolarPanelBrand(false);
                    handleSelectChange(selectedOption, {
                      name: "zonnepaneelMerk",
                    });
                  }
                }}
                placeholder="Selecteer uw zonnepaneel merk"
              />
              {otherSolarPanelBrand && (
                <input
                  name="zonnepaneelMerk"
                  type="text"
                  placeholder="Voer uw zonnepaneel merk in"
                  value={formData.zonnepaneelMerk}
                  onChange={handleFormChange}
                />
              )}

              <label>Type Omvormer:</label>
              <Select
                name="omvormerType"
                options={[
                  { value: "string", label: "String Omvormer" },
                  { value: "micro", label: "Micro Omvormers" },
                ]}
                value={
                  formData.omvormerType
                    ? {
                        value: formData.omvormerType,
                        label: formData.omvormerType,
                      }
                    : null
                }
                onChange={(selectedOption) =>
                  handleSelectChange(selectedOption, { name: "omvormerType" })
                }
                placeholder="Selecteer het type omvormer"
              />

              <label>Capaciteit van Zonnepanelen (Wattpiek):</label>
              <input
                name="zonnepaneelWattpiek"
                type="number"
                value={formData.zonnepaneelWattpiek}
                onChange={handleFormChange}
              />
              <label>Opbrengst zonnepanelen (kWh/jaar):</label>
              <input
                name="zonnepaneelOpbrengst"
                type="number"
                value={formData.zonnepaneelOpbrengst}
                onChange={handleFormChange}
              />
            </>
          ) : (
            <>
              <label>Jaarlijks verbruik (kWh):</label>
              <input
                name="jaarverbruik"
                type="number"
                value={formData.jaarverbruik}
                onChange={handleFormChange}
              />
            </>
          )}

          {errors.zonnepaneelMerk && (
            <p className="error">{errors.zonnepaneelMerk}</p>
          )}
          {errors.zonnepaneelWattpiek && (
            <p className="error">{errors.zonnepaneelWattpiek}</p>
          )}
          {errors.zonnepaneelOpbrengst && (
            <p className="error">{errors.zonnepaneelOpbrengst}</p>
          )}
          {errors.jaarverbruik && (
            <p className="error">{errors.jaarverbruik}</p>
          )}
          <div className="button-block">
            <div>
              <button className="back" onClick={handlePreviousStep}>
                Vorige
              </button>
            </div>
            <div className="align-right">
              <button onClick={handleNextStep}>Volgende</button>
            </div>
          </div>
        </div>
      )}

      {step === 10 && (
        <div className="form-step">
          <h2>Stap 3: Batterijvoorkeuren</h2>
          <label>Noodstroom gewenst:</label>
          <input
            name="noodstroom"
            type="checkbox"
            checked={formData.noodstroom}
            onChange={handleFormChange}
          />

          <label>Slim systeem gewenst:</label>
          <input
            name="slimSysteem"
            type="checkbox"
            checked={formData.slimSysteem}
            onChange={handleFormChange}
          />

          <label>Budget:</label>
          <Select
            name="budget"
            options={[...budgetOptions, { value: "other", label: "Anders" }]}
            value={
              budgetOptions.find(
                (option) => option.value === formData.budget
              ) || { value: formData.budget, label: `€${formData.budget}` }
            }
            onChange={(selectedOption) => {
              if (selectedOption.value === "other") {
                setOtherBudget(true);
                setFormData((prevData) => ({ ...prevData, budget: "" }));
              } else {
                setOtherBudget(false);
                setFormData((prevData) => ({
                  ...prevData,
                  budget: selectedOption.value,
                }));
              }
            }}
            placeholder="Selecteer uw budget"
          />
          {otherBudget && (
            <input
              name="budget"
              type="number"
              placeholder="Voer uw budget in"
              value={formData.budget}
              onChange={handleFormChange}
            />
          )}
          {errors.budget && <p className="error">{errors.budget}</p>}

          <label>Capaciteitsbehoefte (kWh):</label>
          <Select
            name="capaciteitsbehoefte"
            options={[...capacityOptions, { value: "other", label: "Anders" }]}
            value={
              capacityOptions.find(
                (option) => option.value === formData.capaciteitsbehoefte
              ) || {
                value: formData.capaciteitsbehoefte,
                label: `${formData.capaciteitsbehoefte} kWh`,
              }
            }
            onChange={(selectedOption) => {
              if (selectedOption.value === "other") {
                setOtherCapacity(true);
                setFormData((prevData) => ({
                  ...prevData,
                  capaciteitsbehoefte: "",
                }));
              } else {
                setOtherCapacity(false);
                setFormData((prevData) => ({
                  ...prevData,
                  capaciteitsbehoefte: selectedOption.value,
                }));
              }
            }}
            placeholder="Selecteer uw capaciteitsbehoefte"
          />
          {otherCapacity && (
            <input
              name="capaciteitsbehoefte"
              type="number"
              placeholder="Voer uw capaciteitsbehoefte in"
              value={formData.capaciteitsbehoefte}
              onChange={handleFormChange}
            />
          )}
          {errors.capaciteitsbehoefte && (
            <p className="error">{errors.capaciteitsbehoefte}</p>
          )}

          <div className="button-block">
            <div>
              <button className="back" onClick={handlePreviousStep}>
                Vorige
              </button>
            </div>
            <div className="align-right">
              <button onClick={handleNextStep}>Volgende</button>
            </div>
          </div>
        </div>
      )}

      {step === 10 && (
        <div className="form-step">
          <h2>Stap 4: Contactgegevens</h2>
          <label>E-mailadres:</label>
          <input
            name="email"
            type="email"
            value={formData.email}
            onChange={(e) =>
              setFormData({ ...formData, email: e.target.value })
            }
          />
          {errors.email && <p className="error">{errors.email}</p>}

          <label>Terugbel afspraak:</label>
          <DatePicker
            selected={formData.terugbelAfspraak}
            onChange={(date) =>
              setFormData({ ...formData, terugbelAfspraak: date })
            }
            showTimeSelect
            dateFormat="Pp"
            placeholderText="Kies een datum en tijd"
          />
          <div className="button-block">
            <div>
              <button className="back" onClick={() => setStep(step - 1)}>
                Vorige
              </button>
            </div>
            <div className="align-right">
              {/* <button onClick={generatePdfReport}>
                Genereer PDF Adviesrapport
              </button> */}
            </div>
          </div>
        </div>
      )}

      {adviesRapport && (
        <div className="advies-rapport">
          <h3>Adviesrapport:</h3>
          <pre>{adviesRapport}</pre>
        </div>
      )}

      {showOverlay && (
        <AnimatedOverlay
          onComplete={() => navigate(`/offertes/${id}`)} // Hide the overlay when finished
        />
      )}
    </>
  );
}

export default App;
