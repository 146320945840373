import React, { useState } from "react";
import { Table, Button } from "reactstrap";
import moment from "moment";
import { addNoAnswer } from "../firebaseConfig";
import { convertToDate } from "../utils/helpers";

const LeadList = ({
  leads,
  onStatusChange,
  onNoAnswer,
  onReject,
  phase,
  openSchouwForm,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  // Bereken het aantal pagina's
  const totalPages = Math.ceil(leads.length / itemsPerPage);

  // Filter leads voor de huidige pagina
  const paginatedLeads = leads.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  // Navigatieknoppen
  const handlePrevious = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // console.log(paginatedLeads);
  return (
    <div>
      <Table bordered>
        <thead>
          <tr>
            <th>Datum</th>

            <th>Klantnaam</th>
            <th>Adres</th>

            {phase.status === "afgewezen" && <th>Reden</th>}
            {phase.status === "new" && <th>Status</th>}
            {phase.status === "afspraak_gepland" && <th>Type</th>}
            {/* {phase.status !== "afgewezen" && <th>Acties</th>} */}
            <th>Acties</th>
          </tr>
        </thead>
        <tbody>
          {paginatedLeads.map((lead) => (
            <tr key={lead.id}>
              {phase.status === "offerte_concept" && (
                <td>
                  {moment(convertToDate(lead.updatedAt)).format("LLL")}
                  <br />{" "}
                  <small style={{ fontStyle: "italic" }}>Laatste update</small>
                </td>
              )}
              {phase.status === "new" && (
                <td>{moment(lead.createdAt.toDate()).format("LLL")}</td>
              )}
              {phase.status === "afgewezen" && (
                <td>{moment(lead?.canceledAt?.toDate()).format("LLL")}</td>
              )}
              {phase.status === "afspraak_gepland" && (
                <td>
                  {moment(
                    `${lead.saleAfspraak.datum} ${lead.saleAfspraak.tijd}`
                  ).format("LLL")}
                </td>
              )}
              <td>
                {lead.customerDetails.firstName} {lead.customerDetails.lastName}
              </td>
              <td>
                <small>
                  {lead?.customerDetails?.straat}{" "}
                  {lead?.customerDetails?.huisnummer}
                  <br />
                  {lead?.customerDetails?.postcode}{" "}
                  {lead?.customerDetails?.plaats}
                </small>
              </td>

              {phase.status === "afspraak_gepland" && (
                <td>{lead?.saleAfspraak?.type}</td>
              )}
              {phase.status === "new" && (
                <td>
                  {lead?.nietBereikt > 0 ? `${lead.nietBereikt}x NB` : "Nieuw"}
                </td>
              )}
              {phase.status === "afgewezen" && (
                <td>
                  {lead.cancelReason} <br /> {lead.cancelDescription}
                </td>
              )}

              {/* <td>
                {onStatusChange && (
                  <Button
                    color="primary"
                    size="sm"
                    onClick={() => onStatusChange(lead)}
                  >
                    Naar volgende fase
                  </Button>
                )}
              </td> */}

              <td>
                {onStatusChange && phase.next && (
                  <>
                    <Button
                      color="primary"
                      size="sm"
                      onClick={() => onStatusChange(lead)}
                      className="me-2"
                    >
                      {lead.status === "concept" &&
                      phase.cta === "Offerte Opstellen"
                        ? phase.ctaConcept
                        : phase.cta}
                    </Button>
                  </>
                )}
                {phase.status !== "afgewezen" && (
                  <>
                    {onReject && (
                      <Button
                        color="danger"
                        size="sm"
                        onClick={() => onReject(lead)}
                        className="me-2"
                      >
                        Afwijzen
                      </Button>
                    )}
                    {phase.status === "new" && (
                      <Button
                        color="info"
                        size="sm"
                        onClick={() => onNoAnswer(lead)}
                      >
                        NB
                      </Button>
                    )}
                    {phase.status === "offerte_verzonden" && (
                      <Button
                        color="warning"
                        size="sm"
                        onClick={() => openSchouwForm(lead)}
                      >
                        Schouw uitvoeren
                      </Button>
                    )}
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <div className="pagination-controls" style={{ textAlign: "center" }}>
        <Button
          color="secondary"
          size="sm"
          onClick={handlePrevious}
          disabled={currentPage === 1}
          className="me-2"
        >
          Vorige
        </Button>
        <span>
          Pagina {currentPage} van {totalPages}
        </span>
        <Button
          color="secondary"
          size="sm"
          onClick={handleNext}
          disabled={currentPage === totalPages}
          className="ms-2"
        >
          Volgende
        </Button>
      </div>
    </div>
  );
};

export default LeadList;
