import React, { useState } from "react";

import GraphWidget from "../../components/GraphWidget";
import OfferteWidget from "../../components/OfferteWidget";
import { Col, Row } from "reactstrap";
import { FaFileAlt, FaFileSignature, FaTimesCircle } from "react-icons/fa";
import AmountWidget from "../../components/AmountWidget";
import { useDashboardData } from "../../context/DataContext";
import { useUser } from "../../context/UserContext";

function Dashboard() {
  const { data } = useDashboardData();
  const { agents } = useUser();

  return (
    <>
      <Row>
        <Col md="4">
          <AmountWidget
            title="Verzonden Offertes"
            icon={FaFileAlt}
            count={data.sent}
            color="primary"
          />
        </Col>
        <Col md="4">
          <AmountWidget
            title="Getekende Offertes"
            icon={FaFileSignature}
            count={data.signed}
            color="success"
          />
        </Col>
        <Col md="4">
          <AmountWidget
            title="Geannuleerde Offertes"
            icon={FaTimesCircle}
            count={data.cancelled}
            color="danger"
          />
        </Col>
      </Row>
      <GraphWidget title="Statistieken" />
      <OfferteWidget
        offertes={data.offertes}
        agents={agents}
        title="Nieuwste Offertes"
      />
    </>
  );
}

export default Dashboard;
